import React from 'react';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as DocumentIcon } from '../../assets/doc.svg';

export const ContactsHeader = ({
    onImportCsvClick,
    onAddContactClick,
    onSearchChange,
}: {
    onImportCsvClick: () => void;
    onAddContactClick: () => void;
    onSearchChange: (value: string) => void;
}) => {
    return (
        <Stack direction="row" gap={2}>
            <TextField
                size="small"
                placeholder="Search"
                sx={{ ml: '2px', mr: 'auto', width: '400px' }}
                onChange={(e) => onSearchChange(e.target.value)}
                InputProps={{
                    sx: {
                        '& input': { paddingLeft: '0 !important' },
                        borderRadius: '16px !important',
                        '&:before': { borderRadius: '18px !important' },
                    },
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Button size="small" variant="outlined" startIcon={<PlusIcon />} onClick={onAddContactClick}>
                Add contact
            </Button>
            <Button size="small" variant="outlined" startIcon={<DocumentIcon />} onClick={onImportCsvClick}>
                Import CSV
            </Button>
        </Stack>
    );
};
