import styled from 'styled-components';
import SearchIconSrc from '../../assets/search-icon.svg';

export const Root = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-direction: column;

    @media (max-width: 768px) {
        #create-widget-desktop {
            display: none;
        }
    }

    #create-widget-mobile {
        width: 100%;
        max-width: 343px;
    }

    @media (min-width: 768px) {
        #create-widget-mobile {
            display: none;
        }
    }
`;

export const StyledInput = styled.input<{ $visible?: boolean }>`
    width: 360px;
    height: 40px;
    font-size: 16px;
    line-height: 1.4375em;
    border: none;
    outline: none;
    background: transparent;
    color: white;

    @media screen and (max-width: 550px) {
        width: 190px;
    }

    ${({ $visible }) => ($visible ? 'display: block;' : 'display: none;')}
    &::placeholder {
        color: #b2b2b2;
    }
`;

export const InputWrapper = styled.div<{ $focus?: boolean }>`
    border-radius: 99px;
    overflow: hidden;
    padding: 4px;
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.cardBackground};
    box-sizing: border-box;

    border: 1px solid rgba(191, 169, 255, 0.2);
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
    position: relative;

    @media (max-width: 768px) {
        width: 100%;
        max-width: 343px;

        ${StyledInput} {
            width: 80%;
            text-align: center;
        }
    }
`;

export const ButtonText = styled.span`
    font-size: 16px;
    font-weight: 500;
`;

export const StyledButton = styled.button`
    padding: 0.75rem 1.5rem;
    background-color: #1a73e8;
    color: #ffffff;
    border: none;
    border-radius: 0.25rem;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    font-family: Montserrat, sans-serif;

    &:hover {
        background-color: #0a66e6;
    }
`;

export const PlatformImage = styled.img`
    width: 24px;
    height: 24px;
    margin: 0 12px 0 14px;
`;

export const CreditCardLabel = styled.div`
    color: #606580;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.28px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

export const BorderAnimContainer = styled.div`
    border-radius: 999px;
    content: '';
    height: 100%;
    inset: 0;
    left: 0;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: 1s ease opacity;
    width: 100%;
`;

export const GlowingBoxBorders = styled.div`
    animation: borderTurn 2s infinite linear;
    background-image: conic-gradient(
        from 0 at 50% 50%,
        rgba(255, 255, 255, 0.5) 0deg,
        rgba(255, 255, 255, 0) 60deg,
        rgba(255, 255, 255, 0) 310deg,
        rgba(255, 255, 255, 0.5) 360deg
    );
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @keyframes borderTurn {
        0% {
            transform: translate(-50%, -50%) rotate(0);
        }
        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    &:before {
        content: '';
        float: left;
        padding-top: 100%;
    }

    &:after {
        clear: both;
        content: '';
        display: block;
    }
`;
