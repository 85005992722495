import styled from 'styled-components';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    img {
        margin-bottom: 40px;
        width: 100%;
        border-radius: 12px;
    }

    p {
        padding-left: 8px;
        margin-bottom: 24px;
        position: relative;
    }
    p::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 3px;
        background: ${({ theme }) => theme.colors.primary};
        border-radius: 4px 1px 1px 4px;
    }
`;
