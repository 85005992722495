import React from 'react';
import { Dots1, Dots1Small, Dots2, Dots2Small, DotsWrapper } from './starts-background.styles';
export const StarsBackground = ({ small }: { small?: boolean }) => {
    return (
        <DotsWrapper>
            {small ? <Dots1Small /> : <Dots1 />}
            {small ? <Dots2Small /> : <Dots2 />}
        </DotsWrapper>
    );
};
