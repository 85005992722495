import styled from 'styled-components';
import { ReviewCardRoot, VideoReviewContainer } from '../review-card/review-card.styles';

export const CarouselRow = styled.div`
    width: fit-content;
    white-space: nowrap;
    display: flex;
`;
export const ReviewsWallContainer = styled.div<{ $reverse?: boolean; $duration: string }>`
    display: flex;
    overflow: visible;
    position: relative;
    width: 100%;

    flex-flow: ${({ $reverse }) => ($reverse ? 'row-reverse nowrap' : 'row nowrap')};

    @keyframes scroll-horizontal {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(-100%);
        }
    }

    @keyframes scroll-horizontal-reverse {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(100%);
        }
    }

    ${CarouselRow} {
        animation: ${({ $duration, $reverse }) =>
            $reverse
                ? `scroll-horizontal-reverse ${$duration} linear infinite`
                : `scroll-horizontal ${$duration} linear infinite`};
        flex-flow: ${({ $reverse }) => ($reverse ? 'row-reverse nowrap' : 'row nowrap')};
    }
`;

export const Root = styled.div`
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    padding-bottom: 10px;

    &:hover {
        ${CarouselRow} {
            animation-play-state: paused;
        }
    }

    ${ReviewCardRoot} {
        width: 29em;
        flex-shrink: 0;
    }
    ${VideoReviewContainer} {
        position: absolute;
        top: 0;
        left: 0;
        video {
            min-height: unset;
        }
    }
`;
