import styled from 'styled-components';
import { defaultAccentColor } from '../../constants';
import { VideoReviewContainer } from '../review-card/review-card.styles';

export const Root = styled.div<{ $accentColor?: string }>`
    padding-bottom: 3px;
    position: relative;
    width: 100%;
    height: fit-content;

    .react-multi-carousel-dot-list {
        justify-content: center !important;
        left: 0;
        padding: 0 16px;
    }

    .react-multi-carousel-dot {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;

        button {
            border: none;
            background: ${({ theme }) => theme.colors.navigationDot};
        }

        &:first-child,
        &:last-child {
            padding: 1px;
            button {
                width: 10px;
                height: 10px;
            }
        }
    }

    .react-multi-carousel-dot--active {
        button {
            background: ${({ theme }) => theme.colors.navigationDotActive};
        }
    }

    .react-multi-carousel-item {
        padding-bottom: 15px;

        div {
            transition: 0.4s opacity ease-in-out;
            opacity: 0;
        }

        &--active {
            div {
                opacity: 1;
            }
        }
    }

    .carousel-translate-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.25s linear;
        width: 100%;
        transform: none !important;
        border-radius: 32px;
        overflow: hidden !important;
    }

    .carousel-translate-wrapper button {
        width: 100% !important;
        border-radius: 0 !important;
        margin: 0 !important;
        height: 2px !important;
        padding: 7px 0;
        position: relative;
        background: unset;
    }

    .carousel-translate-wrapper button::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        top: 50%;
        transform: translateY(-50%);

        background: ${({ theme }) => theme.colors.carouselSwitcherBg};
        transition: background 0.25s ease-out;
    }

    .react-multi-carousel-dot--active button::after {
        background: ${({ $accentColor }) => $accentColor || defaultAccentColor};
        transition: background 0.25s ease-out;
    }

    .react-multi-carousel-dot button:hover:active {
        background: unset;
    }

    .react-multi-carousel-dot:first-child {
        padding: 0;
    }

    .react-multi-carousel-dot:last-child {
        padding: 0;
    }

    .react-multi-carousel-item div {
        opacity: 1;
        transition: none;
    }

    .react-multi-carousel-list {
        mask-image: linear-gradient(90deg, transparent, red 10%, red 90%, transparent);
        width: 100%;
    }

    @media screen and (min-width: 1536px) {
        #widget-root .react-multi-carousel-list {
            mask-image: linear-gradient(90deg, transparent, red 5%, red 95%, transparent);
        }
    }

    ${VideoReviewContainer} {
        position: absolute;
        top: 0;
        left: 0;
        video {
            min-height: unset;
        }
    }
`;

export const ChevronButton = styled.div<{ $right?: boolean }>`
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateY(-15px) ${({ $right }) => ($right ? 'scaleX(-1)' : '')};
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 999px;
    background: ${({ theme }) => theme.colors.chevronBackground};
    backdrop-filter: blur(10px);

    ${({ $right }) => ($right ? 'right: 8px' : 'left:8px')};

    @media screen and (max-width: 700px) {
        ${({ $right }) => ($right ? 'right: 5px' : 'left:5px')};
    }

    transition: all 0.15s;

    &:hover {
        opacity: 0.6;
    }
`;
