import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { ContentWrapper } from '../instruction-dialog.styles';

export const ElementorArticle = () => (
    <>
        <DialogTitle textAlign="left" align="center" sx={{ p: 0, mt: 3, fontSize: '18px' }}>
            How to add widget to your Elementor site (WordPress)
        </DialogTitle>
        <ContentWrapper>
            <Typography>
                Log in to your WordPress website's admin (/wp-admin), click on <strong>Pages</strong>:
            </Typography>
            <img src="/instructions/wordpress-1.png" alt="" />
            <Typography>
                Choose the page you want to add the widget to and click on <strong>Edit</strong>:
            </Typography>
            <img src="/instructions/wordpress-2.png" alt="" />
            <Typography>
                This opens up the default page editor. To open the Elementor page editor, click the{' '}
                <strong>Edit with Elementor</strong> button near the top of the editor.
            </Typography>
            <img src="/instructions/elementor-1.png" style={{ width: '200px' }} alt="" />
            <Typography>
                Find the <strong>HTML</strong> element from the elements section in the sidebar and drag & drop it into your page.
                After this, the element's settings are automatically opened and you're greeted with a textbox where you can enter
                your embed code.
            </Typography>
            <img src="/instructions/elementor-2.png" alt="" />
            <Typography>
                Once the code is in place, click on the <strong>Publish</strong> button at the bottom of the sidebar, and you're
                done!
            </Typography>
            <img src="/instructions/elementor-3.png" alt="" />
            <Typography>You have successfully added the widget to a page of your Elementor (Wordpress) website!</Typography>
        </ContentWrapper>
    </>
);
