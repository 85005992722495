import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog/Dialog';
import styled from 'styled-components';
import { ReactComponent as DoneIcon } from '../../assets/done.svg';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const SubscriptionActivateModal = () => {
    const [open, setOpen] = useState(window.location.pathname === '/success/');
    const onClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <Root>
                <DoneIcon />
                <Typography fontSize="18px" fontWeight="500" textAlign="center" whiteSpace="nowrap">
                    Your Pro subscription has been activated.
                </Typography>
                <Button variant="outlined" onClick={onClose}>
                    Continue
                </Button>
            </Root>
        </Dialog>
    );
};

const Root = styled.div`
    border-radius: 24px;
    width: 400px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;
