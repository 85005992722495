import React, { FC, useState } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

// Define a type for the injected props
interface WithToastProps {
    showToast: (message: string) => void;
}

// HOC
const withToast = <P extends WithToastProps>(Component: React.ComponentType<P>) => {
    const WithToastComponent: FC<Omit<P, keyof WithToastProps>> = (props) => {
        const [isToastOpen, setIsToastOpen] = useState(false);
        const [toastMessage, setToastMessage] = useState('Server Response Error.');
        const showToast = (message: string) => {
            setIsToastOpen(true);
            if (message) {
                setToastMessage(message);
            }
        };

        // @ts-ignore to ignore the type checking errors on the next line
        return (
            <>
                <Component {...(props as P)} showToast={showToast} />
                <Snackbar
                    open={isToastOpen}
                    autoHideDuration={5000}
                    onClose={() => {
                        setIsToastOpen(false);
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="error">{toastMessage}</Alert>
                </Snackbar>
            </>
        );
    };

    return WithToastComponent;
};

export default withToast;
