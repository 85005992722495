import styled from 'styled-components';
import { NameTextWrapper } from '../../components/review-card/review-card.styles';
import { defaultAccentColor } from '../../constants';
import LoadingButton from '@mui/lab/LoadingButton';

export const ReviewCardRoot = styled.div<{ $pin?: boolean; $cards }>`
    position: relative;
    //height: 150px;
    display: flex;
    padding: 16px;
    transition: background-color 0.25s;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.bg};
    min-width: 350px;
    ${({ $cards }) => $cards && `flex: 1 1 40%`}
`;

export const NameWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    ${NameTextWrapper} {
        max-width: unset;
    }
`;

export const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const PlatformIconWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 16px;
    svg:not(.MuiCircularProgress-svg) {
        margin-right: 8px;
    }
    button {
        width: fit-content;
    }
`;

export const MoreButton = styled.span`
    font-weight: 500;
    padding: 8px 15px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text};
    border-radius: 999px;
    cursor: pointer;
    letter-spacing: -0.28px;
    background: ${({ theme }) => theme.colors.light};
    transition: opacity 0.25s;
    height: fit-content;

    &:hover {
        opacity: 0.8;
    }
`;

export const Separator = styled.div`
    background: ${({ theme }) => theme.colors.border};
    width: 100%;
    height: 1px;
    margin-top: auto;
`;

export const ReviewCountLabel = styled.div`
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 20px;
    letter-spacing: -0.28px;
`;

export const DateText = styled.span<{ $light?: boolean }>`
    color: ${({ theme, $light }) => ($light ? theme.colors.bg : theme.colors.textSecondary)};
    margin-left: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: -0.3px;
    font-size: 14px;
`;

export const MainText = styled.span`
    line-height: 20px;
    color: ${({ theme }) => theme.colors.text};
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    letter-spacing: -0.28px;
    max-height: 100px;
    transition: max-height 0.25s ease-in-out;
    font-size: 14px;
`;
