import React, { useState } from 'react';
import { SectionContentWrapper, SectionDashRoot, SectionTitle } from '../../components/common.styles';
import { RequestsAnalytics } from './requests-analytics.component';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabsWrapper } from './request-section.styles';
import { RequestContacts } from './request-contacts.component';
import { RequestPreviewComponent } from '../../components/request-preview/request-preview.component';
import { PaidFeaturesLock } from '../../components/paid-features-lock/paid-features-lock.component';

export const RequestSection = ({
    companyName,
    logoUrl,
    isProPlan,
    onUpgrade,
}: {
    companyName: string;
    logoUrl: string;
    isProPlan: boolean;
    onUpgrade?: () => void;
}) => {
    const [tab, setTab] = useState(!isProPlan ? 2 : 0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <>
            <SectionDashRoot>
                <SectionContentWrapper $fullHeight>
                    <Stack gap={3} height="100%">
                        <SectionTitle>Review requests</SectionTitle>
                        <TabsWrapper>
                            <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Analytics" color="info" />
                                <Tab label="Contacts" />
                                <Tab label="Preview" />
                            </Tabs>
                        </TabsWrapper>
                        <Stack gap={3} sx={{ position: 'relative', height: '100%' }}>
                            {tab === 0 && <RequestsAnalytics isProPlan={isProPlan} />}
                            {tab === 1 && <RequestContacts isProPlan={isProPlan} />}
                            {tab === 2 && <RequestPreviewComponent companyName={companyName} logoUrl={logoUrl} />}
                            {!isProPlan && <PaidFeaturesLock onUpgrade={onUpgrade} />}
                        </Stack>
                    </Stack>
                </SectionContentWrapper>
            </SectionDashRoot>
        </>
    );
};
