import styled from 'styled-components';

export const CardRoot = styled.div`
    padding: 24px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.bg};
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1 1 calc(25% - 16px);
`;

export const CardTitle = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 48px;
    font-style: normal;
    line-height: 100%;
    letter-spacing: -0.88px;
`;

export const CardDescription = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.72px;
`;
