import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Tooltip from '@mui/material/Tooltip';
import { CodeBlock } from '../code-block/code-block.component';
import { PlatformImage } from '../../pages/profile-page/profile-page.styles';
import { InstructionTypes } from '../../types/instruction.type';
import WordPressIcon from '../../assets/cms-wordpress-multicolor.svg';
import ElementorIcon from '../../assets/elementor.png';
import WixIcon from '../../assets/wix.svg';
import SquarespaceIcon from '../../assets/squarespace.svg';
import WebflowIcon from '../../assets/webflow.svg';
import WeeblyIcon from '../../assets/weebly.svg';
import GoDaddyIcon from '../../assets/godaddy.svg';
import { AvatarsRow } from '../avatars-row/avatars-row.component';
import { InstructionDialog } from '../instruction-dialog/instruction-dialog.component';
import { IntegrationSectionWrapper, ModalDescription, Root } from './integration-guide.modal.styles';
import { themeMui, themeStyledComponents } from '../../theme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { getCalApi } from '@calcom/embed-react';
import { TransitionProps } from '@mui/material/transitions';
import { CalendarModal } from './calendar-modal.component';
import { ModalTitle } from '../common.styles';

interface Props {
    userId?: string;
    open: boolean;
    onClose: () => void;
}

export const IntegrationGuideModal = ({ open, onClose, userId }: Props) => {
    const [activeInstructionType, setActiveInstructionType] = useState<InstructionTypes>();
    const [calendarOpened, setCalendarOpened] = useState(false);

    return (
        <StyledThemeProvider theme={themeStyledComponents}>
            <ThemeProvider theme={themeMui}>
                <Dialog
                    open={open}
                    onClose={onClose}
                    TransitionComponent={Transition}
                    PaperProps={{
                        sx: {
                            position: 'fixed',
                            right: 0,
                            top: 0,
                            height: '100%',
                            margin: '16px',
                            maxHeight: 'calc(100% - 32px)',
                        },
                    }}
                >
                    <Root>
                        {!activeInstructionType ? (
                            calendarOpened ? (
                                <CalendarModal onBack={() => setCalendarOpened(false)} onClose={onClose} />
                            ) : (
                                <>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ width: '100%' }}
                                    >
                                        <ModalTitle>Add widget to your website</ModalTitle>
                                        <Button size="medium" variant="outlined" color="info" onClick={onClose}>
                                            Close
                                        </Button>
                                    </Stack>
                                    <ModalDescription>
                                        Copy and paste this code into desired place of your website (HTML editor, website
                                        template, theme, etc.).
                                    </ModalDescription>
                                    <CodeBlock
                                        code={`<script src="https://widget.hybrid-reach.com/reviews.js" data-id="${userId}"></script>`}
                                    />
                                    <ModalTitle>Platform Tutorials</ModalTitle>
                                    <Stack direction="row" gap={1}>
                                        <Tooltip title="WordPress">
                                            <Button
                                                variant="outlined"
                                                color="info"
                                                sx={{ padding: '16px !important', borderRadius: '16px' }}
                                                onClick={() => setActiveInstructionType(InstructionTypes.wordpress)}
                                            >
                                                <PlatformImage src={WordPressIcon} />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Elementor">
                                            <Button
                                                variant="outlined"
                                                color="info"
                                                sx={{ borderRadius: '16px' }}
                                                onClick={() => setActiveInstructionType(InstructionTypes.elementor)}
                                            >
                                                <PlatformImage src={ElementorIcon} />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Wix">
                                            <Button
                                                variant="outlined"
                                                color="info"
                                                sx={{ borderRadius: '16px' }}
                                                onClick={() => setActiveInstructionType(InstructionTypes.wix)}
                                            >
                                                <PlatformImage src={WixIcon} />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="SquareSpace">
                                            <Button
                                                variant="outlined"
                                                color="info"
                                                sx={{ borderRadius: '16px' }}
                                                onClick={() => setActiveInstructionType(InstructionTypes.squarespace)}
                                            >
                                                <PlatformImage src={SquarespaceIcon} />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Webflow">
                                            <Button
                                                variant="outlined"
                                                color="info"
                                                sx={{ borderRadius: '16px' }}
                                                onClick={() => setActiveInstructionType(InstructionTypes.webflow)}
                                            >
                                                <PlatformImage src={WebflowIcon} />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Weebly">
                                            <Button
                                                variant="outlined"
                                                color="info"
                                                sx={{ borderRadius: '16px' }}
                                                onClick={() => setActiveInstructionType(InstructionTypes.weebly)}
                                            >
                                                <PlatformImage src={WeeblyIcon} />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="GoDaddy">
                                            <Button
                                                variant="outlined"
                                                color="info"
                                                sx={{ borderRadius: '16px' }}
                                                onClick={() => setActiveInstructionType(InstructionTypes.godaddy)}
                                            >
                                                <PlatformImage src={GoDaddyIcon} />
                                            </Button>
                                        </Tooltip>
                                    </Stack>
                                    <IntegrationSectionWrapper>
                                        <Stack gap={1}>
                                            <ModalTitle>Free 15-Minute live website integration</ModalTitle>
                                            <ModalDescription>Select the Most Convenient Time from Our Calendar</ModalDescription>
                                        </Stack>
                                        <Stack direction="row" justifyContent="space-between" sx={{ mt: 3 }}>
                                            <AvatarsRow />
                                            <Button variant="contained" onClick={() => setCalendarOpened(true)}>
                                                Book a Call
                                            </Button>
                                        </Stack>
                                    </IntegrationSectionWrapper>
                                </>
                            )
                        ) : (
                            <InstructionDialog
                                type={activeInstructionType}
                                open={!!activeInstructionType}
                                onBack={() => setActiveInstructionType(undefined)}
                                onClose={onClose}
                            />
                        )}
                        {/*  <CalendarWrapper>
                            <Typography align="center" color="textPrimary" variant="h6">
                                Free 15-minute live integration to your website on the call
                            </Typography>
                            <Typography align="center" color="textPrimary" variant="subtitle1" sx={{ mb: 1 }}>
                                Choose the most convenient time in our calendar
                            </Typography>
                            <AvatarsRow />

                        </CalendarWrapper>*/}
                    </Root>
                </Dialog>
            </ThemeProvider>
        </StyledThemeProvider>
    );
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="left" ref={ref} {...props} />;
});
