import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { ContentWrapper } from '../instruction-dialog.styles';

export const SquarespaceArticle = () => (
    <>
        <DialogTitle textAlign="left" align="center" sx={{ p: 0, mt: 3, fontSize: '18px' }}>
            How to add widget to Squarespace website
        </DialogTitle>
        <ContentWrapper>
            <Typography>
                Log in to your Squarespace account and choose the website where you'd like to add the widget. Select the Website
                section on the left side menu:
            </Typography>
            <img src="/instructions/squarespace-1.png" alt="" />
            <Typography>
                Choose the page you'd like to add the widget to and click <strong>Edit</strong>:
            </Typography>
            <img src="/instructions/squarespace-2.png" alt="" />
            <Typography>
                In the editing view, click <strong>Add block</strong> in a section and tn the menu that pops up, select{' '}
                <strong>Embed</strong> as the block type.
            </Typography>
            <img src="/instructions/squarespace-3.png" alt="" />
            <Typography>
                Now that you have the block on the site, double click it to bring up a context menu. Here select{' '}
                <strong>Code Snippet</strong>, and under it <strong>Embed data</strong>. Paste your embed code into the text box
                that pops up.
            </Typography>
            <img src="/instructions/squarespace-4.png" alt="" />
            <Typography>
                After that simply click <strong>Done</strong> in the top left corner and <strong>Save</strong> your changes. The
                widget will appear in the preview, and you'll be all done!
            </Typography>
        </ContentWrapper>
    </>
);
