import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Cal, { getCalApi } from '@calcom/embed-react';

export const CalendarModal = ({ onBack, onClose }: { onBack: () => void; onClose: () => void }) => {
    useEffect(() => {
        (async function () {
            const cal = await getCalApi();
            cal('ui', {
                theme: 'light',
                styles: { branding: { brandColor: '#000000' } },
                hideEventTypeDetails: false,
                layout: 'month_view',
            });
        })();
    }, []);

    return (
        <Stack sx={{ overflow: 'hidden', width: '509px' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ pb: 2 }}>
                <Button size="medium" variant="outlined" color="info" onClick={onBack}>
                    Back
                </Button>
                <Button size="medium" variant="outlined" color="info" onClick={onClose}>
                    Close
                </Button>
            </Stack>
            <Cal
                calLink="vlad-bond/widget-installation"
                style={{ width: '100%', height: '100%', overflow: 'scroll' }}
                config={{ layout: 'month_view' }}
            />
        </Stack>
    );
};
