import React, { useEffect, useMemo, useState } from 'react';
import { SectionContentWrapper, SectionDashRoot, SectionTitle } from '../../components/common.styles';
import Stack from '@mui/material/Stack';
import { SortSelector } from '../../components/sort-selector/sort-selector.component';
import { SortOption } from '../../types/sort.enum';
import { ShowHiddenInput } from '../../components/show-hidden-input/show-hidden-input.component';
import { VideoCard } from './video-card.component';
import { VideoWatchModal } from './video-watch.modal';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import axios from 'axios';
import { SERVER_URL } from '../../constants';
import { ReviewType } from '../../types/review.type';
import { pinSortNegative, pinSortNewest, pinSortOldest, pinSortPositive } from '../../utils/pinSort';
import Typography from '@mui/material/Typography';

export const VideoSection = ({ minRating }: { minRating: number }) => {
    const [sort, setSort] = useState<SortOption>(SortOption.newest);
    const [showHidden, setShowHidden] = useState(true);
    const [activeVideo, setActiveVideo] = useState(null);
    const [videoReviews, setVideoReviews] = useState<ReviewType[]>();

    const fetchVideoReviews = async () => {
        try {
            const token = localStorage.getItem('access_token');
            if (token) {
                const { data } = await axios.get(`${SERVER_URL}/reviews/video`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setVideoReviews(data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const togglePin = async (review: ReviewType) => {
        const token = localStorage.getItem('access_token');
        const newValue = !review.pin;
        await axios.patch(
            `${SERVER_URL}/reviews`,
            { id: review._id, pin: newValue },
            { headers: { Authorization: `Bearer ${token}` } }
        );
        review.pin = newValue;
    };

    const deleteReview = async (review: ReviewType) => {
        try {
            const token = localStorage.getItem('access_token');
            if (token) {
                setVideoReviews(videoReviews.filter((el) => el._id !== review._id));
                await axios.delete(`${SERVER_URL}/reviews/?id=${review._id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const sortFunction = useMemo(() => {
        switch (sort) {
            case SortOption.newest:
                return pinSortNewest;
            case SortOption.oldest:
                return pinSortOldest;
            case SortOption.positive:
                return pinSortPositive;
            case SortOption.negative:
                return pinSortNegative;
        }
    }, [sort]);

    const currentVisibilityFilter = showHidden
        ? () => true
        : (el: ReviewType) => (el.rating >= minRating && el.forceState !== -1) || el.forceState === 1;

    const filteredItems = useMemo(
        () => videoReviews?.filter(currentVisibilityFilter).sort(sortFunction),
        [videoReviews?.length, sort, showHidden]
    );

    useEffect(() => {
        fetchVideoReviews();
    }, []);

    return (
        <SectionDashRoot>
            <SectionContentWrapper $fullHeight>
                <Stack gap={3} height="100%">
                    <SectionTitle>Video reviews</SectionTitle>
                    {!videoReviews ? (
                        <CircularProgress sx={{ mx: 'auto', mt: 10 }} />
                    ) : (
                        <>
                            <Stack direction="row" gap={2} sx={{ mt: '26px' }}>
                                <SortSelector value={sort} onChange={setSort} />
                                <ShowHiddenInput value={showHidden} onChange={setShowHidden} />
                            </Stack>
                            {videoReviews.length ? (
                                <Stack direction="row" gap={2} flexWrap="wrap" overflow="auto">
                                    {filteredItems.map((item) => (
                                        <VideoCard
                                            key={item._id}
                                            item={item}
                                            minRating={minRating}
                                            onPlay={() => setActiveVideo(item)}
                                            onPin={() => togglePin(item)}
                                            onDelete={() => deleteReview(item)}
                                        />
                                    ))}
                                </Stack>
                            ) : (
                                <Stack sx={{ py: 2, height: '100%' }} gap={1} alignItems="center" justifyContent="center">
                                    <Typography fontSize="28px">🫢</Typography>
                                    <Typography fontSize="18px" fontWeight="500">
                                        Oops! No video reviews yet.
                                    </Typography>
                                </Stack>
                            )}
                        </>
                    )}
                </Stack>
            </SectionContentWrapper>
            <VideoWatchModal videoUrl={activeVideo?.videoUrl} name={activeVideo?.name} onClose={() => setActiveVideo(null)} />
        </SectionDashRoot>
    );
};

const mockVideosData = [
    {
        id: '0',
        url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
        name: 'Marry Miu',
        date: new Date().setDate(new Date().getDate() - 3),
        rating: 5,
        pin: false,
    },
    {
        id: '1',
        url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
        name: 'Marry Miu',
        date: new Date().setDate(new Date().getDate() - 3),
        rating: 5,
        pin: false,
    },
    {
        id: '2',
        url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4',
        name: 'Marry Miu',
        date: new Date().setDate(new Date().getDate() - 3),
        rating: 5,
        pin: false,
    },
    {
        id: '3',
        url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4',
        name: 'Marry Miu',
        date: new Date().setDate(new Date().getDate() - 3),
        rating: 5,
        pin: false,
    },
    {
        id: '4',
        url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4',
        name: 'Marry Miu',
        date: new Date().setDate(new Date().getDate() - 3),
        rating: 5,
        pin: false,
    },
    {
        id: '5',
        url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
        name: 'Marry Miu',
        date: new Date().setDate(new Date().getDate() - 3),
        rating: 5,
        pin: false,
    },
];
