import styled from 'styled-components';
import Button from '@mui/material/Button';

export const Root = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const VideoContainer = styled.div`
    width: 430px;
    height: 320px;
    border-radius: 12px;
    background: radial-gradient(50% 50% at 50% 50%, #322b48 0%, #06070d 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 640px) {
        width: 100%;
    }

    &:before {
        content: '';
        position: absolute;
        pointer-events: none;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        border-radius: 16px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 80.2%, rgba(0, 0, 0, 0.3) 100%),
            linear-gradient(270deg, rgba(0, 0, 0, 0) 80.2%, rgba(0, 0, 0, 0.3) 100%),
            linear-gradient(90deg, rgba(0, 0, 0, 0) 79.6%, rgba(0, 0, 0, 0.3) 100%),
            linear-gradient(180deg, rgba(0, 0, 0, 0) 70.35%, rgba(0, 0, 0, 0.5) 90.45%);
        box-shadow: 0px 45px 12px 0px rgba(0, 0, 0, 0), 0px 29px 11px 0px rgba(0, 0, 0, 0), 0px 16px 10px 0px rgba(0, 0, 0, 0.01),
            0px 7px 7px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
    }
`;

export const RecordingCanvas = styled.canvas.attrs({ id: 'recording-canvas' })`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 12px;
    object-fit: cover;
`;

export const MenuRoot = styled.div`
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    background: ${({ theme }) => theme.colors.bg};
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 4px;
    width: 200px;
    box-shadow: 0px 45px 12px 0px rgba(0, 0, 0, 0), 0px 29px 11px 0px rgba(0, 0, 0, 0), 0px 16px 10px 0px rgba(0, 0, 0, 0.01),
        0px 7px 7px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
`;

export const LabelWrapper = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const RecordingButton = styled(Button)<{ $recording?: boolean }>`
    width: 48px;
    height: 48px;
    padding: 0 !important;
    min-width: 48px;
    background: #ff3541 !important;
    position: relative;
    transition: all 0.1s;

    &:hover {
        background: rgba(255, 53, 65, 0.8) !important;
    }

    path {
        fill: #fff !important;
    }

    ${({ $recording, theme }) =>
        $recording &&
        `
        svg {display: none}
        background: transparent !important;
        border: 2px solid ${theme.colors.bg} !important;
        animation: pulse 2s infinite;
        
        &:hover {
            background: rgba(255, 53, 65, 0.2) !important;
        }
        
       &:before {  
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background: #ff3541;
            border-radius: 4px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
       }
    `}

    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(255, 53, 65, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }
`;

export const CountDown = styled.div`
    border-radius: 40px;
    background: #ff3541;
    color: ${({ theme }) => theme.colors.bg};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding: 4px 8px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PreviewVideo = styled.video`
    object-fit: cover;
    width: 430px;
    height: 320px;
    border-radius: 12px;
    z-index: 1;

    @media (max-width: 640px) {
        width: 100%;
    }
`;
