import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Typography from '@mui/material/Typography';
import {
    ContentWrapper,
    Label,
    MoreOptionsButton,
    PaperWrapper,
    ResponsiveRowOptions,
    ResponsiveSettingsWrapper,
    SectionWrapper,
    StyledFormGroup,
} from './widget-settings.styles';
import { StarsRating } from '../stars-rating/stars-rating.component';
import { SettingsType } from '../../types/settings.type';
import axios from 'axios';
import { DEFAULT_WIDGET_TITLE, SERVER_URL } from '../../constants';
import withToast from '../withToast.hoc';
import LoadingButton from '@mui/lab/LoadingButton';
import { themeMuiDark, themeStyledComponentsDark } from '../../theme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { PlatformSelector } from '../platform-selector/platform-selector.component';
import { ReviewSource } from '../../types/review-source.enum';
import { LayoutSelector } from '../layout-selector/layout-selector.component';
import { LayoutType } from '../../types/layout-type.enum';
import { SideBarRoot } from '../common.styles';
import { Switch } from '../switch/switch.component';
import { SidebarStarsFilter } from '../sidebar-stars-filter/sidebar-stars-filter.component';
import { AccentColorSelector } from '../accent-color-selector/accent-color-selector.component';
import { ReactComponent as ExpandIcon } from '../../assets/expand.svg';

interface Props {
    value: SettingsType;
    onChange: (value: SettingsType) => void;
    save?: boolean;
    email?: string;
    showToast: (message: string) => void;
    sources?: ReviewSource[];
    onSourceChange?: (source: ReviewSource) => void;
    rounded?: boolean;
}

export const WidgetSettings = withToast<Props>(
    ({ value, onChange, email, save = false, showToast, sources, onSourceChange, rounded }: Props) => {
        const [isLoading, setIsLoading] = useState(false);
        const [settingsExpanded, setSettingsExpanded] = useState(false);
        const onSettingsSave = async () => {
            if (save && email) {
                try {
                    setIsLoading(true);
                    await axios.patch(`${SERVER_URL}/user`, {
                        email,
                        settings: value,
                    });
                    setIsLoading(false);
                } catch (e) {
                    showToast('Error while saving the settings. Try again later.');
                    console.error(e);
                }
            }
        };

        return (
            <StyledThemeProvider theme={themeStyledComponentsDark}>
                <ThemeProvider theme={themeMuiDark}>
                    <SideBarRoot $rounded={rounded}>
                        <ContentWrapper>
                            <PlatformSelector sources={sources} onChange={onSourceChange} />
                            <MoreOptionsButton
                                $expanded={settingsExpanded}
                                onClick={() => {
                                    setSettingsExpanded(!settingsExpanded);
                                }}
                            >
                                More options <ExpandIcon />
                            </MoreOptionsButton>
                            <ResponsiveSettingsWrapper $expanded={settingsExpanded}>
                                <SidebarStarsFilter
                                    value={value.minRating}
                                    onChange={(minRating) => {
                                        onChange({
                                            ...value,
                                            minRating,
                                        });
                                    }}
                                />
                                <LayoutSelector
                                    layout={value.layout}
                                    onChange={(layout) => {
                                        onChange({ ...value, layout });
                                    }}
                                />
                                <SectionWrapper>
                                    <TextField
                                        id="title-input"
                                        size="medium"
                                        variant="outlined"
                                        placeholder="What our customers say"
                                        label={'Title'}
                                        fullWidth
                                        value={value.title}
                                        onChange={(e) => {
                                            onChange({
                                                ...value,
                                                title: e.target.value,
                                            });
                                        }}
                                    />
                                </SectionWrapper>
                                <Stack gap={'4px'}>
                                    <ResponsiveRowOptions>
                                        <AccentColorSelector
                                            value={null}
                                            onChange={(color) => {
                                                onChange({
                                                    ...value,
                                                    accentColor: color,
                                                });
                                            }}
                                        />
                                        <PaperWrapper $row>
                                            <Label
                                                label={
                                                    <Typography sx={{ marginRight: '9px' }} variant={'body2'} color="textPrimary">
                                                        Dark Mode
                                                    </Typography>
                                                }
                                                control={
                                                    <Switch
                                                        stars
                                                        checked={value.isDark || false}
                                                        onChange={(e) => {
                                                            onChange({
                                                                ...value,
                                                                isDark: e.target.checked,
                                                            });
                                                        }}
                                                    />
                                                }
                                            />
                                        </PaperWrapper>
                                    </ResponsiveRowOptions>

                                    <ResponsiveRowOptions>
                                        <PaperWrapper $row>
                                            <Label
                                                label={
                                                    <Typography sx={{ marginRight: '9px' }} variant={'body2'} color="textPrimary">
                                                        Overall
                                                    </Typography>
                                                }
                                                control={
                                                    <Switch
                                                        stars
                                                        checked={value.showSummary}
                                                        onChange={(e) => {
                                                            onChange({
                                                                ...value,
                                                                showSummary: e.target.checked,
                                                            });
                                                        }}
                                                    />
                                                }
                                            />
                                        </PaperWrapper>

                                        <PaperWrapper $row>
                                            <Label
                                                label={
                                                    <Typography sx={{ marginRight: '9px' }} variant={'body2'} color="textPrimary">
                                                        Title
                                                    </Typography>
                                                }
                                                control={
                                                    <Switch
                                                        stars
                                                        checked={value.showTitle}
                                                        onChange={(e) => {
                                                            onChange({
                                                                ...value,
                                                                showTitle: e.target.checked,
                                                            });
                                                        }}
                                                    />
                                                }
                                            />
                                        </PaperWrapper>
                                    </ResponsiveRowOptions>
                                    <ResponsiveRowOptions>
                                        {value.layout === LayoutType.carousel && (
                                            <PaperWrapper $row>
                                                <Label
                                                    label={
                                                        <Typography
                                                            sx={{ marginRight: '9px' }}
                                                            variant={'body2'}
                                                            color="textPrimary"
                                                        >
                                                            Auto-Slide
                                                        </Typography>
                                                    }
                                                    control={
                                                        <Switch
                                                            stars
                                                            checked={value.autoplay}
                                                            onChange={(e) => {
                                                                onChange({
                                                                    ...value,
                                                                    autoplay: e.target.checked,
                                                                });
                                                            }}
                                                        />
                                                    }
                                                />
                                            </PaperWrapper>
                                        )}
                                        <PaperWrapper $row>
                                            <Label
                                                label={
                                                    <Typography variant={'body2'} color="textPrimary">
                                                        Ai Highlight
                                                    </Typography>
                                                }
                                                control={
                                                    <Switch
                                                        stars
                                                        checked={value.aiHighlight}
                                                        onChange={(e) => {
                                                            onChange({
                                                                ...value,
                                                                aiHighlight: e.target.checked,
                                                            });
                                                        }}
                                                    />
                                                }
                                            />
                                        </PaperWrapper>
                                    </ResponsiveRowOptions>
                                </Stack>
                            </ResponsiveSettingsWrapper>
                            {save && email && (
                                <LoadingButton loading={isLoading} variant={'contained'} fullWidth onClick={onSettingsSave}>
                                    Save new settings changes
                                </LoadingButton>
                            )}
                        </ContentWrapper>
                    </SideBarRoot>
                </ThemeProvider>
            </StyledThemeProvider>
        );
    }
);
