import React, { ReactElement } from 'react';
import { SubsectionTitle, SubsectionWrapper } from '../common.styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { LayoutType } from '../../types/layout-type.enum';
import { ReactComponent as CarouselLogo } from '../../assets/carousel.svg';
import { ReactComponent as MasonryLogo } from '../../assets/masonry.svg';
import { ReactComponent as ListLogo } from '../../assets/list.svg';
import { ReactComponent as VideoIcon } from '../../assets/video.svg';

export const ProfileLayoutSettings = ({ value, onChange }: { value: LayoutType; onChange: (value: LayoutType) => void }) => {
    return (
        <SubsectionWrapper>
            <SubsectionTitle>Layout</SubsectionTitle>
            <Stack gap={1} sx={{ mt: 3 }}>
                <Stack direction="row" gap={1}>
                    <LayoutButton
                        active={value === LayoutType.carousel}
                        icon={<CarouselLogo />}
                        label={'Carousel'}
                        onClick={() => {
                            onChange(LayoutType.carousel);
                        }}
                    />
                    <LayoutButton
                        active={value === LayoutType.video}
                        icon={<VideoIcon />}
                        label={'Video reviews'}
                        onClick={() => {
                            onChange(LayoutType.video);
                        }}
                    />
                </Stack>
                <Stack direction="row" gap={1}>
                    <LayoutButton
                        active={value === LayoutType.masonry}
                        icon={<MasonryLogo />}
                        label={'Masonry'}
                        onClick={() => {
                            onChange(LayoutType.masonry);
                        }}
                    />
                    <LayoutButton
                        active={value === LayoutType.list}
                        icon={<ListLogo />}
                        label={'List'}
                        onClick={() => {
                            onChange(LayoutType.list);
                        }}
                    />
                </Stack>
            </Stack>
        </SubsectionWrapper>
    );
};

const LayoutButton = ({
    active,
    icon,
    label,
    onClick,
}: {
    active?: boolean;
    icon: ReactElement;
    label: string;
    onClick?: () => void;
}) => (
    <Button
        variant="outlined"
        className={active ? 'Mui-active' : ''}
        sx={{
            borderRadius: '12px',
            fontSize: '14px',
            fontWeight: '400',
            padding: '16px 12px !important',
            flex: '1 1 30%',
            path: {
                transition: 'fill 0.1s linear',
                // @ts-ignore
                fill: (theme) => theme.palette.grey.secondary,
            },
            '&:hover path': {
                fill: (theme) => theme.palette.primary.main,
            },
            '&.Mui-active path': {
                fill: (theme) => theme.palette.primary.main,
            },
        }}
        onClick={onClick}
    >
        <Stack gap={1} alignItems="center">
            {icon}
            {label}
        </Stack>
    </Button>
);
