import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { ContentWrapper } from '../instruction-dialog.styles';

export const WeeblyArticle = () => (
    <>
        <DialogTitle textAlign="left" align="center" sx={{ p: 0, mt: 3, fontSize: '18px' }}>
            How to add widget to Weebly website
        </DialogTitle>
        <ContentWrapper>
            <Typography>
                Start by opening your website in the editor view in Weebly. You should automatically be in the{' '}
                <strong>Build</strong> tab, but if this is not the case, navigate to it from the row of tabs near the top. Once
                there, look for an element called <strong>Embed Code</strong> with a little brackets icon on it. Grab the element,
                and drag the it onto the page.
            </Typography>
            <img src="/instructions/weebly-1.png" />
            <Typography>
                Now that the elements is in its spot, click on it to open up the editing dialogue box. In the box, click{' '}
                <strong>Edit Custom HTML</strong>, and the element turns into a plain text (HTML) box. Take the embed code you
                copied before and paste it here.
            </Typography>
            <img src="/instructions/weebly-2.png" />
            <Typography>
                Next, just click anywhere so the code element sets itself up and you should see the embed pop up in the preview
                immediately. Check that everything looks and reacts as it should, and you're almost done!
            </Typography>
            <Typography>
                The only thing left to do is to publish the changes made to your site by clicking the large Publish button in the
                top right corner. When you see the "website published" window pop up, the changes are live.
            </Typography>
            <img src="/instructions/weebly-3.png" />
            <Typography>Congratulations, you now have testimonials on your website!</Typography>
        </ContentWrapper>
    </>
);
