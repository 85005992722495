import styled from 'styled-components';

export const ReviewFormRoot = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 8px;
    background: ${({ theme }) => theme.colors.bg};
`;

export const ReviewFormContentWrapper = styled.div<{ $hideLogo: boolean; $smallPadding?: boolean }>`
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    max-width: min(500px, 100%);
    padding: ${({ $smallPadding }) => ($smallPadding ? '24px' : '40px 40px 24px 40px')};
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.bg};
    border-radius: 30px;
    ${({ $hideLogo }) => $hideLogo && `${LogoImage} {display: none;}`}
`;

export const StarsRow = styled.div`
    display: flex;
    justify-content: center;
`;
export const ReviewFormStar = styled.div<{ $active: boolean }>`
    user-select: none;
    cursor: pointer;
    transition: transform 0.25s;

    svg {
        width: 64px;
        height: 64px;
    }

    path {
        transition: fill 0.25s;
        fill: ${({ $active }) => ($active ? '#ffca05' : '#e6e8e8')};
    }

    &:hover {
        transform: scale(1.1);
        & path {
            fill: #ffca05;
        }
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 320px;
`;

export const ThankYouContainer = styled.div`
    margin-top: auto;
    margin-bottom: auto;
`;

export const PlatformRedirectRoot = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
`;

export const LogoImage = styled.img`
    max-width: 200px;
    max-height: 64px;
    height: auto;
    border-radius: 12px;
`;

export const ReviewPageRoot = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
`;

export const ReviewSurveyWrapper = styled.div`
    box-shadow: 0 8px 40px #0e0f0f0d, 0 1px 1px #0e0f0f14;
    background: #fff;
    border-radius: 30px;
    padding: 16px;
    max-width: calc(100% - 8px);
`;

export const ReviewFormTitle = styled.div`
    position: absolute;
    left: 50%;
    top: 24px;
    transform: translateX(-50%);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.64px;
`;

export const ReviewFormMainText = styled.div<{ $noWrap?: boolean; $small?: boolean }>`
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    font-size: ${({ $small }) => ($small ? '18px' : '20px')};
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.72px;
    ${({ $noWrap }) => $noWrap && 'white-space: nowrap;'}
`;

export const ReviewFormDescription = styled.div`
    color: ${({ theme }) => theme.colors.textSecondary};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.28px;
    margin-top: 8px;
`;

export const CompanyTitle = styled.div`
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 127.273% */
    letter-spacing: -0.88px;
`;

export const ActionButtonsContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    button {
        flex: 1 1 48%;
        border-radius: 12px;
    }
    & .Mui-disabled {
        opacity: 0.5;
    }
    #form-back {
        background: ${({ theme }) => theme.colors.light};
        color: ${({ theme }) => theme.colors.text};
        transition: background 0.25s;

        &:hover {
            background: rgba(0, 0, 0, 0.09);
        }
    }

    #form-submit {
        background: #06070d;
        color: #fff;
        transition: opacity 0.25s;

        &:hover {
            opacity: 80%;
        }
    }
`;
