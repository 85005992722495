import styled from 'styled-components';
import Button from '@mui/material/Button';

export const Root = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    flex-wrap: wrap;
`;

export const LayoutButton = styled(Button)<{ $active?: boolean }>`
    border: 1px solid ${({ theme }) => theme.colors.border};
    color: ${({ theme }) => theme.colors.text};
    display: flex;
    flex-direction: column;
    padding: 16px;
    flex: 1;
    height: fit-content;
    gap: 4px;
    border-radius: 16px;
    path {
        fill: ${({ theme }) => theme.colors.text};
    }
    ${({ $active, theme }) =>
        $active &&
        `background-color: rgba(26, 115, 232, 0.04); 1px solid ${theme.colors.activeBorderColor};
        &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        inset: -1px;
        border-radius: 16px;
        padding: 1px;
        background: radial-gradient(ellipse at top, #4a14ff 60%, #9876f5);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
        `}
`;
