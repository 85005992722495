import React, { ReactElement, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import { ModalTitle } from '../common.styles';
import { ReactComponent as GoogleLogo } from '../../assets/google.svg';
import { ReactComponent as YelpLogo } from '../../assets/yelp.svg';
import { ReactComponent as FacebookLogo } from '../../assets/facebook.svg';
import { ReactComponent as TrustpilotLogo } from '../../assets/trustpilot.svg';
import { ReviewSource } from '../../types/review-source.enum';
import { PlatformsManageComponent } from '../platforms-manage/platforms-manage.component';
import { UserPopulatedType } from '../../types/user-data.type';
import { ReviewsPreviewType } from '../../types/review-data.type';

interface Props {
    open: boolean;
    onClose: () => void;
    sources: ReviewSource[];
    userPopulatedData: UserPopulatedType;
    onAdded: (source: ReviewSource, previewData?: ReviewsPreviewType) => void;
}

export const AddPlatformModal = ({ open, onClose, sources, userPopulatedData, onAdded }: Props) => {
    const [selectedPlatform, setSelectedPlatform] = useState<ReviewSource>();
    const onSelectPlatform = (value: ReviewSource) => {
        if (selectedPlatform !== value) {
            setSelectedPlatform(value);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    borderRadius: '24px',
                    border: '1px solid #E8E9F2',
                    width: '480px',
                    padding: '24px',
                    gap: '24px',
                },
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                <ModalTitle>Add platform</ModalTitle>
                <Button size="medium" variant="outlined" color="info" onClick={onClose}>
                    Close
                </Button>
            </Stack>
            <Stack direction="row" gap={1}>
                <PlatformButton
                    label={'Google'}
                    icon={<GoogleLogo />}
                    disabled={sources.includes(ReviewSource.google)}
                    active={selectedPlatform === ReviewSource.google}
                    onClick={() => onSelectPlatform(ReviewSource.google)}
                />
                <PlatformButton
                    label={'Yelp'}
                    icon={<YelpLogo />}
                    disabled={sources.includes(ReviewSource.yelp)}
                    active={selectedPlatform === ReviewSource.yelp}
                    onClick={() => onSelectPlatform(ReviewSource.yelp)}
                />
                <PlatformButton
                    label={'Facebook'}
                    icon={<FacebookLogo />}
                    disabled={sources.includes(ReviewSource.facebook)}
                    active={selectedPlatform === ReviewSource.facebook}
                    onClick={() => onSelectPlatform(ReviewSource.facebook)}
                />
                <PlatformButton
                    label={'Trustpilot'}
                    icon={<TrustpilotLogo />}
                    disabled={sources.includes(ReviewSource.trustpilot)}
                    active={selectedPlatform === ReviewSource.trustpilot}
                    onClick={() => onSelectPlatform(ReviewSource.trustpilot)}
                />
            </Stack>
            <PlatformsManageComponent userPopulatedData={userPopulatedData} source={selectedPlatform} onAdded={onAdded} />
        </Dialog>
    );
};

const PlatformButton = ({
    active,
    label,
    icon,
    onClick,
    disabled,
}: {
    active?: boolean;
    label: string;
    icon: ReactElement;
    onClick: () => void;
    disabled?: boolean;
}) => (
    <Button
        variant="outlined"
        disabled={disabled}
        className={active ? 'Mui-active' : ''}
        sx={{
            borderRadius: '12px',
            width: '120px',
            padding: '5px 16px !important',
        }}
        onClick={onClick}
    >
        <Stack gap={1} alignItems="center" sx={{ py: 1 }}>
            {icon}
            {label}
        </Stack>
    </Button>
);
