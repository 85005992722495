import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import { AddContactsCsv } from '../collect-section/add-contacts-csv.component';
import { ModalTitle } from '../../components/common.styles';
import { ImportContactsRoot } from './import-contacts-csv.styles';
import { ContactType } from '../../types/contact.type';

export const ImportContactsCSV = ({
    open,
    onClose,
    onChange,
}: {
    open: boolean;
    onClose: () => void;
    onChange: (contacts: ContactType[], channels: number) => Promise<void>;
}) => {
    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: '24px', overflow: 'visible' } }}>
            <ImportContactsRoot>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                    <ModalTitle>Import CSV</ModalTitle>
                    <Button size="medium" variant="outlined" color="info" onClick={onClose}>
                        Close
                    </Button>
                </Stack>
                <AddContactsCsv onChange={onChange} onClose={onClose} />
            </ImportContactsRoot>
        </Dialog>
    );
};
