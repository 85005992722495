import React from 'react';
import Stack from '@mui/material/Stack/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BulletRow, FeaturesContainer, LightPlanContainer, PlanWrapper, PricingText } from './account-settings.styles';
import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import { getCalApi } from '@calcom/embed-react';
import { useEffect } from 'react';

export const EnterprisePlanCard = () => {
    useEffect(() => {
        (async function () {
            const cal = await getCalApi({ namespace: 'marketing-plan' });
            cal('ui', { styles: { branding: { brandColor: '#000000' } }, hideEventTypeDetails: false, layout: 'month_view' });
        })();
    }, []);

    return (
        <PlanWrapper>
            <LightPlanContainer>
                <Stack height="30px" justifyContent="center">
                    <LogoIcon />
                </Stack>
                <Stack gap={2}>
                    <Typography sx={{ fontSize: '24px', fontWeight: 500 }}>Marketing</Typography>
                    <Typography sx={{ fontSize: '16px', color: 'grey.main', height: '72px' }}>
                        Provides advanced marketing tools to boost your business revenue and client database.
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="flex-end" gap={0.5} sx={{ mb: '28px' }}>
                    <PricingText $dark>Custom</PricingText>
                </Stack>
                <Button
                    variant="contained"
                    size="large"
                    sx={{ fontSize: '16px', padding: '9px 21px' }}
                    data-cal-namespace="marketing-plan"
                    data-cal-link="vlad-bond/marketing-plan"
                    data-cal-config='{"layout":"month_view"}'
                >
                    Book a call
                </Button>
            </LightPlanContainer>
            <FeaturesContainer>
                <BulletRow label="SMS & Email Promotional Campaigns" />
                <BulletRow label="Customer Database Reactivation Strategies" />
                <BulletRow label="Unlimited SMS Messaging" />
                <BulletRow label="Custom Review Platform Integration" />
                <BulletRow label="Advanced Customer Segmentation" />
                <BulletRow label="In-Depth Campaign Analytics" />
                <Stack width="fit-content" borderRadius={46} sx={{ padding: '4px 12px', background: '#F7F8FA' }}>
                    <Typography fontSize="14px" fontWeight="500">
                        & PRO FEATURES
                    </Typography>
                </Stack>
            </FeaturesContainer>
        </PlanWrapper>
    );
};
