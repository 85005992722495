import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    AvatarImage,
    AvatarNameLetter,
    Content,
    DateText,
    FacebookLogo,
    GoogleLogo,
    HeaderTextWrapper,
    HeaderWrapper,
    Link,
    MainText,
    NameTextWrapper,
    NameWrapper,
    RatingContainer,
    ReviewCountLabel,
    ReviewUserName,
    ReviewCardRoot,
    TrustPilotLogo,
    YelpLogo,
    CardFooterWrapper,
    ReviewImage,
    ReviewImageWrapper,
    StyledVideo,
    VideoReviewContainer,
    VideoControlsContainer,
    VideoPlayContainer,
    LockContainer,
    ReviewImageLockedWrapper,
} from './review-card.styles';
import { ReviewType } from '../../types/review.type';
import { StarsRating } from '../stars-rating/stars-rating.component';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment from 'moment-mini';
import { ReviewSource } from '../../types/review-source.enum';
import { isTextOverflowing } from '../../utils/isTextOverflowing';
import { VerifiedIcon } from '../../assets/verified';
import { ReactComponent as PlayIcon } from '../../assets/play.svg';
import { ReactComponent as PauseIcon } from '../../assets/pause.svg';
import { themeMui, themeStyledComponents } from '../../theme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';

export const trustpilotColors = ['#fffbd6', '#ffdebf', '#d1f9ea', '#fddce5'];

export const ReviewCard = ({
    item,
    accentColor,
    aiHighlight,
    isProPlan,
    isWidget,
}: {
    item?: ReviewType;
    accentColor?: string;
    aiHighlight?: boolean;
    isProPlan?: boolean;
    isWidget?: boolean;
}) => {
    const [isShowingMore, setIsShowingMore] = useState(false);
    const [isLongText, setIsLongText] = useState(false);
    const [isImageError, setIsImageError] = useState(false);
    const textContainer = useRef<HTMLSpanElement>();
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const videoEl = useRef<HTMLVideoElement>();

    const openOriginalReview = () => {
        if (item) {
            window.open(item.url, '_blank');
        }
    };

    useEffect(() => {
        const listener = () => {
            setIsShowingMore(false);
        };
        document.addEventListener('hr-carousel-change', listener);
        return () => {
            document.removeEventListener('hr-carousel-change', listener);
        };
    }, []);

    useEffect(() => {
        if (textContainer.current && item) {
            setIsLongText(isTextOverflowing(textContainer.current));
        }
    }, [textContainer.current, item]);

    const metadata = useMemo(() => {
        if (item?.source === ReviewSource.yelp) {
            return { logo: <YelpLogo onClick={openOriginalReview} />, label: 'Check on Yelp' };
        } else if (item?.source === ReviewSource.facebook) {
            return { logo: <FacebookLogo onClick={openOriginalReview} />, label: 'Check on Facebook' };
        } else if (item?.source === ReviewSource.trustpilot) {
            return { logo: <TrustPilotLogo onClick={openOriginalReview} />, label: 'Check on Trustpilot' };
        }
        return { logo: <GoogleLogo onClick={openOriginalReview} />, label: 'Check on Google' };
    }, [item?.source]);

    const avatarConfig = useMemo(() => {
        if (item?.source === ReviewSource.trustpilot) {
            return { bg: trustpilotColors[Math.floor(Math.random() * trustpilotColors.length)], text: '#000' };
        }
        return { bg: 'rgb(176, 65,33)', text: '#ffffff' };
    }, [item?.source]);

    const text = useMemo(() => {
        if (item) {
            const newText = item?.text;
            if (aiHighlight && item.mainStart !== -1 && item.mainLength) {
                const startIndex = item.mainStart;
                const middleLength = item.mainLength;
                const beforePart = item.text.slice(0, startIndex);
                const middlePart = item.text.slice(startIndex, startIndex + middleLength);
                const afterPart = item.text.slice(startIndex + middleLength);
                return `${beforePart}<span class='main-sentence'><svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
<g id='SPARK'>
<g id='Regular-S'>
<path id='Vector' d='M9.0949 5.44139C9.19278 5.44139 9.24615 5.37882 9.26396 5.29838C9.48641 4.14527 9.4686 4.12741 10.6609 3.89501C10.7499 3.87712 10.8122 3.82348 10.8122 3.72516C10.8122 3.62684 10.7499 3.5732 10.6609 3.55533C9.4686 3.32293 9.48641 3.30505 9.26396 2.15196C9.24615 2.06257 9.19278 2 9.0949 2C9.00592 2 8.95252 2.06257 8.93474 2.15196C8.70338 3.30505 8.73007 3.32293 7.53773 3.55533C7.44876 3.5732 7.38647 3.62684 7.38647 3.72516C7.38647 3.82348 7.44876 3.87712 7.53773 3.89501C8.73007 4.12741 8.70338 4.14527 8.93474 5.29838C8.95252 5.37882 9.00592 5.44139 9.0949 5.44139Z' fill='#FFB905'/>
<path id='Vector_2' d='M5.75839 10.2954C5.90077 10.2954 5.99865 10.1971 6.01643 10.0541C6.25669 8.18593 6.33676 8.17697 8.26766 7.80156C8.40112 7.78368 8.50789 7.68536 8.50789 7.54233C8.50789 7.39038 8.40112 7.29204 8.26766 7.27417C6.33676 7.02389 6.2478 6.9345 6.01643 5.03056C5.99865 4.87862 5.90077 4.78027 5.75839 4.78027C5.60712 4.78027 5.50924 4.87862 5.49146 5.03951C5.2779 6.90768 5.14443 6.90768 3.24025 7.27417C3.10678 7.30099 3 7.39038 3 7.54233C3 7.69429 3.10678 7.78368 3.27584 7.80156C5.16222 8.09654 5.2779 8.16804 5.49146 10.0362C5.50924 10.1971 5.60712 10.2954 5.75839 10.2954Z' fill='#FFB905'/>
<path id='Vector_3' d='M10.4831 17.9998C10.67 17.9998 10.8212 17.8657 10.8568 17.6602C11.3729 13.9327 11.9424 13.2802 15.6351 12.8422C15.8486 12.8243 15.9999 12.6634 15.9999 12.4668C15.9999 12.2612 15.8486 12.1092 15.6351 12.0824C11.9424 11.6444 11.3729 11.0008 10.8568 7.27341C10.8212 7.05888 10.67 6.9248 10.4831 6.9248C10.2873 6.9248 10.1361 7.05888 10.1094 7.27341C9.5844 11.0008 9.01492 11.6444 5.32223 12.0824C5.10869 12.1092 4.96631 12.2612 4.96631 12.4668C4.96631 12.6634 5.10869 12.8243 5.32223 12.8422C9.00603 13.3428 9.56661 13.9327 10.1094 17.6602C10.1361 17.8657 10.2873 17.9998 10.4831 17.9998Z' fill='#FFB905'/>
</g>
</g>
</svg>${middlePart}</span>${afterPart || ''}`;
            }

            return newText;
        }
        return '';
    }, [item, aiHighlight]);

    const toggleTextClamp = () => {
        if (textContainer.current) {
            if (!isShowingMore) {
                (textContainer.current as HTMLSpanElement).style.webkitLineClamp = 'unset';
            } else {
                (textContainer.current as HTMLSpanElement).style.webkitLineClamp = '5';
            }
        }
    };

    const toggleFullText = () => {
        if (!isShowingMore) {
            toggleTextClamp();
        }
        setIsShowingMore(!isShowingMore);
    };

    const toggleVideoPlay = () => {
        const newValue = !isVideoPlaying;
        setIsVideoPlaying(newValue);
        if (newValue) {
            videoEl.current?.play();
        } else {
            videoEl.current?.pause();
        }
    };

    return (
        <ReviewCardRoot>
            <Content $hideBorder={!!item.videoUrl} $noPadding={!!item.videoUrl}>
                {!item.videoUrl ? (
                    <>
                        <HeaderWrapper>
                            {item ? (
                                item.avatar && !isImageError ? (
                                    <AvatarImage
                                        onClick={openOriginalReview}
                                        src={item?.avatar}
                                        referrerPolicy="no-referrer"
                                        $round={item?.source === ReviewSource.yelp || item?.source === ReviewSource.facebook}
                                        onError={() => setIsImageError(true)}
                                    />
                                ) : (
                                    <AvatarNameLetter
                                        $bgColor={avatarConfig.bg}
                                        $textColor={avatarConfig.text}
                                        onClick={openOriginalReview}
                                    >
                                        {item?.userName?.slice(0, 1)}
                                    </AvatarNameLetter>
                                )
                            ) : (
                                <Skeleton variant="circular" width={'2.5em'} height={'2.5em'} sx={{ marginRight: '8px' }} />
                            )}
                            <HeaderTextWrapper>
                                <Tooltip
                                    placement="top-start"
                                    /*@ts-ignore */
                                    title={
                                        <Typography
                                            color="white"
                                            typography="subtitle1"
                                            sx={{ fontSize: '11px' }}
                                            textAlign="center"
                                        >
                                            {metadata.label}
                                        </Typography>
                                    }
                                >
                                    <ReviewUserName onClick={openOriginalReview}>
                                        {item ? (
                                            <NameWrapper>
                                                <NameTextWrapper>{item.userName}</NameTextWrapper>
                                                <VerifiedIcon fill="#1EA1F1" />
                                            </NameWrapper>
                                        ) : (
                                            <Skeleton variant="text" width="130px" />
                                        )}
                                    </ReviewUserName>
                                </Tooltip>
                                <ReviewCountLabel>
                                    {item ? item.numReviews : <Skeleton variant="text" width="100px" />}
                                </ReviewCountLabel>
                            </HeaderTextWrapper>
                            <Tooltip
                                title={
                                    <Typography color="white" typography="subtitle1" sx={{ fontSize: '11px' }} textAlign="center">
                                        {metadata.label}
                                    </Typography>
                                }
                            >
                                {metadata.logo}
                            </Tooltip>
                        </HeaderWrapper>
                        <RatingContainer>
                            <StarsRating value={item?.rating} />
                            <DateText>
                                {item ? moment(item.timestamp / 1000).fromNow() : <Skeleton variant="text" width="90px" />}
                            </DateText>
                        </RatingContainer>
                        {isLongText && item ? (
                            <div>
                                <MainText
                                    ref={(ref) => {
                                        textContainer.current = ref;
                                    }}
                                    style={{
                                        WebkitLineClamp: '5',
                                        maxHeight: isShowingMore ? '500px' : '100px',
                                    }}
                                    onTransitionEnd={() => {
                                        if (!isShowingMore) {
                                            (textContainer.current as HTMLSpanElement).style.webkitLineClamp = '5';
                                        }
                                    }}
                                    dangerouslySetInnerHTML={{ __html: text }}
                                />
                                <CardFooterWrapper style={{ marginTop: '16px' }}>
                                    <Link $accentColor={accentColor} onClick={toggleFullText}>
                                        {isShowingMore ? 'Less' : 'More'}
                                    </Link>
                                    {isProPlan ? (
                                        <Stack direction="row" gap="4px">
                                            {item.images?.slice(0, 4).map((src, index) => (
                                                <ReviewImageWrapper
                                                    $index={index}
                                                    $length={Math.min(item.images.length, 4)}
                                                    key={index}
                                                >
                                                    <ReviewImage referrerPolicy="no-referrer" src={src} />
                                                </ReviewImageWrapper>
                                            ))}
                                        </Stack>
                                    ) : (
                                        !isWidget && (
                                            <Stack direction="row" gap="4px">
                                                {item.images?.slice(0, 4).map((src, index) => (
                                                    <ReviewImageLockedWrapper
                                                        $index={index}
                                                        $length={Math.min(item.images.length, 4)}
                                                        key={index}
                                                    >
                                                        <ReviewImage referrerPolicy="no-referrer" src={src} />
                                                        <Tooltip
                                                            placement="top"
                                                            disableHoverListener={isProPlan}
                                                            title={
                                                                <Typography fontSize="14px" fontWeight="500">
                                                                    PRO Feature
                                                                </Typography>
                                                            }
                                                        >
                                                            <LockContainer $radius={6} $border>
                                                                <LockIcon />
                                                            </LockContainer>
                                                        </Tooltip>
                                                    </ReviewImageLockedWrapper>
                                                ))}
                                            </Stack>
                                        )
                                    )}
                                </CardFooterWrapper>
                            </div>
                        ) : item ? (
                            <Stack flexGrow={1}>
                                <MainText
                                    ref={(ref) => {
                                        textContainer.current = ref;
                                    }}
                                    dangerouslySetInnerHTML={{ __html: text }}
                                />
                                <CardFooterWrapper>
                                    <div></div>
                                    {isProPlan ? (
                                        <Stack direction="row" gap="4px" sx={{ py: '4px' }}>
                                            {item.images?.slice(0, 4).map((src, index) => (
                                                <ReviewImageWrapper
                                                    $index={index}
                                                    $length={Math.min(item.images.length, 4)}
                                                    key={index}
                                                >
                                                    <ReviewImage referrerPolicy="no-referrer" src={src} />
                                                </ReviewImageWrapper>
                                            ))}
                                        </Stack>
                                    ) : (
                                        !isWidget && (
                                            <Stack direction="row" gap="4px" sx={{ py: '4px' }}>
                                                {item.images?.slice(0, 4).map((src, index) => (
                                                    <ReviewImageLockedWrapper
                                                        $index={index}
                                                        $length={Math.min(item.images.length, 4)}
                                                        key={index}
                                                    >
                                                        <ReviewImage referrerPolicy="no-referrer" src={src} />
                                                        <Tooltip
                                                            placement="top"
                                                            disableHoverListener={isProPlan}
                                                            title={
                                                                <Typography fontSize="14px" fontWeight="500">
                                                                    PRO Feature
                                                                </Typography>
                                                            }
                                                        >
                                                            <LockContainer $radius={6} $border>
                                                                <LockIcon />
                                                            </LockContainer>
                                                        </Tooltip>
                                                    </ReviewImageLockedWrapper>
                                                ))}
                                            </Stack>
                                        )
                                    )}
                                </CardFooterWrapper>
                            </Stack>
                        ) : (
                            <MainText
                                ref={(ref) => {
                                    textContainer.current = ref;
                                }}
                            >
                                <Skeleton variant="text" />
                                <Skeleton variant="text" width="90%" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" width="70%" />
                                <Skeleton variant="text" width="90%" />
                                <Skeleton variant="text" width="30%" />
                            </MainText>
                        )}
                    </>
                ) : (
                    <StyledThemeProvider theme={themeStyledComponents}>
                        <ThemeProvider theme={themeMui}>
                            <VideoReviewContainer onClick={toggleVideoPlay}>
                                <StyledVideo
                                    src={item.videoUrl}
                                    preload="metadata"
                                    playsInline
                                    poster={item.poster}
                                    ref={videoEl}
                                    onEnded={() => {
                                        setIsVideoPlaying(false);
                                    }}
                                />
                                <VideoControlsContainer>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                        <Stack gap={0.5}>
                                            <NameTextWrapper $light $bold>
                                                {item.userName}
                                            </NameTextWrapper>
                                            <RatingContainer>
                                                <StarsRating value={item?.rating} />
                                                <DateText $light>
                                                    {item ? (
                                                        moment(item.timestamp / 1000).fromNow()
                                                    ) : (
                                                        <Skeleton variant="text" width="90px" />
                                                    )}
                                                </DateText>
                                            </RatingContainer>
                                        </Stack>
                                        <VideoPlayContainer onClick={toggleVideoPlay}>
                                            {isVideoPlaying ? <PauseIcon /> : <PlayIcon />}
                                        </VideoPlayContainer>
                                    </Stack>
                                </VideoControlsContainer>
                                {!isProPlan && (
                                    <Tooltip
                                        placement="top"
                                        disableHoverListener={isProPlan}
                                        title={
                                            <Typography fontSize="14px" fontWeight="500">
                                                PRO Feature
                                            </Typography>
                                        }
                                    >
                                        <LockContainer $radius={16}>
                                            <LockIcon />
                                        </LockContainer>
                                    </Tooltip>
                                )}
                            </VideoReviewContainer>
                        </ThemeProvider>
                    </StyledThemeProvider>
                )}
            </Content>
        </ReviewCardRoot>
    );
};
