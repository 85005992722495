import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { WordpressArticle } from './articles/wordpress.article';
import { InstructionTypes } from '../../types/instruction.type';
import { ElementorArticle } from './articles/elementor.article';
import { WixArticle } from './articles/wix.article';
import { SquarespaceArticle } from './articles/squarespace.article';
import { WebflowArticle } from './articles/webflow.article';
import { WeeblyArticle } from './articles/weebly.article';
import { GodaddyArticle } from './articles/godaddy.article';

interface Props {
    open: boolean;
    onClose: () => void;
    onBack: () => void;
    type: InstructionTypes;
}

export const InstructionDialog = ({ onClose, onBack, type }: Props) => (
    <Stack maxWidth="509px" sx={{ overflow: 'hidden' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ pb: 2 }}>
            <Button size="medium" variant="outlined" color="info" onClick={onBack}>
                Back
            </Button>
            <Button size="medium" variant="outlined" color="info" onClick={onClose}>
                Close
            </Button>
        </Stack>
        <Stack sx={{ overflow: 'scroll' }}>
            {type === InstructionTypes.wordpress && <WordpressArticle />}
            {type === InstructionTypes.elementor && <ElementorArticle />}
            {type === InstructionTypes.wix && <WixArticle />}
            {type === InstructionTypes.squarespace && <SquarespaceArticle />}
            {type === InstructionTypes.webflow && <WebflowArticle />}
            {type === InstructionTypes.weebly && <WeeblyArticle />}
            {type === InstructionTypes.godaddy && <GodaddyArticle />}
        </Stack>
    </Stack>
);
