import { ReviewType } from '../../types/review.type';
import { ReviewCard } from '../review-card/review-card.component';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Masonry from '@mui/lab/Masonry';
import Button from '@mui/material/Button';
import { MasonryRoot } from './reviews-masonry-grid.styles';
import { defaultAccentColor } from '../../constants';

const SHOWING_ITEMS_LIMIT = 20;
const NEW_PAGE_OFFSET = 20;
export const ReviewsMasonryGridComponent = ({
    items,
    accentColor = defaultAccentColor,
    aiHighlight,
    isProPlan,
    isWidget,
}: {
    items?: ReviewType[];
    accentColor?: string;
    aiHighlight?: boolean;
    isProPlan?: boolean;
    isWidget?: boolean;
}) => {
    const [columns, setColumns] = useState(4);
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(items?.length > SHOWING_ITEMS_LIMIT);
    const [currentPage, setCurrentPage] = useState(0);
    const masonryRef = useRef<HTMLDivElement>();

    const calculateColumns = () => {
        if (masonryRef.current) {
            const containerWidth = masonryRef.current.offsetWidth;
            const breakpoints = {
                // xs
                1: 0,
                // sm
                2: 600,
                // md
                3: 900,
                // lg
                4: 1200,
                // xl
                5: 1536,
                // xxl
                6: 1900,
                // xxxl
                7: 2200,
            };
            let currentBreakpoint = 4;

            for (const breakpoint in breakpoints) {
                if (containerWidth >= breakpoints[breakpoint]) {
                    currentBreakpoint = parseInt(breakpoint, 10);
                }
            }
            setColumns(currentBreakpoint);
            return;
        }
        setColumns(4);
    };

    useEffect(() => {
        calculateColumns();
    }, [masonryRef.current]);

    useEffect(() => {
        const callback = () => {
            calculateColumns();
        };

        window.addEventListener('resize', callback);
        return () => {
            window.removeEventListener('resize', callback);
        };
    }, []);

    useEffect(() => {
        if (items) {
            setShowLoadMoreButton(items.length > SHOWING_ITEMS_LIMIT);
        }
    }, [items]);

    const showingItems = useMemo(() => {
        return items.slice(0, SHOWING_ITEMS_LIMIT);
    }, [items]);

    const onShowMore = () => {
        showingItems.push(
            ...items.slice(
                SHOWING_ITEMS_LIMIT + currentPage * NEW_PAGE_OFFSET,
                SHOWING_ITEMS_LIMIT + (currentPage + 1) * NEW_PAGE_OFFSET
            )
        );
        setCurrentPage(currentPage + 1);
        setShowLoadMoreButton(showingItems.length < items.length);
    };

    return (
        <MasonryRoot>
            <Masonry sx={{ margin: 0 }} ref={masonryRef} columns={columns} spacing={1}>
                {items
                    ? showingItems.map((el, index) => (
                          <ReviewCard
                              key={index}
                              item={el}
                              accentColor={accentColor}
                              aiHighlight={aiHighlight}
                              isProPlan={isProPlan}
                              isWidget={isWidget}
                          />
                      ))
                    : [
                          <ReviewCard key="0" />,
                          <ReviewCard key="1" />,
                          <ReviewCard key="2" />,
                          <ReviewCard key="3" />,
                          <ReviewCard key="4" />,
                          <ReviewCard key="5" />,
                          <ReviewCard key="6" />,
                          <ReviewCard key="7" />,
                          <ReviewCard key="8" />,
                          <ReviewCard key="9" />,
                          <ReviewCard key="10" />,
                          <ReviewCard key="11" />,
                      ]}
            </Masonry>
            {showLoadMoreButton && (
                <Button
                    size="large"
                    sx={{
                        width: 'fit-content',
                        mx: 'auto',
                        color: `${accentColor} !important`,
                        backgroundColor: `${accentColor}1F`,
                        transition: 'all 0.1s',
                        '&:hover': { backgroundColor: `${accentColor}1F`, opacity: 0.7 },
                        fontFamily: `'CabinetGrotesk', 'Inter', sans-serif`,
                    }}
                    onClick={onShowMore}
                >
                    Load More
                </Button>
            )}
        </MasonryRoot>
    );
};

export default ReviewsMasonryGridComponent;
