import React from 'react';
import { ContentText, DateText, Root, Title } from './notification.styles';
import MessageIcon from '../../assets/message.png';
import Stack from '@mui/material/Stack';

export const Notification = ({ size, companyName }: { size?: 'small' | 'medium'; companyName: string }) => {
    return (
        <Root $size={size}>
            <img src={MessageIcon} alt="Message" />
            <Stack sx={{ width: '100%' }} gap={0.5}>
                <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between">
                    <Title $size={size}>{companyName}</Title>
                    <DateText $size={size}>{size === 'small' ? '2 days ago' : 'Now'}</DateText>
                </Stack>
                <ContentText $size={size}>
                    {size === 'small'
                        ? `Alex, it's ${companyName}. We have a quick favor to ask, would you mind leaving us a quick review?`
                        : `Hey Alex, it's ${companyName}. Just checking in to see if you had a chance to leave us a quick review yet?`}
                    <br />
                    <br />
                    Reply START to receive review link.
                </ContentText>
            </Stack>
        </Root>
    );
};
