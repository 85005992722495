import styled from 'styled-components';

export const TabsWrapper = styled.div`
    .MuiTab-root {
        color: ${({ theme }) => theme.colors.text};
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.72px;
        padding: 14px 12px;
    }
`;
