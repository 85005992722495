import styled from 'styled-components';

export const PlatformImage = styled.img`
    width: 32px;
    height: 32px;
    object-fit: contain;
`;

export const ProfileRoot = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    width: 100%;
    background: ${({ theme }) => theme.colors.bg};
    min-height: 100vh;
`;

export const ProfilePageWrapper = styled.div<{ $profile?: boolean }>`
    background: transparent;
    width: 100%;

    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    @media (max-width: 768px) {
        gap: 40px;
    }
`;

export const ProfileSideLayout = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    gap: 24px;
    background: ${({ theme }) => theme.colors.light};
`;
