import React, { ChangeEventHandler } from 'react';
import { SwitchWrapper, Checkbox, Slider, InactiveState, ActiveState } from './switch.styles';
import { StarsBackground } from '../stars-background/stars-background.component';

export const Switch = ({
    checked,
    onChange,
    stars,
    disabled,
}: {
    checked?: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    stars?: boolean;
    disabled?: boolean;
}) => (
    <SwitchWrapper checked={checked} $disabled={disabled}>
        <Checkbox disabled={disabled} type="checkbox" checked={checked} onChange={onChange} />
        <Slider>
            <InactiveState />
            <ActiveState>{stars && <StarsBackground small />}</ActiveState>
        </Slider>
    </SwitchWrapper>
);
