import React, { useEffect } from 'react';
import Carousel from '../carousel';
import 'react-multi-carousel/lib/styles.css';
import { ReviewCard } from '../review-card/review-card.component';
import { ChevronButton, Root } from './reviews-carousel.styles';
import { ReviewType } from '../../types/review.type';

const partialVisibilityGutter = 80;
let loaded = false;

export const ReviewsCarouselComponent = ({
    items,
    autoplay,
    accentColor,
    aiHighlight,
    isProPlan,
    isWidget,
    waitUntilPageLoad,
}: {
    items?: ReviewType[];
    autoplay: boolean;
    accentColor?: string;
    aiHighlight?: boolean;
    isProPlan?: boolean;
    isWidget?: boolean;
    waitUntilPageLoad?: boolean;
}) => {
    const [hovered, setIsHovered] = React.useState(false);
    const [isPageLoaded, setIsPageLoaded] = React.useState(loaded);

    useEffect(() => {
        if (waitUntilPageLoad && !loaded) {
            window.addEventListener('load', () => {
                setIsPageLoaded(true);
                loaded = true;
            });
        } else {
            setIsPageLoaded(true);
        }
    }, []);

    return (
        <Root $accentColor={accentColor} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <Carousel
                autoPlay={isPageLoaded && autoplay && !hovered}
                autoPlaySpeed={items?.length > 8 ? 5000 : 3000}
                infinite
                responsive={responsive}
                renderButtonGroupOutside
                customButtonGroup={<ButtonGroup />}
                arrows={false}
                showDots
                renderDotsOutside
                centerMode
                partialVisible
                slidesToSlide={items?.length > 8 ? null : 1}
                beforeChange={() => {
                    document.dispatchEvent(new Event('hr-carousel-change'));
                }}
            >
                {items
                    ? items.map((el, index) => (
                          <ReviewCard
                              aiHighlight={aiHighlight}
                              accentColor={accentColor}
                              key={index}
                              item={el}
                              isProPlan={isProPlan}
                              isWidget={isWidget}
                          />
                      ))
                    : [
                          <ReviewCard key="0" />,
                          <ReviewCard key="1" />,
                          <ReviewCard key="2" />,
                          <ReviewCard key="3" />,
                          <ReviewCard key="4" />,
                          <ReviewCard key="5" />,
                          <ReviewCard key="6" />,
                      ]}
            </Carousel>
        </Root>
    );
};

const responsive = {
    xxLarge: {
        breakpoint: {
            max: 100000,
            min: 2200,
        },
        items: 6,
        partialVisibilityGutter,
    },
    xLarge: {
        breakpoint: {
            max: 2200,
            min: 1900,
        },
        items: 5,
        partialVisibilityGutter,
    },
    large: {
        breakpoint: {
            max: 1900,
            min: 1400,
        },
        items: 4,
        partialVisibilityGutter,
    },
    desktop: {
        breakpoint: {
            max: 1400,
            min: 1024,
        },
        items: 3,
        partialVisibilityGutter,
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 700,
        },
        items: 2,
        partialVisibilityGutter: 60,
    },
    mobile: {
        breakpoint: {
            max: 700,
            min: 0,
        },
        items: 1,
        partialVisibilityGutter: 40,
    },
};

const ButtonGroup = ({ next, previous }: { next?: () => void; previous?: () => void }) => {
    return (
        <>
            <ChevronButton onClick={previous}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M12.5 5L7.5 10L12.5 15" stroke="white" strokeWidth="1.5" />
                </svg>
            </ChevronButton>
            <ChevronButton onClick={next} $right>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M12.5 5L7.5 10L12.5 15" stroke="white" strokeWidth="1.5" />
                </svg>
            </ChevronButton>
        </>
    );
};

export default ReviewsCarouselComponent;
