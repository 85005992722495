import Stack from '@mui/material/Stack/Stack';
import React, { useMemo, useState } from 'react';
import { SectionContentWrapper, SectionDashRoot, SectionTitle } from '../../components/common.styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FreePlanCard } from './free-plan-card.component';
import { ProPlanCard } from './pro-plan-card.component';
import { EnterprisePlanCard } from './enterprise-plan-card.component';
import { PlanType } from '../../types/plan.type';
import axios from 'axios';
import { MONTHLY_PRO_PRICE_ID, SERVER_URL, YEARLY_PRO_PRICE_ID } from '../../constants';
import { useTheme } from 'styled-components';
import { BillingPeriodSwitcher } from './billing-period-switcher';
import { BillingCycleType } from '../../types/billing-cycle.type';
import { PlanCancellationModal } from './plan-cancellation-modal.component';
import { formatDate } from '../../utils/formatDate';

const freePlanTitle = 'Free Plan';
const proPlanTitle = 'PRO Plan';
const freePlanPrice = '$0 per month';
const proPlanMonthlyPrice = '$49 per month';
const proPlanYearlyPrice = '$39 per month';

export const AccountSection = ({
    plan,
    stripeCustomerId,
    cancellationDate,
    subscriptionInterval,
    onCancelled,
}: {
    plan?: PlanType;
    stripeCustomerId?: string;
    cancellationDate?: string;
    subscriptionInterval?: string;
    onCancelled: () => void;
}) => {
    const [loading, setLoading] = useState(false);
    const [billingCycle, setBillingCycle] = useState<BillingCycleType>((subscriptionInterval as BillingCycleType) || 'year');
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
    const isProPlan = plan === 'pro';
    const { colors } = useTheme();

    const onCheckout = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('access_token');
            const { data } = await axios.post(
                `${SERVER_URL}/payment/create-checkout-session`,
                { priceId: billingCycle === 'year' ? YEARLY_PRO_PRICE_ID : MONTHLY_PRO_PRICE_ID },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            window.open(data, '_self');
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };
    const onDowngrade = async () => {
        setCancelConfirmModal(true);
    };

    const openCustomerPortal = async () => {
        const token = localStorage.getItem('access_token');
        const { data } = await axios.post(
            `${SERVER_URL}/payment/customer-portal`,
            {},
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        window.open(data, '_blank');
    };

    const formattedDate = useMemo(() => cancellationDate && formatDate(cancellationDate), [cancellationDate]);

    return (
        <SectionDashRoot>
            <SectionContentWrapper $fullHeight>
                <Stack gap={3} height="100%">
                    <SectionTitle>Billing</SectionTitle>
                    <Typography sx={{ mt: '10px', fontSize: '12px', fontWeight: 600, color: 'grey.main' }}>
                        CURRENT PLAN
                    </Typography>
                    <Stack
                        direction="row"
                        alignItems="flex-end"
                        justifyContent="space-between"
                        sx={{ pb: 2, borderBottom: '1px solid', borderColor: 'grey.light' }}
                    >
                        <Stack>
                            <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
                                {isProPlan ? proPlanTitle : freePlanTitle}
                            </Typography>
                            <Typography sx={{ fontSize: '24px', fontWeight: 500, color: isProPlan ? '#000' : 'grey.main' }}>
                                {isProPlan ? (billingCycle === 'year' ? proPlanYearlyPrice : proPlanMonthlyPrice) : freePlanPrice}
                            </Typography>
                            {cancellationDate && (
                                <Typography fontSize="12px" fontWeight="500" sx={{ mt: '12px', color: colors.error }}>
                                    Your plan will be canceled on {formattedDate}.
                                </Typography>
                            )}
                        </Stack>
                        <Stack direction="row" gap={2}>
                            {stripeCustomerId && (
                                <Button size="small" variant="contained" sx={{ height: '40px' }} onClick={openCustomerPortal}>
                                    Open Billing Portal
                                </Button>
                            )}
                            {isProPlan && (
                                <Button size="small" variant="outlined" sx={{ height: '40px' }} onClick={onDowngrade}>
                                    Cancel Plan
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                    <Stack gap={3} overflow="scroll">
                        <BillingPeriodSwitcher value={billingCycle} onChange={setBillingCycle} />
                        <Stack direction="row" gap={2} width="100%" maxWidth="1112px" sx={{ mx: 'auto' }}>
                            <FreePlanCard active={!isProPlan} loading={loading} onClick={onDowngrade} />
                            <ProPlanCard active={isProPlan} onClick={onCheckout} loading={loading} billingCycle={billingCycle} />
                            <EnterprisePlanCard />
                        </Stack>
                    </Stack>
                </Stack>
            </SectionContentWrapper>
            <PlanCancellationModal
                open={cancelConfirmModal}
                onClose={() => {
                    setCancelConfirmModal(false);
                }}
                onCancelled={onCancelled}
            />
        </SectionDashRoot>
    );
};
