import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { ReactComponent as SortIcon } from '../../assets/sort.svg';
import styled, { useTheme } from 'styled-components';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { SortOption } from '../../types/sort.enum';

export const SortSelector = ({ value, onChange }: { value: SortOption; onChange: (value: SortOption) => void }) => {
    const [open, setOpen] = useState(false);
    const {
        colors: { primary },
    } = useTheme();
    const toggleOpen = () => setOpen(!open);
    return (
        <Root>
            <ClickAwayListener
                onClickAway={() => {
                    if (open) {
                        setOpen(false);
                    }
                }}
            >
                <div>
                    <Button
                        variant="outlined"
                        sx={{
                            borderColor: open && `${primary} !important;`,
                            textTransform: 'capitalize',
                        }}
                        startIcon={<SortIcon />}
                        onClick={toggleOpen}
                    >
                        {value}
                    </Button>
                    <Popup open={open}>
                        <SortButton
                            label="Newest"
                            active={value === SortOption.newest}
                            onClick={() => {
                                onChange(SortOption.newest);
                                setOpen(false);
                            }}
                        />
                        <SortButton
                            label="Oldest"
                            active={value === SortOption.oldest}
                            onClick={() => {
                                onChange(SortOption.oldest);
                                setOpen(false);
                            }}
                        />
                        <SortButton
                            label="Negative first"
                            active={value === SortOption.negative}
                            onClick={() => {
                                onChange(SortOption.negative);
                                setOpen(false);
                            }}
                        />
                        <SortButton
                            label="Positive first"
                            active={value === SortOption.positive}
                            onClick={() => {
                                onChange(SortOption.positive);
                                setOpen(false);
                            }}
                        />
                    </Popup>
                </div>
            </ClickAwayListener>
        </Root>
    );
};

const SortButton = ({ active, label, onClick }: { active?: boolean; label: string; onClick: () => void }) => (
    <Button
        variant="text"
        color="info"
        fullWidth
        onClick={onClick}
        sx={{
            background: active && '#F7F8FA',
            justifyContent: 'flex-start',
            '&:hover': {
                color: '#000 !important',
            },
            borderRadius: '8px',
        }}
    >
        {label}
    </Button>
);

const Root = styled.div`
    position: relative;
`;

const Popup = styled.div<{ open?: boolean }>`
    position: absolute;
    top: 50px;
    left: 0;
    width: 220px;
    background: ${({ theme }) => theme.colors.bg};
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 1;
    transition: opacity 0.1s linear;
    box-shadow: 0px 45px 12px 0px rgba(0, 0, 0, 0), 0px 29px 11px 0px rgba(0, 0, 0, 0), 0px 16px 10px 0px rgba(0, 0, 0, 0.01),
        0px 7px 7px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);

    ${({ open }) => (open ? `opacity: 1; pointer-events: auto;` : 'opacity: 0; pointer-events: none;')};
`;
