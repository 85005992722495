import styled from 'styled-components';

export const SwitchWrapper = styled.label<{ checked?: boolean; $disabled?: boolean }>`
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
    background-color: ${({ theme, checked }) =>
        checked ? theme.colors.controlActiveBackground : theme.colors.controlBackground};
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.1s linear;
    ${({ $disabled }) => $disabled && 'opacity: 0.6'}
`;

export const Slider = styled.span`
    position: absolute;
    left: 2px;
    bottom: 2px;
    transition: 0.4s;
    height: 20px;
    width: 20px;
    border-radius: 50%;
`;

export const InactiveState = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.inactiveControlColor};
    transition: all 0.25s;
    border-radius: 50%;
`;

export const ActiveState = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.activeControlColor};
    transition: all 0.25s;
    opacity: 0;
    border-radius: 50%;
    overflow: hidden;
`;

export const Checkbox = styled.input`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${Slider} ${InactiveState} {
        transform: translateX(20px);
        opacity: 0;
    }

    &:checked + ${Slider} ${ActiveState} {
        transform: translateX(20px);
        opacity: 1;
    }
`;
