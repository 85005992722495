import React, { useState } from 'react';
import { ReactComponent as MoreIcon } from '../../assets/more.svg';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';
import styled from 'styled-components';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export const TableActions = ({ onDelete }: { onDelete: () => void }) => {
    const [actionsShows, setActionsShown] = useState(false);
    return (
        <Root>
            <Wrapper onClick={() => setActionsShown(!actionsShows)}>
                <MoreIcon />
            </Wrapper>
            {actionsShows && (
                <ClickAwayListener
                    onClickAway={() => {
                        if (actionsShows) {
                            setActionsShown(false);
                        }
                    }}
                >
                    <ActionContainer onClick={onDelete}>
                        Delete <TrashIcon />
                    </ActionContainer>
                </ClickAwayListener>
            )}
        </Root>
    );
};

const Root = styled.div`
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
`;

const Wrapper = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 999px;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.colors.light};
        path {
            fill: ${({ theme }) => theme.colors.text};
        }
    }
`;

const ActionContainer = styled.div`
    position: absolute;
    right: 0;
    bottom: -55px;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.bg};
    box-shadow: 0px 45px 12px 0px rgba(0, 0, 0, 0), 0px 29px 11px 0px rgba(0, 0, 0, 0), 0px 16px 10px 0px rgba(0, 0, 0, 0.01),
        0px 7px 7px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
    padding: 12px 16px;
    color: #cc5029;
    width: 128px;
    display: flex;
    justify-content: space-between;
    transition: background-color 0.1s;
    cursor: pointer;
    z-index: 10;
    path {
        fill: #cc5029;
    }
    &:hover {
        background: ${({ theme }) => theme.colors.light};
    }
`;
