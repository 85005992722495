import React, { useState } from 'react';
import { CodeRoot, Header } from './code-block.styles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ReactComponent as CopyIcon } from '../../assets/copy.svg';

export const CodeBlock = ({ code }: { code: string }) => {
    const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);
    return (
        <CodeRoot>
            <Header>
                <Typography>HTML</Typography>
                <Tooltip placement="top" title="Copied to the clipboard" open={copyTooltipOpen}>
                    <Button
                        variant="text"
                        color={'info'}
                        startIcon={<CopyIcon />}
                        onClick={() => {
                            navigator.clipboard.writeText(code);
                            setCopyTooltipOpen(true);
                            setTimeout(() => {
                                setCopyTooltipOpen(false);
                            }, 2000);
                        }}
                    >
                        Copy Code
                    </Button>
                </Tooltip>
            </Header>
            <Typography whiteSpace="pre-line" variant={'body2'} sx={{ padding: ' 24px 16px' }}>
                {code}
            </Typography>
        </CodeRoot>
    );
};
