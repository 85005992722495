import styled from 'styled-components';

export const PlatformsManage = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    gap: 24px;
`;

export const ProfilePlatformCardRoot = styled.div`
    padding: 7px 8px 7px 16px;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
