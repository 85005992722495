import { getPartialVisibilityGutter, getWidthFromDeviceType } from './elementWidth';
import { CarouselInternalState, CarouselProps } from '../types';

function notEnoughChildren(state: CarouselInternalState): boolean {
    const { slidesToShow, totalItems } = state;
    return totalItems < slidesToShow;
}

function getInitialState(
    state: CarouselInternalState,
    props: CarouselProps
): {
    shouldRenderOnSSR: boolean;
    flexBisis: number | string | undefined;
    domFullyLoaded: boolean;
    partialVisibilityGutter: number | undefined;
    shouldRenderAtAll: boolean;
} {
    const { domLoaded, slidesToShow, containerWidth, itemWidth } = state;
    // old wrongly spelt partialVisbile prop kept to not make changes breaking
    const { deviceType, responsive, ssr, partialVisbile, partialVisible } = props;
    let flexBisis: number | string | undefined;
    const domFullyLoaded = Boolean(domLoaded && slidesToShow && containerWidth && itemWidth);
    if (ssr && deviceType && !domFullyLoaded) {
        flexBisis = getWidthFromDeviceType(deviceType, responsive);
    }
    const shouldRenderOnSSR = Boolean(ssr && deviceType && !domFullyLoaded && flexBisis);
    const partialVisibilityGutter = getPartialVisibilityGutter(
        responsive,
        partialVisbile || partialVisible,
        deviceType,
        state.deviceType
    );
    const shouldRenderAtAll = shouldRenderOnSSR || domFullyLoaded;
    return {
        shouldRenderOnSSR,
        flexBisis,
        domFullyLoaded,
        partialVisibilityGutter,
        shouldRenderAtAll,
    };
}

function getIfSlideIsVisbile(index: number, state: CarouselInternalState): boolean {
    const { currentSlide, slidesToShow } = state;
    return index >= currentSlide && index < currentSlide + slidesToShow;
}

function getTransformForCenterMode(
    state: CarouselInternalState,
    props: CarouselProps,
    transformPlaceHolder?: number,
    partialVisibilityGutter?: number
) {
    const transform = transformPlaceHolder || state.transform;
    if ((!props.infinite && state.currentSlide === 0) || notEnoughChildren(state)) {
        return transform;
    } else {
        if (partialVisibilityGutter) {
            return transform + partialVisibilityGutter;
        }
        return transform + state.itemWidth / 2;
    }
}

function isInLeftEnd({ currentSlide }: CarouselInternalState): boolean {
    return !(currentSlide > 0);
}

function isInRightEnd({ currentSlide, totalItems, slidesToShow }: CarouselInternalState): boolean {
    return !(currentSlide + slidesToShow < totalItems);
}

function getTransformForPartialVsibile(
    state: CarouselInternalState,
    partialVisibilityGutter = 0,
    props: CarouselProps,
    transformPlaceHolder?: number
) {
    const { slidesToShow } = state;
    const isRightEndReach = isInRightEnd(state);
    const shouldRemoveRightGutter = !props.infinite && isRightEndReach;
    const baseTransform = transformPlaceHolder || state.transform;
    if (notEnoughChildren(state)) {
        return baseTransform;
    }
    const transform = baseTransform - (props.centerMode ? partialVisibilityGutter : 0);
    if (shouldRemoveRightGutter) {
        const remainingWidth = state.containerWidth - (state.itemWidth - partialVisibilityGutter) * slidesToShow;
        return transform + remainingWidth;
    }
    return transform;
}

export function parsePosition(props: CarouselProps, position: number) {
    return props.rtl ? -1 * position : position;
}

function getTransform(state: CarouselInternalState, props: CarouselProps, transformPlaceHolder?: number) {
    // old wrongly spelt partialVisbile prop kept to not make changes breaking
    const { partialVisbile, partialVisible, responsive, deviceType, centerMode } = props;
    const transform = transformPlaceHolder || state.transform;
    const partialVisibilityGutter = getPartialVisibilityGutter(
        responsive,
        partialVisbile || partialVisible,
        deviceType,
        state.deviceType
    );
    const currentTransform =
        (partialVisible || partialVisbile) && !centerMode
            ? getTransformForPartialVsibile(state, partialVisibilityGutter, props, transformPlaceHolder)
            : centerMode
            ? getTransformForCenterMode(state, props, transformPlaceHolder, partialVisibilityGutter)
            : transform;
    return parsePosition(props, currentTransform);
}

function getSlidesToSlide(state: CarouselInternalState, props: CarouselProps): number {
    const { domLoaded, slidesToShow, containerWidth, itemWidth } = state;
    const { deviceType, responsive } = props;
    let slidesToScroll = props.slidesToSlide || 1;
    const domFullyLoaded = Boolean(domLoaded && slidesToShow && containerWidth && itemWidth);
    const ssr = props.ssr && props.deviceType && !domFullyLoaded;
    if (ssr) {
        Object.keys(responsive).forEach((device) => {
            const { slidesToSlide } = responsive[device];
            if (deviceType === device && slidesToSlide) {
                slidesToScroll = slidesToSlide;
            }
        });
    }
    if (domFullyLoaded) {
        slidesToScroll = props.slidesToSlide || state.slidesToShow;
    }
    return slidesToScroll;
}

export {
    isInLeftEnd,
    isInRightEnd,
    getInitialState,
    getIfSlideIsVisbile,
    getTransformForCenterMode,
    getTransformForPartialVsibile,
    notEnoughChildren,
    getSlidesToSlide,
    getTransform,
};
