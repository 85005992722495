import Button from '@mui/material/Button/Button';
import styled from 'styled-components';

export const SideButton = styled(Button).attrs({ color: 'secondary', fullWidth: true })<{ $active?: boolean }>`
    justify-content: flex-start;
    gap: 10px;
    padding: 12px 16px;
    border-radius: 16px;
    font-size: 16px;
    color: ${({ $active, theme }) => ($active ? theme.colors.text : theme.colors.inactiveGrey)};
    transition: all 0.25s;
    font-weight: 500;
    background: ${({ $active }) => ($active ? 'rgba(104, 51, 255, 0.08)' : 'transparent')};
    line-height: 24px; /* 150% */
    letter-spacing: -0.64px;

    &:hover {
        background: #fff;
        color: ${({ theme }) => theme.colors.text} !important;

        span {
            transform: translateX(4px);
        }
    }

    span {
        transition: all 0.25s;
    }

    svg {
        width: 20px;
        height: 20px;
    }

    path {
        fill: ${({ $active, theme }) => ($active ? '#6833FF' : theme.colors.inactiveGrey)};
        transition: all 0.25s;
    }

    &:last-child {
        margin-top: auto;
    }
`;
export const SideRoot = styled.div`
    height: auto;
    width: 260px;
    flex-shrink: 0;
    gap: 16px;
    padding: 16px 24px 16px 16px;
    border-right: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.bg};

    display: flex;
    flex-direction: column;

    @media screen and (max-width: 900px) {
    }
`;
