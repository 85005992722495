import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';
import { SERVER_URL } from '../../constants';
import { PaperRoot } from '../common.styles';
import withToast from '../withToast.hoc';
import LoadingButton from '@mui/lab/LoadingButton';
import { UserProfileType } from '../../types/user-data.type';

interface Props {
    email: string;
    onSubmit: () => void;
    showToast: (message: string) => void;
    user: UserProfileType;
}

export const MissingUserDataForm = withToast<Props>(({ email, onSubmit, showToast, user }: Props) => {
    const [firstName, setFirstName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onSubmitClick = async () => {
        try {
            const token = localStorage.getItem('access_token');
            if (token) {
                setIsLoading(true);
                const payload: Record<string, any> = { email };
                if (!user.firstName) {
                    payload.firstName = firstName;
                    user.firstName = firstName;
                }
                await axios.patch(`${SERVER_URL}/user`, payload, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setIsLoading(false);
                onSubmit();
            }
        } catch (e) {
            console.error(e);
            showToast('Error while saving name.');
        }
    };

    return (
        <PaperRoot>
            <Typography variant={'h5'} fontWeight={'bold'} align={'center'}>
                Let us know you better
            </Typography>
            <Divider />
            {!user.firstName && (
                <TextField fullWidth label={'First Name*'} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            )}
            <LoadingButton
                loading={isLoading}
                fullWidth
                // disabled={(!user.firstName && !firstName) || (!user.googleCompanyId && !googleCompanyId)}
                disabled={!user.firstName && !firstName}
                variant="contained"
                size={'large'}
                onClick={onSubmitClick}
            >
                Save
                <SaveIcon sx={{ marginLeft: '8px' }} />
            </LoadingButton>
        </PaperRoot>
    );
});
