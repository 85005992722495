import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { ReactComponent as VisibleIcon } from '../../assets/visible.svg';
import { ReactComponent as HiddenIcon } from '../../assets/hidden.svg';

interface Props {
    value: boolean;
    onClick: (value: boolean) => void;
    styles: Record<string, any>;
    showLabel?: boolean;
}

export const VisibleButton = ({ value, onClick, styles = {}, showLabel }: Props) => {
    const [isShowLoading, setIsShowLoading] = useState(false);

    const toggleClick = async () => {
        setIsShowLoading(true);
        await onClick(!value);
        setIsShowLoading(false);
    };

    return value ? (
        <Tooltip
            placement="top"
            title={
                <Typography color="white" typography="subtitle1" sx={{ fontSize: '11px' }} textAlign="center">
                    Press to hide from widget
                </Typography>
            }
        >
            <span>
                <LoadingButton
                    loading={isShowLoading}
                    variant="outlined"
                    color="info"
                    onClick={toggleClick}
                    sx={{
                        ...styles,
                    }}
                >
                    <VisibleIcon />
                    {showLabel && <>Showing</>}
                </LoadingButton>
            </span>
        </Tooltip>
    ) : (
        <Tooltip
            placement="top"
            title={
                <Typography color="white" typography="subtitle1" sx={{ fontSize: '11px' }} textAlign="center">
                    Press to show in widget
                </Typography>
            }
        >
            <span>
                <LoadingButton
                    loading={isShowLoading}
                    variant="contained"
                    onClick={toggleClick}
                    color="error"
                    sx={{
                        ...styles,
                    }}
                >
                    <HiddenIcon />
                    {showLabel && <>Hidden</>}
                </LoadingButton>
            </span>
        </Tooltip>
    );
};
