import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { MuiTelInput } from 'mui-tel-input';
import { ContactType } from '../../types/contact.type';
import { ModalTitle } from '../../components/common.styles';
import { ImportContactsRoot } from '../requests-section/import-contacts-csv.styles';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import { isValidPhoneNumber } from 'libphonenumber-js';

interface Props {
    open: boolean;
    onClose: () => void;
    onChange: (contacts: ContactType[], channels: number) => Promise<void>;
}
const emptyData = {
    firstName: '',
    lastName: '',
    email: null,
    phone: null,
};
export const AddSingleContact = ({ open, onClose, onChange }: Props) => {
    const [formData, setFormData] = useState({ ...emptyData });
    const [isLoading, setIsLoading] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState(0); // 0 both, 1 email, 2 sms
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    const onCreateContact = async () => {
        setIsLoading(true);
        await onChange([formData], selectedChannel);
        setFormData(emptyData);
        setSelectedChannel(0);
        setIsLoading(false);
        onClose();
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setFormData({ ...formData, email });
        setEmailError(email && !validateEmail(email));
    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, phone: value });
        const isValid = isValidPhoneNumber(value, 'US');
        setPhoneError(value && !isValid);
    };

    const close = () => {
        setFormData({ ...emptyData });
        setSelectedChannel(0);
        setEmailError(false);
        setPhoneError(false);
        onClose();
    };

    return (
        <Dialog open={open} onClose={close} PaperProps={{ sx: { borderRadius: '24px', overflow: 'visible' } }}>
            <ImportContactsRoot>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                    <ModalTitle>Add contact</ModalTitle>
                    <Button size="medium" variant="outlined" color="info" onClick={close}>
                        Close
                    </Button>
                </Stack>
                <Stack gap={2}>
                    <Stack direction="row" spacing={2}>
                        <TextField
                            disabled={isLoading}
                            variant="outlined"
                            fullWidth
                            label={'First Name'}
                            value={formData.firstName}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    firstName: e.target.value,
                                })
                            }
                        />
                        <TextField
                            disabled={isLoading}
                            size="medium"
                            fullWidth
                            label={'Last Name'}
                            value={formData.lastName}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    lastName: e.target.value,
                                })
                            }
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <MuiTelInput
                            error={phoneError}
                            disabled={isLoading}
                            size="medium"
                            fullWidth
                            label={'Phone'}
                            defaultCountry={'US'}
                            value={formData.phone}
                            InputProps={{
                                sx: {
                                    input: {
                                        paddingLeft: '0 !important',
                                    },
                                },
                            }}
                            onChange={handlePhoneChange}
                        />
                        <TextField
                            disabled={isLoading}
                            error={emailError}
                            size="medium"
                            fullWidth
                            type="email"
                            label={'Email'}
                            value={formData.email}
                            onChange={handleEmailChange}
                        />
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <FormGroup
                        onChange={(e) => {
                            const value = parseInt((e.target as HTMLInputElement).value, 10);
                            if (selectedChannel === value) {
                                setSelectedChannel(value === 1 ? 2 : 1);
                            } else if (!selectedChannel) {
                                setSelectedChannel(value === 1 ? 2 : 1);
                            } else {
                                setSelectedChannel(0);
                            }
                        }}
                    >
                        <Stack direction="row" gap={1.5}>
                            <FormControlLabel
                                sx={{ mr: 0 }}
                                control={<Checkbox checked={selectedChannel == 0 || selectedChannel === 1} />}
                                label="Email"
                                name="email"
                                value={1}
                            />
                            <FormControlLabel
                                sx={{ mr: 0 }}
                                control={<Checkbox checked={selectedChannel == 0 || selectedChannel === 2} />}
                                label="SMS"
                                value={2}
                                name="sms"
                            />
                        </Stack>
                    </FormGroup>
                    <LoadingButton
                        disabled={emailError || phoneError || !formData.firstName || !(formData.email || formData.phone)}
                        loading={isLoading}
                        variant="contained"
                        onClick={onCreateContact}
                    >
                        Send request to 1 contact
                    </LoadingButton>
                </Stack>
            </ImportContactsRoot>
        </Dialog>
    );
};
