import styled from 'styled-components';
import Button from '@mui/material/Button';

export const Root = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    flex-wrap: wrap;
`;

export const PlatformButton = styled(Button)<{ $active?: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 16px;
    //width: 87px;
    flex: 1 1 auto;
    height: fit-content;
    gap: 8px;
    position: relative;
    font-size: 14px;
    letter-spacing: -0.56px;
    line-height: 20px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    transition: border 0.1s linear;

    img {
        width: 24px;
    }

    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.4);
    }

    ${({ $active, theme }) =>
        $active &&
        `background-color: rgba(26, 115, 232, 0.04); 1px solid ${theme.colors.activeBorderColor};
        &::after {
        content: '';
        position: absolute;
        pointer-events: none;
        inset: -1px;
        border-radius: 16px;
        padding: 1px;
        background: radial-gradient(ellipse at top, #4a14ff 60%, #9876f5);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
        `}
`;
