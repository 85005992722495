import Dialog from '@mui/material/Dialog/Dialog';
import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';

export const VideoWatchModal = ({ name, videoUrl, onClose }: { name: string; videoUrl?: string; onClose: () => void }) => (
    <Dialog
        open={!!videoUrl}
        onClose={onClose}
        PaperProps={{
            sx: {
                borderRadius: '16px',
                maxWidth: '768px',
                overflow: 'hidden',
                width: '90%',
                position: 'relative',
            },
        }}
    >
        <StyledVideo autoPlay src={videoUrl} controls />
        <HeaderContainer>
            <Typography fontWeight={500} color={'white'} sx={{ textShadow: '0px 1px 8px rgba(0, 0, 0, 0.40)' }}>
                {name}
            </Typography>
            <IconButton
                onClick={onClose}
                sx={{ background: 'rgba(6, 7, 13, 0.20)', backdropFilter: 'blur(40px)', padding: '6px' }}
            >
                <CloseIcon />
            </IconButton>
        </HeaderContainer>
    </Dialog>
);

const StyledVideo = styled.video`
    width: 100%;
    height: auto;
`;

const HeaderContainer = styled.div`
    position: absolute;
    width: calc(100% - 16px);
    left: 8px;
    top: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
