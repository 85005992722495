import styled from 'styled-components';

export const Root = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
`;
export const ModalDescription = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.64px;
    color: ${({ theme }) => theme.colors.textSecondary};
    max-width: 400px;
`;

export const IntegrationSectionWrapper = styled.div`
    width: 100%;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 16px;
    margin-top: auto;
`;
