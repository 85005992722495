import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    ActionButtonsContainer,
    CompanyTitle,
    FormContainer,
    LogoImage,
    ReviewFormContentWrapper,
    ReviewFormDescription,
    ReviewFormMainText,
    ReviewFormStar,
    StarsRow,
    ThankYouContainer,
} from './review-form.styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Confetti from 'react-confetti';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { defaultAccentColor, SERVER_URL } from '../constants';
import { PlatformRedirect } from './platform-redirect.component';
import { ReviewSource } from '../types/review-source.enum';
import { VideoForm } from './video-form.component';
import RecorderService from '../components/video-recording/recorder.service';

const StarFull = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.56 21C17.4001 21.0006 17.2423 20.9629 17.1 20.89L12 18.22L6.9 20.89C6.56214 21.0677 6.1526 21.0375 5.84443 20.8122C5.53626 20.5869 5.38323 20.2058 5.45 19.83L6.45 14.2L2.33 10.2C2.06806 9.9386 1.97189 9.55391 2.08 9.2C2.19825 8.83742 2.51242 8.57366 2.89 8.52L8.59 7.69L11.1 2.56C11.2671 2.21501 11.6167 1.99589 12 1.99589C12.3833 1.99589 12.7329 2.21501 12.9 2.56L15.44 7.68L21.14 8.51C21.5176 8.56366 21.8318 8.82742 21.95 9.19C22.0581 9.54391 21.9619 9.9286 21.7 10.19L17.58 14.19L18.58 19.82C18.6529 20.2028 18.4968 20.5931 18.18 20.82C17.9989 20.9469 17.7809 21.0102 17.56 21V21Z"
            fill="#ffca05"
        />
    </svg>
);

const inputStyles = {
    '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E8E9F2',
            transition: 'border-color 0.1s linear',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#adadb5',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000',
            borderWidth: '1px',
        },
        '&::before': {
            border: 'none',
        },
    },
};

export const ReviewForm = ({
    userId,
    companyName,
    email,
    selectedBoostPlatforms,
    googleCompanyId,
    yelpAlias,
    facebookAlias,
    trustpilotAlias,
    logoUrl,
    showDefaultPlatforms,
    showDefaultFeedbackForm,
    hideBackButton,
    defaultRating = 0,
    contactId,
    analytics,
    reload,
    accentColor,
}: {
    userId?: string;
    companyName?: string;
    email: string;
    selectedBoostPlatforms: ReviewSource[];
    googleCompanyId?: string;
    yelpAlias?: string;
    facebookAlias?: string;
    trustpilotAlias?: string;
    logoUrl?: string;
    showDefaultPlatforms?: boolean;
    showDefaultFeedbackForm?: boolean;
    hideBackButton?: boolean;
    defaultRating?: number;
    contactId?: string;
    analytics?: boolean;
    reload?: number;
    accentColor: string;
}) => {
    const [hoverValue, setHoverValue] = useState(0);
    const [runConfetti, setRunConfetti] = useState(false);
    const [showFeedbackForm, setShowFeedbackForm] = useState(showDefaultFeedbackForm);
    const [showThankYou, setShowThankYou] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        rating: defaultRating,
    });
    const [feedbackButtonLoading, setFeedbackButtonLoading] = useState(false);
    const [showReviewPlatforms, setShowReviewPlatforms] = useState(showDefaultPlatforms);
    const [showVideoForm, setShowVideoForm] = useState(false);
    const isFirstRender = useRef(true);
    const lastSelectedPlatforms = useRef(selectedBoostPlatforms);
    const onStarClick = (value: number) => {
        if (value < 4) {
            setShowFeedbackForm(true);
            setFormData({ ...formData, rating: value });
        } else {
            setRunConfetti(true);
            setShowReviewPlatforms(true);
            setFormData({ ...formData, rating: value });
        }
    };

    const onFeedbackSubmit = async () => {
        setFeedbackButtonLoading(true);
        try {
            await axios.post(`${SERVER_URL}/survey/feedback`, {
                contactId,
                userId,
                ownerEmail: email,
                ...formData,
            });
        } catch (e) {
            console.error(e);
        }
        setShowFeedbackForm(false);
        setShowThankYou(true);
        setFeedbackButtonLoading(false);
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        if (lastSelectedPlatforms.current.length !== selectedBoostPlatforms.length) {
            lastSelectedPlatforms.current = selectedBoostPlatforms;
            setShowReviewPlatforms(true);
        }
    }, [selectedBoostPlatforms]);

    useEffect(() => {
        if (reload) {
            setShowFeedbackForm(false);
            setShowThankYou(false);
            setShowReviewPlatforms(false);
        }
    }, [reload]);

    const logo = useMemo(
        () => (logoUrl ? <LogoImage src={logoUrl} /> : companyName && <CompanyTitle>{companyName}</CompanyTitle>),
        [logoUrl]
    );
    return (
        <>
            <Confetti
                style={{ zIndex: '20' }}
                run={runConfetti}
                recycle={false}
                numberOfPieces={400}
                onConfettiComplete={(confetti) => {
                    setRunConfetti(false);
                    confetti.reset();
                }}
            />

            <ReviewFormContentWrapper $hideLogo={showFeedbackForm} $smallPadding={showReviewPlatforms || showVideoForm}>
                {!showVideoForm && logo}
                {!showFeedbackForm && !showThankYou && !showVideoForm && !showReviewPlatforms && (
                    <>
                        <div>
                            <ReviewFormMainText $noWrap>Please review your experience with us.</ReviewFormMainText>
                            <ReviewFormDescription>Your feedback helps us become better.</ReviewFormDescription>
                        </div>
                        <StarsRow>
                            <StarButton value={1} currentValue={hoverValue} onHoverChange={setHoverValue} onClick={onStarClick} />
                            <StarButton value={2} currentValue={hoverValue} onHoverChange={setHoverValue} onClick={onStarClick} />
                            <StarButton value={3} currentValue={hoverValue} onHoverChange={setHoverValue} onClick={onStarClick} />
                            <StarButton value={4} currentValue={hoverValue} onHoverChange={setHoverValue} onClick={onStarClick} />
                            <StarButton value={5} currentValue={hoverValue} onHoverChange={setHoverValue} onClick={onStarClick} />
                        </StarsRow>
                        <Typography variant="body2">Thank you for your review.</Typography>
                    </>
                )}
                {showFeedbackForm && (
                    <FormContainer>
                        <ReviewFormMainText>
                            We would love to hear from you.
                            <br /> Please leave your feedback below.
                        </ReviewFormMainText>
                        <TextField
                            size="small"
                            fullWidth
                            label={'Name'}
                            required
                            variant="outlined"
                            value={formData.name}
                            disabled={feedbackButtonLoading}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    name: e.target.value,
                                })
                            }
                            sx={{
                                mt: 3,
                                ...inputStyles,
                            }}
                        />
                        <TextField
                            size="small"
                            fullWidth
                            label={'Email'}
                            variant="outlined"
                            value={formData.email}
                            disabled={feedbackButtonLoading}
                            sx={inputStyles}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    email: e.target.value,
                                })
                            }
                        />
                        <TextField
                            size="small"
                            fullWidth
                            label={'Phone'}
                            variant="outlined"
                            color="info"
                            value={formData.phone}
                            disabled={feedbackButtonLoading}
                            sx={inputStyles}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    phone: e.target.value,
                                })
                            }
                        />
                        <TextField
                            size="small"
                            multiline
                            rows={4}
                            fullWidth
                            required
                            label={'Message'}
                            variant="outlined"
                            disabled={feedbackButtonLoading}
                            value={formData.message}
                            sx={inputStyles}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    message: e.target.value,
                                })
                            }
                        />
                        <ActionButtonsContainer>
                            {!hideBackButton && (
                                <Button
                                    id="form-back"
                                    size="large"
                                    variant="text"
                                    onClick={() => {
                                        setShowFeedbackForm(false);
                                        setShowReviewPlatforms(false);
                                    }}
                                >
                                    Back
                                </Button>
                            )}
                            <LoadingButton
                                id="form-submit"
                                loading={feedbackButtonLoading}
                                variant="text"
                                disabled={!formData.name || !formData.message}
                                onClick={onFeedbackSubmit}
                            >
                                Send Feedback
                            </LoadingButton>
                        </ActionButtonsContainer>
                    </FormContainer>
                )}
                {showThankYou && (
                    <ThankYouContainer>
                        <ReviewFormMainText>Thank you for your feedback!</ReviewFormMainText>
                        <ReviewFormDescription>We appreciate the time you spent.</ReviewFormDescription>
                    </ThankYouContainer>
                )}
                {showReviewPlatforms && (
                    <>
                        <PlatformRedirect
                            userId={userId}
                            selectedBoostPlatforms={selectedBoostPlatforms}
                            googleCompanyId={googleCompanyId}
                            yelpAlias={yelpAlias}
                            facebookAlias={facebookAlias}
                            trustpilotAlias={trustpilotAlias}
                            contactId={contactId}
                            analytics={analytics}
                            accentColor={accentColor}
                            onVideoClick={() => {
                                setShowReviewPlatforms(false);
                                setShowVideoForm(true);
                            }}
                        />
                    </>
                )}
                {showVideoForm && (
                    <VideoForm
                        accentColor={accentColor || defaultAccentColor}
                        rating={formData.rating}
                        userId={userId}
                        onBack={() => {
                            RecorderService.stopStream();
                            setShowVideoForm(false);
                            setShowReviewPlatforms(true);
                        }}
                    />
                )}
            </ReviewFormContentWrapper>
        </>
    );
};

const StarButton = ({
    onHoverChange,
    value,
    currentValue,
    onClick,
}: {
    onHoverChange: (value: number) => void;
    value: number;
    currentValue: number;
    onClick: (value: number) => void;
}) => (
    <ReviewFormStar
        $active={currentValue >= value}
        onMouseLeave={() => onHoverChange(0)}
        onMouseEnter={() => onHoverChange(value)}
        onClick={() => onClick(value)}
    >
        <StarFull />
    </ReviewFormStar>
);
