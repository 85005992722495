import React, { ReactElement } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { SourceRow } from '../reviews-section/reviews-filter.styles';
import { ReviewSource } from '../../types/review-source.enum';
import { BoostFormControlLabel } from './collect-section.styles';

interface Props {
    checked: boolean;
    disabled?: boolean;
    logo?: ReactElement;
    name: string;
    label?: string | ReactElement;
}

export const FormPlatformCheckboxComponent = ({ name, checked, disabled, logo, label }: Props) => {
    return (
        <BoostFormControlLabel
            control={<Checkbox checked={checked} disabled={disabled} />}
            label={
                <SourceRow>
                    {logo}
                    {label}
                </SourceRow>
            }
            name={name}
        />
    );
};
