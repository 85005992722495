import Stack from '@mui/material/Stack/Stack';
import React, { useState } from 'react';
import { Root } from './video-form.styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { VideoRecording } from './video-recording.component';
import { VideoPreview } from './video-preview.component';
import { VideoUploading } from './video-uploading.component';
import axios from 'axios';
import { SERVER_URL } from '../constants';
import { getRecorderFileExtension } from '../utils/deviceInfo';

interface Props {
    onBack: () => void;
    accentColor: string;
    rating: number;
    userId: string;
}

export const VideoForm = ({ onBack, accentColor, rating, userId }: Props) => {
    const [recordedFile, setRecordedFile] = useState<Blob>();
    const [isUploadingVideo, setIsUploadingVideo] = useState(false);
    const [uploadingProgress, setUploadingProgress] = useState(0);

    const sendFileToServer = async (name: string) => {
        const fileOptions = getRecorderFileExtension();
        const file = new File([recordedFile], `${userId}-${name.split(' ').join('_')}-${Date.now()}.${fileOptions.extension}`, {
            type: fileOptions.type,
        });
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        formData.append('rating', rating.toString());
        formData.append('userId', userId);
        await axios.post(`${SERVER_URL}/reviews/video`, formData, {
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadingProgress(progress);
            },
        });
    };

    return (
        <Root>
            {!isUploadingVideo ? (
                <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>
                            {recordedFile ? <>Send a video review</> : <>Record a video review</>}
                        </Typography>
                        {!recordedFile && (
                            <Button size="medium" variant="outlined" color="info" onClick={onBack}>
                                Back
                            </Button>
                        )}
                    </Stack>
                    {!recordedFile && (
                        <Typography sx={{ fontWeight: '500' }}>Tell us about the service and your impressions.</Typography>
                    )}
                    {!recordedFile ? (
                        <VideoRecording accentColor={accentColor} onRecorded={setRecordedFile} />
                    ) : (
                        <VideoPreview
                            accentColor={accentColor}
                            recordedFile={recordedFile}
                            onDelete={() => {
                                setRecordedFile(null);
                            }}
                            onUpload={(name) => {
                                setIsUploadingVideo(true);
                                sendFileToServer(name);
                            }}
                        />
                    )}
                </>
            ) : (
                <VideoUploading
                    accentColor={accentColor}
                    value={uploadingProgress}
                    onClose={() => {
                        setRecordedFile(null);
                        setIsUploadingVideo(false);
                        onBack();
                    }}
                />
            )}
        </Root>
    );
};
