import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoText } from '../../assets/logo-text.svg';

export const Logo = () => {
    return (
        <Root>
            <LogoText />
        </Root>
    );
};

const Root = styled.div`
    color: black;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 22px */
    letter-spacing: -0.44px;
    margin-bottom: 64px;
    padding: 10px;
`;
