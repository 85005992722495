import React from 'react';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack/Stack';

export const VideoUploading = ({ accentColor, value, onClose }: { accentColor: string; value: number; onClose: () => void }) => {
    if (value === 100) {
        return (
            <Stack sx={{ width: '350px', '& path': { fill: accentColor } }} gap={2} alignItems="center">
                {DoneIcon}
                <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>Video sent successfully</Typography>
                <Button
                    variant="contained"
                    color="info"
                    onClick={onClose}
                    sx={{
                        mt: 1,
                        py: '11px',
                        color: accentColor,
                        backgroundColor: `${accentColor}1F`,
                        transition: 'all 0.1s',
                        '&:hover': { backgroundColor: `${accentColor}1F`, opacity: 0.7 },
                    }}
                >
                    Review us on platforms
                </Button>
            </Stack>
        );
    }
    return (
        <Stack sx={{ width: '430px', pb: 5, maxWidth: '100%' }} gap={5}>
            <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>Video is uploading</Typography>
            <Stack gap={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Do not close the page</Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: '500', color: accentColor }}>{value}%</Typography>
                </Stack>
                <LinearProgress
                    variant="determinate"
                    value={value}
                    sx={{
                        borderRadius: '999px',
                        background: '#F7F8FA',
                        '& .MuiLinearProgress-bar': { background: accentColor, borderRadius: '999px' },
                    }}
                />
            </Stack>
        </Stack>
    );
};

const DoneIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.3919 10.941C27.7924 11.3416 27.7924 11.991 27.3919 12.3915L20.7252 19.0582C20.3247 19.4587 19.6753 19.4587 19.2748 19.0582C18.8742 18.6576 18.8742 18.0082 19.2748 17.6077L25.9414 10.941C26.342 10.5405 26.9914 10.5405 27.3919 10.941ZM37.3919 10.941C37.7924 11.3416 37.7924 11.991 37.3919 12.3915L21.4324 28.351C20.6412 29.1422 19.3588 29.1422 18.5676 28.351L15 24.7834L11.4325 28.3509C11.4324 28.3509 11.4324 28.351 11.4323 28.351C10.6413 29.1422 9.35872 29.1422 8.56767 28.351C8.56763 28.351 8.56758 28.3509 8.56753 28.3509L1.77477 21.5582C1.37423 21.1576 1.37423 20.5082 1.77477 20.1077C2.1753 19.7072 2.8247 19.7072 3.22524 20.1077L10 26.8824L13.5495 23.3329L10.9414 20.7248C10.5409 20.3243 10.5409 19.6749 10.9414 19.2744C11.342 18.8738 11.9914 18.8738 12.3919 19.2744L15.7244 22.6068C15.7247 22.6071 15.725 22.6074 15.7253 22.6077C15.7255 22.608 15.7258 22.6083 15.7261 22.6085L20 26.8825L35.9414 10.941C36.342 10.5405 36.9914 10.5405 37.3919 10.941Z"
            fill="#6833FF"
        />
    </svg>
);
