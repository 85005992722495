import { UserPopulatedType } from '../types/user-data.type';
import { ReviewSource } from '../types/review-source.enum';

export const getAvailableUserPlatforms = (userPopulatedData: UserPopulatedType): ReviewSource[] => {
    const result = [];
    if (userPopulatedData.user.googleCompanyId) {
        result.push(ReviewSource.google);
    }
    if (userPopulatedData.user.yelpAlias) {
        result.push(ReviewSource.yelp);
    }
    if (userPopulatedData.user.facebookAlias) {
        result.push(ReviewSource.facebook);
    }
    if (userPopulatedData.user.trustpilotAlias) {
        result.push(ReviewSource.trustpilot);
    }
    return result;
};
