import styled from 'styled-components';
export const Root = styled.div`
    path {
        fill: ${({ theme }) => theme.colors.controlBackground};
        transition: fill 0.3s ease;
    }
    svg {
        cursor: pointer;
        transition: transform 0.3s ease;
        width: 20px;
        height: 20px;
    }
    svg.active {
        path {
            fill: #ffca05; /* color when hovered */
        }
    }
    &:hover {
        path {
            fill: ${({ theme }) => theme.colors.controlBackground} !important;
        }
        svg:hover,
        svg:hover ~ svg {
            path {
                fill: #ffca05 !important;
            }
        }
    }

    svg:hover {
        transform: scale(1.1);
    }
`;
