import styled from 'styled-components';

export const FilterWrapperContainer = styled.div`
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};

    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px; /* 150% */
        letter-spacing: -0.64px;
    }
`;

export const SettingWrapper = styled.div`
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    flex: 1 1 50%;
`;
