import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { ContentWrapper } from '../instruction-dialog.styles';

export const WordpressArticle = () => (
    <>
        <DialogTitle textAlign="left" align="center" sx={{ p: 0, mt: 3, fontSize: '18px' }}>
            How to add widget to WordPress website
        </DialogTitle>
        <ContentWrapper>
            <Typography>
                Log in to your WordPress website's admin (/wp-admin), click on <strong>Pages</strong>:
            </Typography>
            <img src="/instructions/wordpress-1.png" alt="" />
            <Typography>
                Choose the page you want to add the widget to and click on <strong>Edit</strong>:
            </Typography>
            <img src="/instructions/wordpress-2.png" alt="" />
            <Typography>
                In the editor, find where you'd like to show the widget and click on <strong>Add Block</strong>:
            </Typography>
            <img src="/instructions/wordpress-3.png" alt="" />
            <Typography>
                Then find and click <strong>Custom HTML</strong>:
            </Typography>
            <img src="/instructions/wordpress-4.png" alt="" />
            <Typography>
                Paste your installation code in the popup window and click <strong>Save</strong> to save changes:
            </Typography>
            <img src="/instructions/wordpress-5.png" alt="" />
            <Typography>You have successfully added the widget to a page of your WordPress website!</Typography>
        </ContentWrapper>
    </>
);
