import styled from 'styled-components';

const commonStyles = `
    padding: 10px 16px;
    border-radius: 999px;
     display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
`;

export const LoadingContainer = styled.div<{ $size?: 'sm' | 'md'; $textDark?: boolean }>`
    ${commonStyles};
    padding: ${({ $size }) => ($size === 'sm' ? '5px 12px' : '10px 16px')};
    background: ${({ theme }) => theme.colors.light};
    color: ${({ theme, $textDark }) => ($textDark ? theme.colors.text : theme.colors.inactiveGrey)};
    circle {
        stroke: ${({ theme }) => theme.colors.inactiveGrey};
    }

    ${({ $size }) => $size && `svg {width: 16px; height:16px;}`}
    path {
        fill: ${({ theme }) => theme.colors.iconLight};
    }
    circle {
        stroke: ${({ theme }) => theme.colors.iconLight};
    }
`;

export const WarningContainer = styled.div`
    ${commonStyles};
    background: rgba(204, 119, 41, 0.12);
    color: #cc7729;
`;

export const SuccessContainer = styled.div<{ $size?: 'sm' | 'md' }>`
    ${commonStyles};
    padding: ${({ $size }) => ($size === 'sm' ? '5px 12px' : '10px 16px')};
    background: rgba(69, 186, 59, 0.12);
    color: #328c2a;
    ${({ $size }) => $size && `svg {width: 16px; height:16px;}`}
`;

export const ErrorContainer = styled.div<{ $size?: 'sm' | 'md' }>`
    ${commonStyles};
    padding: ${({ $size }) => ($size === 'sm' ? '5px 12px' : '10px 16px')};
    background: rgba(204, 80, 41, 0.12);
    color: ${({ theme }) => theme.colors.error};
    path {
        fill: ${({ theme }) => theme.colors.error};
    }

    ${({ $size }) => $size && `svg {width: 16px; height:16px;}`}
`;
