import React, { useMemo } from 'react';
import { ReviewType } from '../../types/review.type';
import { CarouselRow, ReviewsWallContainer, Root } from './reviews-wall.styles';
import { ReviewCard } from '../review-card/review-card.component';

export const ReviewsWallComponent = ({
    items,
    accentColor,
    aiHighlight,
    isProPlan,
    isWidget,
}: {
    items?: ReviewType[];
    accentColor?: string;
    aiHighlight?: boolean;
    isProPlan?: boolean;
    isWidget?: boolean;
}) => {
    const firstLine = useMemo(() => items.slice(0, items.length / 2), [items]);
    const secondLine = useMemo(() => items.slice(items.length / 2, items.length), [items]);

    const duration = `${firstLine.length * 14}s`;
    return (
        <Root>
            <ReviewsWallContainer $duration={duration}>
                <CarouselRow>
                    {firstLine.map((el, index) => (
                        <ReviewCard
                            key={index}
                            item={el}
                            accentColor={accentColor}
                            aiHighlight={aiHighlight}
                            isProPlan={isProPlan}
                            isWidget={isWidget}
                        />
                    ))}
                </CarouselRow>
                <CarouselRow>
                    {firstLine.map((el, index) => (
                        <ReviewCard
                            key={index}
                            item={el}
                            accentColor={accentColor}
                            aiHighlight={aiHighlight}
                            isProPlan={isProPlan}
                            isWidget={isWidget}
                        />
                    ))}
                </CarouselRow>
                {firstLine.length < 2 && (
                    <CarouselRow>
                        {firstLine.map((el, index) => (
                            <ReviewCard
                                key={index}
                                item={el}
                                accentColor={accentColor}
                                aiHighlight={aiHighlight}
                                isProPlan={isProPlan}
                                isWidget={isWidget}
                            />
                        ))}
                    </CarouselRow>
                )}
                {firstLine.length < 3 && (
                    <CarouselRow>
                        {firstLine.map((el, index) => (
                            <ReviewCard
                                key={index}
                                item={el}
                                accentColor={accentColor}
                                aiHighlight={aiHighlight}
                                isProPlan={isProPlan}
                                isWidget={isWidget}
                            />
                        ))}
                    </CarouselRow>
                )}
                {firstLine.length < 4 && (
                    <CarouselRow>
                        {firstLine.map((el, index) => (
                            <ReviewCard
                                key={index}
                                item={el}
                                accentColor={accentColor}
                                aiHighlight={aiHighlight}
                                isProPlan={isProPlan}
                                isWidget={isWidget}
                            />
                        ))}
                    </CarouselRow>
                )}
            </ReviewsWallContainer>

            <ReviewsWallContainer $reverse $duration={duration}>
                <CarouselRow>
                    {secondLine.map((el, index) => (
                        <ReviewCard
                            key={index}
                            item={el}
                            accentColor={accentColor}
                            aiHighlight={aiHighlight}
                            isProPlan={isProPlan}
                            isWidget={isWidget}
                        />
                    ))}
                </CarouselRow>
                <CarouselRow>
                    {secondLine.map((el, index) => (
                        <ReviewCard
                            key={index}
                            item={el}
                            accentColor={accentColor}
                            aiHighlight={aiHighlight}
                            isProPlan={isProPlan}
                            isWidget={isWidget}
                        />
                    ))}
                </CarouselRow>
                {secondLine.length < 2 && (
                    <CarouselRow>
                        {secondLine.map((el, index) => (
                            <ReviewCard
                                key={index}
                                item={el}
                                accentColor={accentColor}
                                aiHighlight={aiHighlight}
                                isProPlan={isProPlan}
                                isWidget={isWidget}
                            />
                        ))}
                    </CarouselRow>
                )}
                {secondLine.length < 3 && (
                    <CarouselRow>
                        {secondLine.map((el, index) => (
                            <ReviewCard
                                key={index}
                                item={el}
                                accentColor={accentColor}
                                aiHighlight={aiHighlight}
                                isProPlan={isProPlan}
                                isWidget={isWidget}
                            />
                        ))}
                    </CarouselRow>
                )}
                {secondLine.length < 4 && (
                    <CarouselRow>
                        {secondLine.map((el, index) => (
                            <ReviewCard
                                key={index}
                                item={el}
                                accentColor={accentColor}
                                aiHighlight={aiHighlight}
                                isProPlan={isProPlan}
                                isWidget={isWidget}
                            />
                        ))}
                    </CarouselRow>
                )}
            </ReviewsWallContainer>
        </Root>
    );
};

export default ReviewsWallComponent;
