import Dialog from '@mui/material/Dialog/Dialog';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as WarningIcon } from '../../assets/warning-2.svg';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack/Stack';
import axios from 'axios';
import { SERVER_URL } from '../../constants';
import LoadingButton from '@mui/lab/LoadingButton';
import { formatDate } from '../../utils/formatDate';

export const PlanCancellationModal = ({
    open,
    onClose,
    onCancelled,
}: {
    open: boolean;
    onClose: () => void;
    onCancelled: () => void;
}) => {
    const [confirmed, setConfirmed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cancellationDate, setCancellationDate] = useState('');

    const onCancelConfirm = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('access_token');
            const { data } = await axios.post(
                `${SERVER_URL}/payment/downgrade`,
                {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            const date = formatDate(data);
            setCancellationDate(date);
            setLoading(false);
            setConfirmed(true);
            onCancelled();
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <Root>
                <WarningIcon />
                {!confirmed ? (
                    <>
                        <Typography fontSize="18px" fontWeight="500" textAlign="center">
                            Are you sure you want to downgrade your subscription?
                        </Typography>
                        <Typography fontSize="14px" sx={{ color: 'grey.main' }} textAlign="center">
                            You will lose access to the review survey form, video reviews, SMS campaigns, images, and more.
                        </Typography>
                        <Stack sx={{ mt: 1 }} width="100%" direction="row" gap={2}>
                            <LoadingButton
                                loading={loading}
                                variant="outlined"
                                color="error"
                                sx={{ width: 'calc(50% - 8px)', color: '#CC5029 !important' }}
                                onClick={onCancelConfirm}
                            >
                                Confirm
                            </LoadingButton>
                            <Button disabled={loading} variant="contained" sx={{ width: 'calc(50% - 8px)' }} onClick={onClose}>
                                Stay
                            </Button>
                        </Stack>
                    </>
                ) : (
                    <>
                        <Typography fontSize="18px" fontWeight="500" textAlign="center">
                            Your Pro subscription will be canceled on <AccentText>{cancellationDate}</AccentText>
                        </Typography>
                        <Button variant="outlined" onClick={onClose}>
                            Continue
                        </Button>
                    </>
                )}
            </Root>
        </Dialog>
    );
};

const Root = styled.div`
    border-radius: 24px;
    width: 400px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

const AccentText = styled.span`
    color: ${({ theme }) => theme.colors.primary};
`;
