import React, { useEffect, useMemo, useState } from 'react';
import { ContactsHeader } from '../collect-section/contacts-header.component';
import { EmailContacts } from '../collect-section/email-contacts.component';
import { ImportContactsCSV } from './import-contacts-csv.modal';
import { ContactType } from '../../types/contact.type';
import axios from 'axios';
import { SERVER_URL } from '../../constants';
import { AddSingleContact } from '../collect-section/add-single-contact.component';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import { SuccessContainer } from '../../components/company-search-status/company-search-status.styles';
import { ReactComponent as SuccessIcon } from '../../assets/success.svg';
import CircularProgress from '@mui/material/CircularProgress';

export const RequestContacts = ({ isProPlan }: { isProPlan?: boolean }) => {
    const [csvModalOpen, setCsvModalOpen] = useState(false);
    const [addSingleContactOpen, setAddSingleContactOpen] = useState(false);
    const [notificationType, setNotificationType] = React.useState('');
    const [contacts, setContacts] = React.useState<ContactType[]>();
    const [searchQuery, setSearchQuery] = useState('');

    const onAddContacts = async (contacts: ContactType[], channel: number) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            const { data } = await axios.post(
                `${SERVER_URL}/contact`,
                { contacts, channel },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setContacts(data);
            setNotificationType('success');
        }
    };

    const onDeleteContact = async (contact: ContactType) => {
        setContacts(contacts.filter((el) => el !== contact));
        const token = localStorage.getItem('access_token');
        if (token) {
            await axios.delete(`${SERVER_URL}/contact/?contactId=${contact._id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
        }
    };

    const fetchEmailContacts = async () => {
        try {
            const token = localStorage.getItem('access_token');
            if (token) {
                const { data } = await axios.get(`${SERVER_URL}/contact?campaignType=reviewRequest`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setContacts(data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (isProPlan) {
            fetchEmailContacts();
        } else {
            setContacts(dummyContacts);
        }
    }, []);

    const filteredContacts = useMemo(() => {
        if (!contacts) {
            return [];
        }
        if (!searchQuery) {
            return contacts;
        }
        return contacts.filter((row) =>
            Object.values(row).some((value) => value.toString().toLowerCase().includes(searchQuery.toLowerCase()))
        );
    }, [searchQuery, contacts]);

    if (!contacts) {
        return <CircularProgress />;
    }

    return (
        <>
            <ContactsHeader
                onImportCsvClick={() => setCsvModalOpen(true)}
                onAddContactClick={() => setAddSingleContactOpen(true)}
                onSearchChange={setSearchQuery}
            />
            <EmailContacts contacts={filteredContacts} onDelete={onDeleteContact} />
            <ImportContactsCSV open={csvModalOpen} onClose={() => setCsvModalOpen(false)} onChange={onAddContacts} />
            <AddSingleContact
                open={addSingleContactOpen}
                onClose={() => setAddSingleContactOpen(false)}
                onChange={onAddContacts}
            />
            <Snackbar
                sx={{ background: 'white', borderRadius: '999px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={notificationType === 'success'}
                autoHideDuration={6000}
                onClose={() => setNotificationType('')}
            >
                <SuccessContainer>
                    <SuccessIcon />
                    Review requests sent successfully
                </SuccessContainer>
            </Snackbar>
        </>
    );
};

const dummyContacts = [
    {
        firstName: 'Alice',
        lastName: 'Smith',
        email: 'alice.smith@example.com',
        phone: '+1-555-123-4567',
        emailStep: 1,
        smsStep: 0,
        lastActivity: Date.now() - Math.floor(Math.random() * 30 * 24 * 60 * 60 * 1000),
        lastActivityType: 'email',
    },
    {
        firstName: 'Bob',
        lastName: 'Johnson',
        email: 'bob.johnson@example.com',
        phone: '+1-555-234-5678',
        emailStep: 0,
        smsStep: 2,
        lastActivity: Date.now() - Math.floor(Math.random() * 30 * 24 * 60 * 60 * 1000),
        lastActivityType: 'sms',
    },
    {
        firstName: 'Carol',
        lastName: 'Williams',
        email: 'carol.williams@example.com',
        phone: '+1-555-345-6789',
        lastActivity: Date.now() - Math.floor(Math.random() * 30 * 24 * 60 * 60 * 1000),
        lastActivityType: 'email',
        surveyOpened: true,
    },
    {
        firstName: 'David',
        lastName: 'Brown',
        email: 'david.brown@example.com',
        phone: '+1-555-456-7890',
        reviewRedirected: true,
        lastActivity: Date.now() - Math.floor(Math.random() * 30 * 24 * 60 * 60 * 1000),
        lastActivityType: 'sms',
    },
    {
        firstName: 'Eve',
        lastName: 'Jones',
        email: 'eve.jones@example.com',
        phone: '+1-555-567-8901',
        feedbackSent: true,
        lastActivity: Date.now() - Math.floor(Math.random() * 30 * 24 * 60 * 60 * 1000),
        lastActivityType: 'email',
    },
    {
        firstName: 'Frank',
        lastName: 'Garcia',
        email: 'frank.garcia@example.com',
        phone: '+1-555-678-9012',
        status: 'positive',
        lastActivity: Date.now() - Math.floor(Math.random() * 30 * 24 * 60 * 60 * 1000),
        lastActivityType: 'sms',
        reviewRedirected: true,
    },
    {
        firstName: 'Grace',
        lastName: 'Martinez',
        email: 'grace.martinez@example.com',
        phone: '+1-555-789-0123',
        reviewRedirected: true,
        lastActivity: Date.now() - Math.floor(Math.random() * 30 * 24 * 60 * 60 * 1000),
        lastActivityType: 'email',
    },
];
