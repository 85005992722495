import React, { useEffect, useRef, useState } from 'react';
import { PaperWrapper } from '../widget-settings/widget-settings.styles';
import { ColorPickerActiveColor, ColorPickerBorder, PickerWrapper } from './accent-color-selector.styles';
import { ChromePicker } from 'react-color';
import { defaultAccentColor } from '../../constants';
import { BodyText } from '../common.styles';

export const AccentColorSelector = ({ onChange, value }: { onChange: (color: string) => void; value: string }) => {
    const [color, setColor] = useState(value || defaultAccentColor);
    const [isPickerVisible, setPickerVisible] = useState(false);
    const pickerRef = useRef(null);

    const handleClickOutside = (event: any) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
            setPickerVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleColorChange = (color: any) => {
        setColor(color.hex);
    };
    return (
        <PaperWrapper $row>
            <BodyText sx={{ marginRight: '9px' }}>Accent Color</BodyText>
            <ColorPickerBorder onClick={() => setPickerVisible(!isPickerVisible)}>
                <label htmlFor="color-picker" style={{ cursor: 'pointer', display: 'inline-block' }}>
                    <ColorPickerActiveColor style={{ background: color }} />
                </label>
            </ColorPickerBorder>
            {isPickerVisible && (
                <PickerWrapper ref={pickerRef}>
                    <ChromePicker
                        color={color}
                        disableAlpha={true}
                        onChange={handleColorChange}
                        onChangeComplete={(colorEvent) => {
                            handleColorChange(colorEvent);
                            onChange(colorEvent.hex);
                        }}
                    />
                </PickerWrapper>
            )}
        </PaperWrapper>
    );
};
