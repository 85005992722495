import * as React from "react";

import {
  CarouselInternalState,
  CarouselProps,
  StateCallBack,
  SkipCallbackOptions
} from "./types";
import {getInitialSlideInInfiniteMode, getOriginalIndexLookupTableByClones} from "./utils/clones";
import { getLookupTableForNextSlides } from "./utils/dots";
import { getSlidesToSlide, notEnoughChildren } from "./utils/common";

interface DotsTypes {
  props: CarouselProps;
  state: CarouselInternalState;
  goToSlide: (index: number, skipCallbacks?: SkipCallbackOptions) => void;
  getState: () => StateCallBack;
}

const MAX_DOTS_COUNT = 11
const Dots = ({
  props,
  state,
  goToSlide,
  getState
}: DotsTypes): React.ReactElement<any> | null => {
  const { showDots, customDot, dotListClass, infinite, children } = props;
  if (!showDots || notEnoughChildren(state)) {
    return null;
  }
  const { currentSlide, slidesToShow } = state;
  const slidesToSlide = getSlidesToSlide(state, props);
  const childrenArr = React.Children.toArray(children);
  let numberOfDotsToShow: number;
  if (!infinite) {
    numberOfDotsToShow =
      Math.ceil((childrenArr.length - slidesToShow) / slidesToSlide!) + 1;
  } else {
    numberOfDotsToShow = Math.ceil(childrenArr.length / slidesToSlide!);
  }
  const nextSlidesTable = getLookupTableForNextSlides(
    numberOfDotsToShow,
    state,
    props,
    childrenArr
  );
  const lookupTable = getOriginalIndexLookupTableByClones(
    slidesToShow,
    childrenArr
  );
  const currentSlides = lookupTable[currentSlide];
  const isMaxExceeded = childrenArr.length > MAX_DOTS_COUNT

  // const childrenArr = React.Children.toArray(props.children);
  const initialSlide = getInitialSlideInInfiniteMode(
      slidesToShow || state.slidesToShow,
      childrenArr
  );
  const threshold = 5
  const endThreshold = 6
  const isMiddleValue = currentSlide > initialSlide + threshold;
  const isEnd = (currentSlide - initialSlide) > (childrenArr.length - endThreshold)
  const endShift = childrenArr.length - (currentSlide - initialSlide) - endThreshold
  return (
    <ul className={`react-multi-carousel-dot-list ${dotListClass}`} style={{justifyContent: isMaxExceeded ? 'unset' : 'center', overflow: isMaxExceeded ? 'hidden': 'visible'}}>
      <div className={'carousel-translate-wrapper'}
           style={{transform: isMiddleValue ? `translateX(-${(currentSlide - initialSlide - threshold + (isEnd ? endShift : 0)) * 18}px)` : ''}}>
      {Array(numberOfDotsToShow)
        .fill(0)
        .map((_, index: number) => {
          let isActive;
          let nextSlide: number;
          if (!infinite) {
            const maximumNextSlide = childrenArr.length - slidesToShow;
            const possibileNextSlides = index * slidesToSlide!;
            const isAboutToOverSlide = possibileNextSlides > maximumNextSlide;
            nextSlide = isAboutToOverSlide
              ? maximumNextSlide
              : possibileNextSlides;
            isActive =
              nextSlide === currentSlide ||
              (currentSlide > nextSlide &&
                currentSlide < nextSlide + slidesToSlide! &&
                currentSlide < childrenArr.length - slidesToShow);
          } else {
            nextSlide = nextSlidesTable[index];
            const cloneIndex = lookupTable[nextSlide];
            isActive =
              currentSlides === cloneIndex ||
              (currentSlides >= cloneIndex &&
                currentSlides < cloneIndex + slidesToSlide!);
          }
          if (customDot) {
            return React.cloneElement(customDot, {
              index,
              active: isActive,
              key: index,
              onClick: () => goToSlide(nextSlide),
              carouselState: getState()
            });
          }
          return (
            <li
              data-index={index}
              key={index}
              className={`react-multi-carousel-dot ${
                isActive ? "react-multi-carousel-dot--active" : ""
              }`}
            >
              <button
                aria-label={`Go to slide ${index + 1}`}
                onClick={() => goToSlide(nextSlide)}
              />
            </li>
          );
        })}
      </div>
    </ul>
  );
};

export default Dots;
