import styled from 'styled-components';

export const Root = styled.div`
    width: 320px;
    flex-shrink: 0;
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const SourceRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;

    svg {
        width: 24px;
        height: 24px;
    }
`;
