import Stack from '@mui/material/Stack/Stack';
import React, { useRef, useState } from 'react';
import { CSVToArray } from '../../utils/csvToArr';
import { csvFileToString } from '../../utils/csvToStr';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { ContactType } from '../../types/contact.type';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { DropAreaContainer, DropDescription, DropInput, DropTitle } from './collect-section.styles';
import { ReactComponent as UploadCloud } from '../../assets/upload-cloud.svg';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import { MappingSelect } from './mapping-select.component';

interface Props {
    onChange: (contacts: ContactType[], channels: number) => Promise<void>;
    onClose: () => void;
}

export const AddContactsCsv = ({ onChange, onClose }: Props) => {
    const [file, setFile] = React.useState(null);
    const [firstNameCol, setFirstNameCol] = React.useState('');
    const [lastNameCol, setLastNameCol] = React.useState('');
    const [phoneCol, setPhoneCol] = React.useState('');
    const [emailCol, setEmailCol] = React.useState('');
    const [availableColumns, setAvailableColumns] = React.useState<string[]>([]);
    const [showError, setShowError] = React.useState(false);
    const [importedData, setImportedData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const dropInput = useRef();
    const [isDragging, setIsDragging] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState(0); // 0 both, 1 email, 2 sms

    const handleChange = async (newFile) => {
        if (newFile) {
            const fileContent = await csvFileToString(newFile);
            const csvArray = CSVToArray(fileContent, ',');
            const header = csvArray[0];
            const data = csvArray.slice(1).map((row) => row.reduce((acc, el, i) => ({ ...acc, [header[i]]: el }), {}));
            setAvailableColumns(header);
            setImportedData(data);
            setFile(newFile);
        } else {
            setImportedData([]);
            setFile(null);
        }
    };

    const onImportTable = async () => {
        if (firstNameCol && (emailCol || phoneCol)) {
            setIsLoading(true);
            const data = importedData.map((row) => ({
                firstName: row[firstNameCol],
                lastName: row[lastNameCol],
                email: row[emailCol] === '' ? undefined : row[emailCol],
                phone: row[phoneCol],
            }));
            await onChange(data, selectedChannel);
            setIsLoading(false);
            onClose();
        } else {
            setShowError(true);
        }
    };

    return (
        <Stack spacing={2} alignItems="center">
            {!importedData.length ? (
                <DropAreaContainer
                    $isDragging={isDragging}
                    onDrop={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsDragging(false);
                        handleChange(e.dataTransfer.files[0]);
                    }}
                    onDragOver={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onDragEnter={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsDragging(true);
                    }}
                    onDragLeave={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsDragging(false);
                    }}
                    onClick={() => {
                        if (dropInput.current) (dropInput.current as HTMLInputElement).click();
                    }}
                >
                    <UploadCloud />
                    <DropTitle>
                        Drag and drop file here or <span>Select File</span>
                    </DropTitle>
                    <DropDescription>File must be in “.csv” format and must not be more tnan 10Mb</DropDescription>
                    <DropInput
                        ref={dropInput}
                        type="file"
                        accept=".csv"
                        onChange={(e) => {
                            // @ts-ignore
                            handleChange(e.target.files[0]);
                        }}
                    />
                </DropAreaContainer>
            ) : (
                <Stack gap={3} sx={{ width: '512px' }}>
                    <Typography variant="body2">Map columns in your file to contact field properties</Typography>
                    <Stack gap={2}>
                        <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                            <MappingSelect
                                error={showError}
                                value={firstNameCol}
                                onChange={setFirstNameCol}
                                disabled={isLoading}
                                columns={availableColumns}
                                rows={importedData}
                                label={'Select First Name'}
                            />
                            <MappingSelect
                                error={showError}
                                value={lastNameCol}
                                onChange={setLastNameCol}
                                disabled={isLoading}
                                columns={availableColumns}
                                rows={importedData}
                                label={'Select Last Name'}
                            />
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                            <MappingSelect
                                error={showError}
                                value={phoneCol}
                                onChange={setPhoneCol}
                                disabled={isLoading}
                                columns={availableColumns}
                                rows={importedData}
                                label={'Select Phone number'}
                            />
                            <MappingSelect
                                error={showError}
                                value={emailCol}
                                onChange={setEmailCol}
                                disabled={isLoading}
                                columns={availableColumns}
                                rows={importedData}
                                label={'Select Email'}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <FormGroup
                            onChange={(e) => {
                                const value = parseInt((e.target as HTMLInputElement).value, 10);
                                if (selectedChannel === value) {
                                    setSelectedChannel(value === 1 ? 2 : 1);
                                } else if (!selectedChannel) {
                                    setSelectedChannel(value === 1 ? 2 : 1);
                                } else {
                                    setSelectedChannel(0);
                                }
                            }}
                        >
                            <Stack direction="row" gap={1.5}>
                                <FormControlLabel
                                    sx={{ mr: 0 }}
                                    control={<Checkbox checked={selectedChannel == 0 || selectedChannel === 1} />}
                                    label="Email"
                                    name="email"
                                    value={1}
                                />
                                <FormControlLabel
                                    sx={{ mr: 0 }}
                                    control={<Checkbox checked={selectedChannel == 0 || selectedChannel === 2} />}
                                    label="SMS"
                                    value={2}
                                    name="sms"
                                />
                            </Stack>
                        </FormGroup>
                        <LoadingButton
                            disabled={!firstNameCol || !(emailCol || phoneCol)}
                            loading={isLoading}
                            variant="contained"
                            onClick={onImportTable}
                        >
                            Send request to {importedData.length} contacts
                        </LoadingButton>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};
