import React from 'react';
import GoogleLogo from '../../assets/google.svg';
import YelpLogo from '../../assets/yelp.svg';
import FacebookLogo from '../../assets/facebook.svg';
import TrustpilotLogo from '../../assets/trustpilot.svg';
import { ButtonsWrapper, PlatformButton, Root } from './platform-selector.styles';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Typography from '@mui/material/Typography';
import { ReviewSource } from '../../types/review-source.enum';
import { themeMuiDark } from '../../theme';

interface Props {
    sources: ReviewSource[];
    onChange: (value: ReviewSource) => void;
    label?: string;
    blur?: boolean;
}

const theme = createTheme({
    typography: {
        fontFamily: ['Montserrat', 'Roboto', 'Noto Sans', 'Arial', 'sans-serif'].join(','),
        button: {
            textTransform: 'none',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    // Add the style for the focused state
                    '&:hover': {
                        borderColor: 'white',
                    },
                    borderRadius: '1em', // Set your desired border radius here
                },
            },
        },
    },
});

export const PlatformSelector = ({ sources, onChange, label, blur }: Props) => {
    return (
        <Root>
            {label && (
                <Typography color="white" typography="h6">
                    {label}
                </Typography>
            )}
            <ButtonsWrapper>
                <ThemeProvider theme={blur ? theme : themeMuiDark}>
                    <PlatformButton
                        $active={sources.includes(ReviewSource.google)}
                        size="large"
                        variant="outlined"
                        onClick={() => {
                            onChange(ReviewSource.google);
                        }}
                    >
                        <img src={GoogleLogo} alt="" />
                    </PlatformButton>
                    <PlatformButton
                        $active={sources.includes(ReviewSource.yelp)}
                        size="large"
                        variant="outlined"
                        onClick={() => {
                            onChange(ReviewSource.yelp);
                        }}
                    >
                        <img src={YelpLogo} alt="" />
                    </PlatformButton>
                    <PlatformButton
                        $active={sources.includes(ReviewSource.facebook)}
                        size="large"
                        variant="outlined"
                        onClick={() => {
                            onChange(ReviewSource.facebook);
                        }}
                    >
                        <img src={FacebookLogo} alt="" />
                    </PlatformButton>
                    <PlatformButton
                        $active={sources.includes(ReviewSource.trustpilot)}
                        size="large"
                        variant="outlined"
                        onClick={() => {
                            onChange(ReviewSource.trustpilot);
                        }}
                    >
                        <img src={TrustpilotLogo} alt="" />
                    </PlatformButton>
                </ThemeProvider>
            </ButtonsWrapper>
        </Root>
    );
};
