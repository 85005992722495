import styled from 'styled-components';

export const Root = styled.div<{ $size: string }>`
    border-radius: ${({ $size }) => ($size === 'small' ? '16px' : '20px')};
    background: ${({ theme }) => theme.colors.cardBackground};
    backdrop-filter: blur(25px);
    border: 1px solid rgba(104, 51, 255, 0.05);
    padding: ${({ $size }) => ($size === 'small' ? '8px' : '10px')};
    width: ${({ $size }) => ($size === 'small' ? 'calc(100% - 36px)' : 'calc(100% + 64px)')};
    display: flex;
    gap: 6.4px;
    box-shadow: 33px 52px 17px 0px rgba(20, 0, 77, 0), 21px 33px 16px 0px rgba(20, 0, 77, 0.01),
        12px 19px 13px 0px rgba(20, 0, 77, 0.03), 5px 8px 10px 0px rgba(20, 0, 77, 0.04), 1px 2px 5px 0px rgba(20, 0, 77, 0.05);

    img {
        width: ${({ $size }) => ($size === 'small' ? '27px' : '33px')};
        height: ${({ $size }) => ($size === 'small' ? '27px' : '33px')};
        border-radius: 5px;
    }
`;

export const Title = styled.div<{ $size: string }>`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ $size }) => ($size === 'small' ? '12px' : '14px')};
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.312px;
`;

export const DateText = styled.div<{ $size: string }>`
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ $size }) => ($size === 'small' ? '10px' : '12px')};
    font-style: normal;
    font-weight: 510;
    line-height: 12.799px;
`;

export const ContentText = styled.div<{ $size: string }>`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ $size }) => ($size === 'small' ? '12px' : '14px')};
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 130.191% */
    letter-spacing: -0.312px;
`;
