import styled from 'styled-components';
import React from 'react';

export const ColorPickerActiveColor = styled.div`
    background: #1ea1f1;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    transition: opacity 0.3s ease;
`;

export const ColorPickerBorder = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 1px solid ${({ theme }) => theme.colors.controlBackground};
    padding: 3px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
        ${ColorPickerActiveColor} {
            opacity: 0.8;
        }
    }
`;

export const InputPicker = styled.input`
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
`;

export const PickerWrapper = styled.div`
    position: absolute;
    top: 40px;
    right: -200px;
    z-index: 2;
    user-select: none;
`;
