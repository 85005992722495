import React, { useMemo } from 'react';
import { ProfilePlatformCardRoot } from './platforms-manage.styles';
import Stack from '@mui/material/Stack';
import { InputIconWrapper } from '../common.styles';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';
import { ReactComponent as GoogleIcon } from '../../assets/google.svg';
import { ReactComponent as YelpIcon } from '../../assets/yelp.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
import { ReactComponent as TrustpilotIcon } from '../../assets/trustpilot.svg';

import { ReviewSource } from '../../types/review-source.enum';

interface Props {
    platform: ReviewSource;
    disabled?: boolean;
    onDelete?: () => Promise<void>;
}

export const ProfilePlatformCard = ({ platform, disabled, onDelete }: Props) => {
    const icon = useMemo(() => {
        if (platform == ReviewSource.google) {
            return <GoogleIcon />;
        }
        if (platform == ReviewSource.yelp) {
            return <YelpIcon />;
        }
        if (platform == ReviewSource.facebook) {
            return <FacebookIcon />;
        }
        if (platform == ReviewSource.trustpilot) {
            return <TrustpilotIcon />;
        }
    }, [platform]);

    return (
        <ProfilePlatformCardRoot>
            <Stack direction="row" alignItems="center" gap={2} sx={{ textTransform: 'capitalize' }}>
                {icon}
                {platform}
            </Stack>
            <InputIconWrapper disabled={disabled} onClick={onDelete}>
                <TrashIcon />
            </InputIconWrapper>
        </ProfilePlatformCardRoot>
    );
};
