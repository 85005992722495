import styled from 'styled-components';

export const CodeRoot = styled.div`
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    box-shadow: none;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.text};
    width: 510px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 4px 6px 4px 16px;
    -webkit-box-align: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.light};
`;
