import React from 'react';
import Avatar1 from '../../assets/avatars/avatar-1.webp';
import Avatar2 from '../../assets/avatars/avatar-2.webp';
import Avatar3 from '../../assets/avatars/avatar-3.webp';
import Avatar4 from '../../assets/avatars/avatar-4.webp';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

export const AvatarsRow = () => {
    return (
        <AvatarGroup max={4}>
            <Avatar src={Avatar1} sx={{ width: '34px', height: '34px' }} />
            <Avatar src={Avatar4} sx={{ width: '34px', height: '34px' }} />
            <Avatar src={Avatar3} sx={{ width: '34px', height: '34px' }} />
            <Avatar src={Avatar2} sx={{ width: '34px', height: '34px' }} />
        </AvatarGroup>
    );
};
