import React, { useEffect, useRef, useState } from 'react';
import { DEBUG_TOKEN, SERVER_URL } from '../../constants';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SettingsType } from '../../types/settings.type';
import { MissingUserDataForm } from '../../components/missing-user-data-form/missing-user-data-form.component';
import { PaperRoot } from '../../components/common.styles';
import { UserPopulatedType } from '../../types/user-data.type';
import LoadingButton from '@mui/lab/LoadingButton';
import withToast from '../../components/withToast.hoc';
import GoogleLogo from '../../assets/google.svg';

interface Props {
    googleCompanyId?: string;
    yelpAlias?: string;
    facebookAlias?: string;
    trustpilotAlias?: string;
    settings: SettingsType;
    onSuccess: (data: UserPopulatedType) => void;
    showToast: (message?: string) => void;
    skipTokenLogin?: boolean;
    isSignUp?: boolean;
}

const searchParams = new URLSearchParams(document.location.search);
const source = searchParams.get('utm_source');
const urlToken = searchParams.get('token');

export const SignupPage = withToast<Props>(
    ({
        googleCompanyId,
        settings,
        onSuccess,
        showToast,
        yelpAlias,
        facebookAlias,
        trustpilotAlias,
        skipTokenLogin,
        isSignUp,
    }: Props) => {
        const [email, setEmail] = useState('');
        const [oneTimePassword, setOneTimePassword] = useState('');
        const [isSubmitted, setIsSubmitted] = useState(false);
        const [showMissingDataForm, setShowMissingDataForm] = useState(false);
        const userPopulatedData = useRef<UserPopulatedType>();
        const [isLoading, setIsLoading] = useState(false);
        const [showResendButton, setShowResendButton] = useState(false);

        const loginWithToken = async () => {
            try {
                const token = urlToken || localStorage.getItem('access_token');

                if (token) {
                    localStorage.setItem('access_token', token);
                    setIsLoading(true);
                    const { data } = await axios.post(
                        `${SERVER_URL}/user/token-signin`,
                        {},
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    );

                    if (data) {
                        const { user } = data;
                        userPopulatedData.current = data;
                        if (!user.firstName) {
                            setEmail(user.email);
                            setShowMissingDataForm(true);
                        } else {
                            onSuccess(data);
                        }
                    }
                }
            } catch (e) {
                localStorage.removeItem('access_token');
                console.error(e);
            }
            setIsLoading(false);
        };

        useEffect(() => {
            if (!skipTokenLogin) {
                loginWithToken();
                setTimeout(() => {
                    setShowResendButton(true);
                }, 20000);
            }
        }, []);

        const onSignInSubmit = async () => {
            try {
                setIsLoading(true);
                await axios.post(`${SERVER_URL}/user/signin`, {
                    email,
                    googleCompanyId,
                    yelpAlias,
                    facebookAlias,
                    trustpilotAlias,
                });
                setIsSubmitted(true);
            } catch (e) {
                showToast("User doesn't exist, try to create widget from the main page.");
                console.error(e);
            }
            setIsLoading(false);
        };

        const onOneTimePasswordSubmit = async () => {
            try {
                setIsLoading(true);
                const { data } = await axios.post(`${SERVER_URL}/user/verify`, {
                    email,
                    oneTimePassword,
                    settings,
                    source,
                });
                setIsLoading(false);

                if (data) {
                    const { user } = data;
                    localStorage.setItem('access_token', data.accessToken);
                    userPopulatedData.current = data;
                    if (
                        !user.firstName ||
                        (!user.googleCompanyId && !user.yelpAlias && !user.facebookAlias && !user.trustpilotAlias)
                    ) {
                        setShowMissingDataForm(true);
                    } else {
                        onSuccess(data);
                    }
                }
            } catch (e) {
                showToast('Wrong one-time password.');
                console.error(e);
                setIsLoading(false);
            }
        };

        const onGoogleLogin = useGoogleLogin({
            onSuccess: async (credentialResponse) => {
                try {
                    setIsLoading(true);
                    const { data } = await axios.post(`${SERVER_URL}/user/google`, {
                        // pass the token as part of the req body
                        token: credentialResponse.access_token,
                        googleCompanyId,
                        yelpAlias,
                        facebookAlias,
                        trustpilotAlias,
                        settings,
                        source,
                    });
                    localStorage.setItem('access_token', data.accessToken);
                    onSuccess(data);
                } catch (e) {
                    showToast("User doesn't exist, try to create widget from the main page.");
                    console.error(e);
                }
                setIsLoading(false);
            },
            onError: (e) => {
                showToast('Google Login failed.');
                console.error(e);
            },
        });

        const onPasswordResend = () => {
            setShowResendButton(false);
            axios.post(`${SERVER_URL}/user/resend`, { email });
            setTimeout(() => {
                setShowResendButton(true);
            }, 30000);
        };

        if (showMissingDataForm) {
            return (
                <MissingUserDataForm
                    user={userPopulatedData.current.user}
                    email={email}
                    onSubmit={() => {
                        onSuccess(userPopulatedData.current);
                    }}
                />
            );
        }

        return (
            <PaperRoot>
                <div>
                    <Typography variant={'h5'} fontWeight={'bold'} color="textPrimary" align={'center'}>
                        Your widget has been created.
                    </Typography>
                    <Typography variant={'h6'} fontWeight={'bold'} color="textPrimary" align={'center'}>
                        {isSignUp ? <>Sign up to embed the widget.</> : <>Sign in to embed the widget.</>}
                    </Typography>
                </div>
                <Divider />
                {!isSubmitted ? (
                    <>
                        <LoadingButton
                            fullWidth
                            variant="outlined"
                            size="large"
                            sx={{ fontSize: '16px', borderColor: '#b0b0b0', py: 1.4 }}
                            color="inherit"
                            loading={isLoading}
                            onClick={() => {
                                onGoogleLogin();
                            }}
                        >
                            <img src={GoogleLogo} style={{ marginRight: '8px' }} /> Google
                        </LoadingButton>
                        <Typography color="textPrimary">or</Typography>
                        <TextField
                            size="medium"
                            fullWidth
                            label={'Email*'}
                            value={email}
                            disabled={isLoading}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <LoadingButton
                            fullWidth
                            loading={isLoading}
                            disabled={!email}
                            variant="contained"
                            size={'large'}
                            onClick={onSignInSubmit}
                        >
                            {isSignUp ? <>Sign Up</> : <>Sign In</>}
                            <EmailIcon sx={{ marginLeft: '8px' }} />
                        </LoadingButton>
                    </>
                ) : (
                    <>
                        <Typography color="textPrimary" align={'center'}>
                            One-time password was sent to your email.
                        </Typography>
                        <TextField
                            fullWidth
                            label={'6-digit one time email password*'}
                            value={oneTimePassword}
                            disabled={isLoading}
                            onChange={(e) => setOneTimePassword(e.target.value)}
                        />
                        <LoadingButton
                            loading={isLoading}
                            fullWidth
                            disabled={!oneTimePassword}
                            variant="contained"
                            size={'large'}
                            sx={{ fontSize: '16px' }}
                            onClick={onOneTimePasswordSubmit}
                        >
                            {isSignUp ? <>Complete Sign Up</> : <>Complete Sign In</>}
                            <CheckCircleIcon sx={{ marginLeft: '8px' }} />
                        </LoadingButton>

                        {showResendButton && !isLoading && (
                            <Typography color="textPrimary" variant="body2" align={'center'} sx={{ mt: 4 }}>
                                Still haven't received it yet? <br /> Check your <strong>spam</strong> folder and press resend
                                email
                                <Button size="small" variant="text" onClick={onPasswordResend}>
                                    Resend Email
                                </Button>
                            </Typography>
                        )}
                    </>
                )}
            </PaperRoot>
        );
    }
);

export default SignupPage;
