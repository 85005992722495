import React from 'react';
import Dialog from '@mui/material/Dialog/Dialog';
import styled from 'styled-components';
import { LockIcon } from '../lock-icon.component';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const PaidFeatureModal = ({
    open,
    onClose,
    onUpgrade,
}: {
    open?: boolean;
    onClose: () => void;
    onUpgrade: () => void;
}) => (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: '24px' } }}>
        <ContentWrapper>
            <LockIcon size={48} />
            <Stack alignItems="center" gap={1}>
                <Typography textAlign="center" sx={{ fontSize: '24px', fontWeight: '500' }}>
                    This is a paid feature
                </Typography>
                <Typography textAlign="center" sx={{ fontSize: '16px', fontWeight: '500', color: 'grey.main' }}>
                    Upgrade to a Pro plan to access these features.
                </Typography>
            </Stack>
            <Button variant="contained" onClick={onUpgrade}>
                Upgrade
            </Button>
        </ContentWrapper>
    </Dialog>
);

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 24px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 24px;
`;
