import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { ContentWrapper } from '../instruction-dialog.styles';

export const WixArticle = () => (
    <>
        <DialogTitle textAlign="left" align="center" sx={{ p: 0, mt: 3, fontSize: '18px' }}>
            How to add widget to Wix website
        </DialogTitle>
        <ContentWrapper>
            <Typography>
                Navigate to your <strong>Wix Dashboard</strong> and select the website to edit by clicking{' '}
                <strong>Edit Site</strong>.
            </Typography>
            <img src="/instructions/wix-1.png" alt="" />
            <Typography>
                Once you're in the editor, click on the plus icon in the toolbar on the left, or any other plus icon on your
                website's preview.
            </Typography>
            <Typography>
                Here you can add content and sections to your site. Click on the <strong>Embed</strong> category in the list and
                locate <strong>Embed Code</strong> in the sub-menu. From therre, select <strong>Embed HTML</strong> to open the
                HTML element in which the embed code can be pasted into.
            </Typography>
            <img src="/instructions/wix-2.png" alt="" />
            <Typography>
                Now that the HTML element is added to your site, click <strong>Enter Code</strong> near the top edge of the
                element, or double click the element. This opens up the element's settings and a text box, into which you can
                paste the embed code you copied before.
            </Typography>
            <Typography>
                Also, <strong>remember to resize the element</strong> to your liking, as Wix doesn't allow the widgets to resize
                themselves.
            </Typography>
            <Typography>
                Next click <strong>Update</strong> to save the element.
            </Typography>
            <img src="/instructions/wix-3.png" alt="" />
            <Typography>
                When you're satisfied with your work, you can preview and publish the page from the top-right.
            </Typography>
            <img src="/instructions/wix-4.png" alt="" />
        </ContentWrapper>
    </>
);
