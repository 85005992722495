import React from 'react';
import Stack from '@mui/material/Stack/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BulletRow, FeaturesContainer, LightPlanContainer, PerText, PlanWrapper, PricingText } from './account-settings.styles';
import { ReactComponent as LogoIcon } from '../../assets/logo.svg';

export const FreePlanCard = ({ active, loading, onClick }: { active?: boolean; loading: boolean; onClick: () => void }) => (
    <PlanWrapper>
        <LightPlanContainer $logoGrey>
            <Stack height="30px" justifyContent="center">
                <LogoIcon />
            </Stack>
            <Stack gap={2}>
                <Typography sx={{ fontSize: '24px', fontWeight: 500 }}>Free Plan</Typography>
                <Typography sx={{ fontSize: '16px', color: 'grey.main', height: '72px' }}>
                    Provides a review widget for your website, enabling you to collect and display customer reviews effortlessly.
                </Typography>
            </Stack>
            <Stack direction="row" alignItems="flex-end" gap={0.5} sx={{ mb: '28px' }}>
                <PricingText $dark>$ 0</PricingText>
                <PerText>/month</PerText>
            </Stack>
            <Button
                disabled={active || loading}
                variant="outlined"
                size="large"
                sx={{ fontSize: '16px', padding: '9px 21px' }}
                onClick={onClick}
            >
                {active ? <>Your current plan</> : <>Choose this plan</>}
            </Button>
        </LightPlanContainer>
        <FeaturesContainer>
            <BulletRow label="1 review platform" />
            <BulletRow label="Unlimited monthly views" />
            <BulletRow label="Customizable website widget with multiple layouts" />
            <BulletRow label="Review filtering" />
            <BulletRow label="Weekly auto-import reviews" />
            <BulletRow label="Unlimited reviews from a single platform" />
            <BulletRow label="Manage reviews with show/hide options" />
            <BulletRow label="Email notifications for new reviews" />
            <BulletRow label="Responsive design for mobile and desktop" />
            <BulletRow label="Free widget installation" />
        </FeaturesContainer>
    </PlanWrapper>
);
