import Typography from '@mui/material/Typography';
import { ButtonsWrapper, LayoutButton, Root } from './layout-selector.styles';
import React from 'react';
import { ReactComponent as CarouselLogo } from '../../assets/carousel.svg';
import { ReactComponent as MasonryLogo } from '../../assets/masonry.svg';
import { ReactComponent as ListLogo } from '../../assets/list.svg';
import { LayoutType } from '../../types/layout-type.enum';

export const LayoutSelector = ({ layout, onChange }: { layout: LayoutType; onChange: (value: LayoutType) => void }) => {
    return (
        <Root>
            <Typography color="textPrimary" typography="subtitle1" fontWeight={'600'} width={'100%'}>
                Layout
            </Typography>
            <ButtonsWrapper>
                <LayoutButton
                    $active={layout === LayoutType.carousel}
                    size="large"
                    variant="outlined"
                    onClick={() => {
                        onChange(LayoutType.carousel);
                    }}
                >
                    <CarouselLogo />
                    Carousel
                </LayoutButton>
                <LayoutButton
                    $active={layout === LayoutType.masonry}
                    size="large"
                    variant="outlined"
                    onClick={() => {
                        onChange(LayoutType.masonry);
                    }}
                >
                    <MasonryLogo />
                    Masonry
                </LayoutButton>
                <LayoutButton
                    $active={layout === LayoutType.list}
                    size="large"
                    variant="outlined"
                    onClick={() => {
                        onChange(LayoutType.list);
                    }}
                >
                    <ListLogo />
                    List
                </LayoutButton>
            </ButtonsWrapper>
        </Root>
    );
};
