import React from 'react';
import { LoadingContainer, SuccessContainer, WarningContainer } from './company-search-status.styles';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { ReactComponent as WarningIcon } from '../../assets/warning.svg';
import { ReactComponent as SuccessIcon } from '../../assets/success.svg';

interface Props {
    count?: number;
    isLoading?: boolean;
}

export const CompanySearchStatus = ({ count, isLoading }: Props) => {
    if (isLoading) {
        return (
            <LoadingContainer>
                <CircularProgress size={20} color="info" /> Checking for reviews...
            </LoadingContainer>
        );
    }
    if (count === undefined) {
        return <></>;
    }
    if (!count) {
        return (
            <WarningContainer>
                <WarningIcon /> No reviews found for the platform
            </WarningContainer>
        );
    }
    return (
        <SuccessContainer>
            <SuccessIcon />
            {count} reviews found for the platform
        </SuccessContainer>
    );
};
