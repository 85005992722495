import React from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export const ShowHiddenInput = ({ value, onChange }: { value: boolean; onChange?: (value: boolean) => void }) => {
    return (
        <Root
            control={<Checkbox checked={value} />}
            label={'Show Hidden'}
            labelPlacement="start"
            onChange={(e) => {
                const checked = (e.target as HTMLInputElement).checked;
                onChange(checked);
            }}
        />
    );
};

const Root = styled(FormControlLabel)`
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 999px;
    padding: 1px 2px 1px 16px;
    gap: 4px;
    margin: 0;
    span {
        font-size: 14px;
        font-weight: 500;
    }
`;
