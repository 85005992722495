import styled from 'styled-components';
import StarFull from '../../assets/star-full.svg';
import StarEmptyDark from '../../assets/star-empty-dark.svg';

export const StarsRating = styled.span<{ value: number; $dark?: boolean }>`
    display: flex;
    height: 20px;
    width: 100px;
    position: relative;
    background-image: url(${({ theme, $dark }) => ($dark ? StarEmptyDark : theme.starEmpty)});
    background-size: 20px;

    &:before {
        content: '';
        width: ${({ value }) => (value / 5) * 100}%;
        height: 100%;
        background-image: url(${StarFull});
        background-size: 20px;
        position: absolute;
        top: 0;
        left: 0;
    }
`;
