import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import styled from 'styled-components';

export const TextWrapper = styled.div<{ $loading: boolean }>`
    transition: opacity 0.25s;
    ${({ $loading }) => $loading && 'opacity: 0 !important;'}
`;

export const Root = styled.button<{ $disabled?: boolean; $hidden?: boolean; $variant?: string }>`
  font-family: 'Inter', 'Roboto', 'Noto Sans';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.64px;
    padding: 14px 26px;
    border-radius: 99px;
    border: none;
    color: white;
    line-height: 20px;
    flex-shrink: 0;
    //background: #4a14ff;
    background: ${({ $disabled, $variant }) =>
        $disabled
            ? 'radial-gradient(63.57% 94.79% at 50.36% 18.75%, rgba(74, 20, 255, 0.20) 0%, rgba(152, 118, 245, 0.20) 100%);'
            : $variant === 'secondary'
            ? ' radial-gradient(76.54% 98.32% at 50% 50%, rgba(5, 6, 16, 0.00) 0%, #9061FF 100%);'
            : 'radial-gradient(63.57% 94.79% at 50.36% 18.75%, #4a14ff 0%, #9876f5 100%);'}
    cursor: pointer;
    position: relative;
    overflow: hidden;
    ${({ $hidden }) => $hidden && 'display: none;'}
    backdrop-filter: blur(100px);

    &:after {
        content: '';
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border: 1px solid ${({ $disabled }) => ($disabled ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.2)')};
        //background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(153, 153, 153, 0) 100%);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 99px;
    }
  
  ${TextWrapper} {
    ${({ $disabled }) =>
        $disabled &&
        `
    opacity: 0.6;
    background: linear-gradient(92deg, #fff 0%, rgba(255, 255, 255, 0.4) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `}
  }
  
  transition: opacity .1s;
  &:hover {
    ${({ $disabled }) => !$disabled && 'opacity: 0.8'}
  }
`;
export const LoadingIndicator = styled(CircularProgress)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.6;

    animation: loadingAnim 1.4s linear infinite;
    @keyframes loadingAnim {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
`;
