import React, { useMemo } from 'react';
import GoogleLogo from '../assets/google.svg';
import { PlatformButton } from '../components/platform-selector/platform-selector.styles';
import { PlatformRedirectRoot, ReviewFormMainText } from './review-form.styles';
import YelpLogo from '../assets/yelp.svg';
import FacebookLogo from '../assets/facebook.svg';
import TrustpilotLogo from '../assets/trustpilot.svg';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { ReviewSource } from '../types/review-source.enum';
import axios from 'axios';
import { SERVER_URL } from '../constants';

export const PlatformRedirect = ({
    userId,
    contactId,
    selectedBoostPlatforms,
    googleCompanyId,
    yelpAlias,
    facebookAlias,
    trustpilotAlias,
    analytics,
    accentColor,
    onVideoClick,
}: {
    userId: string;
    contactId?: string;
    selectedBoostPlatforms: ReviewSource[];
    googleCompanyId?: string;
    yelpAlias?: string;
    facebookAlias?: string;
    trustpilotAlias?: string;
    analytics?: boolean;
    accentColor: string;
    onVideoClick?: () => void;
}) => {
    const incrementRedirectAnalytics = async () => {
        if (analytics) {
            return axios.post(`${SERVER_URL}/survey/page-redirect`, { userId, contactId });
        }
    };
    const openGoogleUrl = () => {
        incrementRedirectAnalytics();
        window.open(`https://search.google.com/local/writereview?placeid=${googleCompanyId}`);
    };
    const openYelpUrl = () => {
        incrementRedirectAnalytics();
        window.open(`https://www.yelp.com/biz/${yelpAlias}`);
    };

    const openTrustpilotUrl = () => {
        incrementRedirectAnalytics();
        window.open(`https://www.trustpilot.com/review/${trustpilotAlias}`);
    };

    const openFacebookUrl = () => {
        incrementRedirectAnalytics();
        window.open(`https://www.facebook.com/${facebookAlias}/reviews`);
    };

    const boostPlatforms = useMemo(() => {
        if (selectedBoostPlatforms.length) {
            return selectedBoostPlatforms;
        } else {
            const plaforms = [];
            if (googleCompanyId) {
                plaforms.push(ReviewSource.google);
            }
            if (yelpAlias) {
                plaforms.push(ReviewSource.yelp);
            }
            if (facebookAlias) {
                plaforms.push(ReviewSource.facebook);
            }
            if (trustpilotAlias) {
                plaforms.push(ReviewSource.trustpilot);
            }
            return plaforms;
        }
    }, [selectedBoostPlatforms]);

    return (
        <PlatformRedirectRoot>
            <ReviewFormMainText $small>
                We appreciate your feedback, please leave a review on one of the platforms
            </ReviewFormMainText>
            <Stack direction="row" gap={1}>
                {boostPlatforms.includes(ReviewSource.google) && (
                    <PlatformButton color="info" size="large" variant="outlined" onClick={openGoogleUrl} sx={{ width: '87px' }}>
                        <img src={GoogleLogo} alt="" />
                        Google
                    </PlatformButton>
                )}
                {boostPlatforms.includes(ReviewSource.yelp) && (
                    <PlatformButton color="info" size="large" variant="outlined" onClick={openYelpUrl} sx={{ width: '87px' }}>
                        <img src={YelpLogo} alt="" />
                        Yelp
                    </PlatformButton>
                )}
                {boostPlatforms.includes(ReviewSource.facebook) && (
                    <PlatformButton color="info" size="large" variant="outlined" onClick={openFacebookUrl} sx={{ width: '87px' }}>
                        <img src={FacebookLogo} alt="" />
                        Facebook
                    </PlatformButton>
                )}
                {boostPlatforms.includes(ReviewSource.trustpilot) && (
                    <PlatformButton
                        color="info"
                        size="large"
                        variant="outlined"
                        onClick={openTrustpilotUrl}
                        sx={{ width: '87px' }}
                    >
                        <img src={TrustpilotLogo} alt="" />
                        Trustpilot
                    </PlatformButton>
                )}
            </Stack>
            <Button
                variant="contained"
                fullWidth
                size="large"
                onClick={onVideoClick}
                sx={{
                    py: '11px',
                    color: accentColor,
                    backgroundColor: `${accentColor}1F`,
                    transition: 'all 0.1s',
                    '&:hover': { backgroundColor: `${accentColor}1F`, opacity: 0.7 },
                }}
                startIcon={
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="video">
                            <path
                                id="Vector"
                                d="M3.75 3.75C3.08696 3.75 2.45107 4.01339 1.98223 4.48223C1.51339 4.95107 1.25 5.58696 1.25 6.25V13.75C1.25 14.413 1.51339 15.0489 1.98223 15.5178C2.45107 15.9866 3.08696 16.25 3.75 16.25H10.625C11.288 16.25 11.9239 15.9866 12.3928 15.5178C12.8616 15.0489 13.125 14.413 13.125 13.75V6.25C13.125 5.58696 12.8616 4.95107 12.3928 4.48223C11.9239 4.01339 11.288 3.75 10.625 3.75H3.75ZM16.6167 15.625L14.375 13.3833V6.61667L16.6167 4.375C17.4033 3.5875 18.75 4.145 18.75 5.25833V14.7417C18.75 15.855 17.4033 16.4125 16.6167 15.625Z"
                                fill={accentColor}
                            />
                        </g>
                    </svg>
                }
            >
                Record a video review
            </Button>
        </PlatformRedirectRoot>
    );
};
