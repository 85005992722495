import React, { useMemo, useState } from 'react';
import { CsvInputLabel, MappingFormControl } from './collect-section.styles';
import Stack from '@mui/material/Stack/Stack';
import Select from '@mui/material/Select';
import { ReactComponent as ArrowsIcon } from '../../assets/arrows-up-down.svg';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
const StyledArrowsIcon = styled(ArrowsIcon)`
    right: 12px;
`;

interface Props {
    error: boolean;
    value: string;
    onChange: (value: string) => void;
    disabled: boolean;
    columns: string[];
    rows: Record<string, any>[];
    label: string;
}

export const MappingSelect = ({ error, value, onChange, disabled, columns, rows, label }: Props) => {
    const [opened, setOpened] = useState(false);
    const [previewColumn, setPreviewColumn] = useState('');
    const previewValues = useMemo(() => rows.map((row) => row[previewColumn]).slice(0, 10), [previewColumn]);
    return (
        <MappingFormControl fullWidth error={error && !value} sx={{ position: 'relative' }}>
            <CsvInputLabel>{label}</CsvInputLabel>
            <Stack>
                <Select
                    size="small"
                    disabled={disabled}
                    value={value}
                    label={label}
                    onChange={(e) => {
                        onChange(e.target.value);
                        setPreviewColumn('');
                    }}
                    onOpen={() => setOpened(true)}
                    onClose={() => setOpened(false)}
                    IconComponent={StyledArrowsIcon}
                    sx={{
                        borderRadius: '999px !important',
                        '&:before': { borderRadius: '999px !important' },
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                borderRadius: '16px', // Example of custom style
                                border: '1px solid #E8E9F2',
                                padding: '4px',
                                width: '253px',
                                boxShadow:
                                    '0px 45px 12px 0px rgba(0, 0, 0, 0.00), 0px 29px 11px 0px rgba(0, 0, 0, 0.00), 0px 16px 10px 0px rgba(0, 0, 0, 0.01), 0px 7px 7px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02)',
                                '& .MuiMenuItem-root': {
                                    fontSize: '14px',
                                    borderRadius: '12px',
                                    padding: '10px 16px',
                                },
                                '& .MuiList-root': {
                                    padding: '0',
                                },
                            },
                        },
                    }}
                >
                    {columns.map((column) => (
                        <MenuItem
                            key={column}
                            value={column}
                            onMouseEnter={() => setPreviewColumn(column)}
                            onMouseLeave={() => setPreviewColumn('')}
                        >
                            {column}
                        </MenuItem>
                    ))}
                </Select>
            </Stack>
            {opened && previewColumn && (
                <PreviewRoot>
                    <PreviewRow $bold>
                        <span>Row</span>
                        <span>Value</span>
                    </PreviewRow>

                    {previewValues.map((value, index) => (
                        <PreviewRow key={index}>
                            <span>{index + 1}</span>
                            <span>{value}</span>
                        </PreviewRow>
                    ))}
                </PreviewRoot>
            )}
        </MappingFormControl>
    );
};

export const PreviewRoot = styled.div`
    border-radius: 16px;
    border: 1px solid var(--Colors-UI-Stroke, #e8e9f2);
    background: var(--Colors-UI-1, #fff);
    box-shadow: 0px 45px 12px 0px rgba(0, 0, 0, 0), 0px 29px 11px 0px rgba(0, 0, 0, 0), 0px 16px 10px 0px rgba(0, 0, 0, 0.01),
        0px 7px 7px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
    position: absolute;
    top: -20px;
    right: calc(100% + 16px);
    width: 250px;
    padding: 12px;
    z-index: 10;
`;

const PreviewRow = styled.div<{ $bold?: boolean }>`
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    padding-top: 8px;

    border-bottom: 1px solid ${({ theme }) => theme.colors.border};

    ${({ $bold }) => $bold && 'font-weight: 600'};

    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        padding-bottom: 0;
        border: 0;
    }

    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-child {
            width: 60px;
            flex-shrink: 0;
        }

        &:last-child {
            font-weight: ${({ $bold }) => ($bold ? '600' : '500')};
            flex: 1 1 auto;
        }
    }
`;
