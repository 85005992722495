import styled from 'styled-components';
import { Content } from '../review-card/review-card.styles';

export const MasonryRoot = styled.div`
    padding: 0 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    ${Content} {
        min-height: unset;
    }
`;
