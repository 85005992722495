import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
    AvatarLetter,
    BottomGradient,
    EmailSender,
    InboxText,
    NotificationContainer,
    SectionContainer,
    Title,
    LogoImage,
    EmailSubtitle,
    EmailFooterText,
    NextMessageText,
} from './request-preview.styles';
import { ReactComponent as Device } from '../../assets/device.svg';
import { ReactComponent as EmailContainer } from '../../assets/email-container.svg';
import { Notification } from './notification.component';
import { EmailCard } from './email-card.component';
import { RecipientText, SubjectText, TimeText } from './email-card.styles';
import { CompanyTitle } from '../../review-form/review-form.styles';
import { ReactComponent as CheckboxUnchecked } from '../../assets/checkbox-unchecked.svg';
import { ReactComponent as StarFull } from '../../assets/star-full.svg';
import { ReactComponent as StarEmpty } from '../../assets/star-empty.svg';

export const RequestPreviewComponent = ({ companyName, logoUrl }: { companyName: string; logoUrl: string }) => {
    const logo = useMemo(
        () => (logoUrl ? <LogoImage src={logoUrl} /> : companyName && <CompanyTitle>{companyName}</CompanyTitle>),
        [logoUrl]
    );
    return (
        <Stack direction="row" gap={2} sx={{ height: '100%' }} flexWrap="wrap" overflow="auto">
            <SectionContainer $minWidth={'390px'}>
                <Stack>
                    <Title>SMS</Title>
                    <Stack sx={{ position: 'relative', width: '315px', margin: '0 auto' }}>
                        <Device />
                        <BottomGradient />
                        <NotificationContainer>
                            <Notification size="medium" companyName={companyName} />
                            <NextMessageText $weight={510}>Send next message in 2 days</NextMessageText>
                            <Notification size="small" companyName={companyName} />
                        </NotificationContainer>
                    </Stack>
                </Stack>
            </SectionContainer>
            <SectionContainer $minWidth={'655px'}>
                <Stack>
                    <Title>Email</Title>
                    <Stack sx={{ position: 'relative' }}>
                        <EmailContainer />
                        <Stack sx={{ position: 'absolute', top: '26px', left: '18px', width: '150px' }}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 4 }}>
                                <InboxText $weight={500}>Inbox</InboxText>
                                <InboxText $weight={400}>323</InboxText>
                            </Stack>
                        </Stack>
                        <Stack sx={{ position: 'absolute', top: '74px', left: '8px', width: '172px' }} gap={'12px'}>
                            <EmailCard companyName={companyName} active />
                            <NextMessageText $dark $weight={500} $margin="0 8px">
                                Send next message in 2 days
                            </NextMessageText>
                            <EmailCard companyName={companyName} />
                        </Stack>
                        <Stack sx={{ position: 'absolute', top: '22px', left: '208px', width: '400px' }} gap={'6px'}>
                            <Stack direction="row" gap={'12px'} width="100%">
                                <AvatarLetter>{companyName.slice(0, 1)}</AvatarLetter>
                                <Stack width="100%" gap={0.5}>
                                    <Stack direction="row" justifyContent="space-between" sx={{ flex: '1 1 auto' }}>
                                        <EmailSender>{companyName}</EmailSender>
                                        <TimeText $size="medium" $secondary>
                                            Now
                                        </TimeText>
                                    </Stack>
                                    <SubjectText>Tell us how we did - {companyName}</SubjectText>
                                    <RecipientText>
                                        Recipient: <span>{`<Your customer email here>`}</span>
                                    </RecipientText>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack
                            sx={{ position: 'absolute', top: '118px', left: '208px', width: '400px' }}
                            gap={'20px'}
                            alignItems="center"
                        >
                            {logo}
                            <Stack gap="12px" alignItems="center">
                                <Stack alignItems="center">
                                    <Typography sx={{ mb: 0.5 }}>Hi Alex,</Typography>
                                    <Typography fontWeight="500">Please review your experience with us.</Typography>
                                </Stack>
                                <EmailSubtitle>Your feedback helps us become better.</EmailSubtitle>
                                <Stack direction="row" gap={2} alignItems="center">
                                    <CheckboxUnchecked />
                                    <Stack direction="row" gap={0.5} alignItems="center">
                                        <StarFull />
                                        <StarFull />
                                        <StarFull />
                                        <StarFull />
                                        <StarFull />
                                    </Stack>
                                </Stack>
                                <Stack direction="row" gap={2} alignItems="center">
                                    <CheckboxUnchecked />
                                    <Stack direction="row" gap={0.5} alignItems="center">
                                        <StarFull />
                                        <StarFull />
                                        <StarFull />
                                        <StarFull />
                                        <StarEmpty />
                                    </Stack>
                                </Stack>
                                <Stack direction="row" gap={2} alignItems="center">
                                    <CheckboxUnchecked />
                                    <Stack direction="row" gap={0.5} alignItems="center">
                                        <StarFull />
                                        <StarFull />
                                        <StarFull />
                                        <StarEmpty />
                                        <StarEmpty />
                                    </Stack>
                                </Stack>
                                <Stack direction="row" gap={2} alignItems="center">
                                    <CheckboxUnchecked />
                                    <Stack direction="row" gap={0.5} alignItems="center">
                                        <StarFull />
                                        <StarFull />
                                        <StarEmpty />
                                        <StarEmpty />
                                        <StarEmpty />
                                    </Stack>
                                </Stack>
                                <Stack direction="row" gap={2} alignItems="center">
                                    <CheckboxUnchecked />
                                    <Stack direction="row" gap={0.5} alignItems="center">
                                        <StarFull />
                                        <StarEmpty />
                                        <StarEmpty />
                                        <StarEmpty />
                                        <StarEmpty />
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack gap="12px" alignItems="center">
                                <EmailFooterText $weight={500}>Thank you for your time and support!</EmailFooterText>
                                <EmailFooterText $weight={400}>
                                    Best regards, <br />
                                    {companyName}
                                </EmailFooterText>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </SectionContainer>
        </Stack>
    );
};
