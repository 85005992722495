import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { BillingCycleType } from '../../types/billing-cycle.type';

export const BillingPeriodSwitcher = ({
    value,
    onChange,
}: {
    value: BillingCycleType;
    onChange: (value: BillingCycleType) => void;
}) => {
    const isYearlyBilling = value === 'year';
    return (
        <Root>
            <Button
                variant="text"
                color="info"
                sx={{ '&.MuiButton-sizeMedium': { padding: '6px 12px' }, background: !isYearlyBilling ? '#fff' : 'unset' }}
                onClick={() => onChange('month')}
            >
                Monthly
            </Button>
            <Button
                variant="text"
                color="info"
                sx={{ '&.MuiButton-sizeMedium': { padding: '0 6px 0 12px' }, background: isYearlyBilling ? '#fff' : 'unset' }}
                onClick={() => onChange('year')}
            >
                Yearly
                <DiscountChip>-20%</DiscountChip>
            </Button>
        </Root>
    );
};

const Root = styled.div`
    display: flex;
    gap: 4px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background: ${({ theme }) => theme.colors.controlBackground};
    border-radius: 999px;
    padding: 2px;
`;

const DiscountChip = styled.div`
    background: ${({ theme }) => theme.colors.primary};
    margin-left: 6px;
    border-radius: 999px;
    padding: 0 6px;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.bg};
`;
