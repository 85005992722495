import React, { useMemo } from 'react';
import { ReviewType } from '../../types/review.type';
import { Column, Root, Title } from './video-layout.styles';
import { ReviewCard } from '../review-card/review-card.component';

export const VideoLayout = ({
    items,
    accentColor,
    aiHighlight,
    isProPlan,
}: {
    items?: ReviewType[];
    accentColor?: string;
    aiHighlight?: boolean;
    isProPlan?: boolean;
}) => {
    const reviews = useMemo(
        () => ({ text: items.filter((el) => !el.videoUrl), video: items.filter((el) => el.videoUrl) }),
        [items]
    );

    return (
        <Root>
            <Column $text>
                <Title>Reviews</Title>
                {reviews.text.map((el, index) => (
                    <ReviewCard key={index} item={el} accentColor={accentColor} aiHighlight={aiHighlight} isProPlan={isProPlan} />
                ))}
            </Column>
            <Column>
                <Title>Video reviews</Title>
                {reviews.video.map((el, index) => (
                    <ReviewCard key={index} item={el} accentColor={accentColor} aiHighlight={aiHighlight} isProPlan={isProPlan} />
                ))}
            </Column>
        </Root>
    );
};

export default VideoLayout;
