import styled from 'styled-components';
import { MultipleRoot, SummaryRoot } from '../../components/reviews-summary/reviews-summary.styles';

export const ReviewsContainer = styled.div<{ $cards?: boolean }>`
    width: 100%;
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: ${({ $cards }) => ($cards ? 'row' : 'column')};
    gap: 24px;
    flex-wrap: ${({ $cards }) => ($cards ? 'wrap' : 'unset')};
    padding-bottom: 110px;
`;

export const ReviewsDisplayWrapper = styled.div`
    display: flex;
    margin-top: 36px;
    gap: 24px;
    height: 100%;
`;

export const ReviewsSectionHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${MultipleRoot} {
        margin: 0;
    }
    ${SummaryRoot} {
        width: 180px;
    }
`;
