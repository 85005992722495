import React, { ReactElement, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { CardDescription, CardRoot, CardTitle } from './requests-analytics.styles';
import { SurveyAnalyticsType } from '../../types/survey-analytics.type';
import axios from 'axios';
import { SERVER_URL } from '../../constants';
import { ReactComponent as SentIcon } from '../../assets/sent.svg';
import { ReactComponent as ClickIcon } from '../../assets/click.svg';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import { ReactComponent as SubmittedIcon } from '../../assets/submitted.svg';
import Skeleton from '@mui/material/Skeleton';

export const RequestsAnalytics = ({ isProPlan }: { isProPlan?: boolean }) => {
    const [analytics, setAnalytics] = useState<SurveyAnalyticsType>();
    const [isLoading, setIsLoading] = useState(true);
    const fetchAnalytics = async () => {
        try {
            const token = localStorage.getItem('access_token');
            if (token) {
                const { data } = await axios.get(`${SERVER_URL}/survey-analytics`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setAnalytics(data);
                setIsLoading(false);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (isProPlan) {
            fetchAnalytics();
        } else {
            setAnalytics({
                _id: '',
                emailSent: 100,
                smsSent: 123,
                surveyOpened: 103,
                reviewPageRedirect: 62,
                privateFeedbackSent: 12,
            });
            setIsLoading(false);
        }
    }, []);

    return (
        <Stack gap={2} maxWidth={1200}>
            <Stack direction="row" gap={2}>
                <AnalyticsCard
                    label="Requests sent"
                    value={analytics?.emailSent + analytics?.smsSent}
                    icon={<SentIcon />}
                    loading={isLoading}
                />
                <AnalyticsCard label="Requests opened" value={analytics?.surveyOpened} icon={<ClickIcon />} loading={isLoading} />
            </Stack>
            <Stack direction="row" gap={2}>
                <AnalyticsCard
                    label="Negative reviews blocked"
                    value={analytics?.privateFeedbackSent}
                    icon={<LockIcon />}
                    loading={isLoading}
                />
                <AnalyticsCard
                    label="Positive reviews submitted"
                    value={analytics?.reviewPageRedirect}
                    icon={<SubmittedIcon />}
                    loading={isLoading}
                />
            </Stack>
        </Stack>
    );
};

const AnalyticsCard = ({
    label,
    value,
    icon,
    loading,
}: {
    label: string;
    value: number;
    icon: ReactElement;
    loading: boolean;
}) => (
    <CardRoot>
        <Stack direction="row" alignItems="center" gap={1}>
            {icon}
            <CardDescription>{label}</CardDescription>
        </Stack>
        <CardTitle>{loading ? <Skeleton variant="text" width={'70px'} /> : value}</CardTitle>
    </CardRoot>
);
