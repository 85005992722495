import IconButton from '@mui/material/IconButton/IconButton';
import styled from 'styled-components';

export const Root = styled.div`
    padding: 8px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: #fff;
    min-width: 360px;
    flex: 1 1 360px;
    max-width: 500px;
`;

export const StyledVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const VideoContainer = styled.div`
    width: 100%;
    height: 200px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    margin-bottom: 8px;
    cursor: pointer;
`;

export const PlayButton = styled(IconButton)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: ${({ theme }) => theme.colors.bg};
    padding: 10px;

    &:hover {
        background: rgba(255, 255, 255, 0.7);
    }
`;

export const RatingContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    svg {
        width: 20px;
        height: 20px;
    }
`;
