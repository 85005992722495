import { getRecorderExtension } from '../../utils/deviceInfo';

interface Size {
    width: number;
    height: number;
}

class RecorderService {
    videoElement: HTMLVideoElement;
    videoCanvas: HTMLCanvasElement;
    stream: MediaStream | null = null;
    videoSize?: Size;
    captureSize?: Size;
    recorder?: MediaRecorder;
    protected records: Blob[] = [];
    protected type: string = getRecorderExtension();

    constructor(protected bitRate: number = 2310000) {
        this.videoElement = document.createElement('video');
        this.videoElement.setAttribute('playsinline', '');
        this.videoElement.muted = true;
    }

    async requestPermissions(): Promise<void> {
        this.videoCanvas = document.getElementById('recording-canvas') as HTMLCanvasElement;
        if (!this.videoCanvas) {
            console.error('Canvas is not found');
            return;
        }

        // Request the user's video
        this.stream = await navigator.mediaDevices.getUserMedia({
            video: {
                width: 1280,
                height: 720,
                frameRate: { max: 30 },
            },
            audio: true,
        });
        this.videoElement.srcObject = this.stream;

        return new Promise((resolve) => {
            this.videoElement.onloadedmetadata = () => {
                const { videoWidth: width, videoHeight: height } = this.videoElement;
                this.videoSize = { width: width, height: height };
                this.captureSize = this.videoSize;

                this.videoCanvas.width = this.captureSize.width;
                this.videoCanvas.height = this.captureSize.height;
                const context = this.videoCanvas.getContext('2d');
                context.translate(this.videoCanvas.width, 0);
                context.scale(-1, 1);
                resolve();
            };
            this.videoElement.srcObject = this.stream;
            this.drawVideo();
            this.videoElement.play();
        });
    }

    async getDevices() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = devices.filter((device) => device.kind === 'audioinput');
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');

        return { audioDevices, videoDevices };
    }

    async startRecording() {
        try {
            this.recorder = new MediaRecorder(this.stream, {
                mimeType: this.type,
                videoBitsPerSecond: this.bitRate,
                audioBitsPerSecond: 128000,
            });
            this.recorder.ondataavailable = (event) => this.records.push(event.data);
            this.recorder.start();
        } catch (error) {
            console.error('Error starting video recording:', error);
        }
    }

    async changeMediaDevices(audioDeviceId: string, videoDeviceId: string) {
        try {
            if (this.stream) {
                // Stop all current audio and video tracks
                this.stream.getTracks().forEach((track) => track.stop());
            }

            // Request a new stream with the selected audio and video devices
            this.stream = await navigator.mediaDevices.getUserMedia({
                video: videoDeviceId
                    ? { deviceId: { exact: videoDeviceId }, width: 1280, height: 720, frameRate: { max: 30 } }
                    : false,
                audio: audioDeviceId ? { deviceId: { exact: audioDeviceId } } : false,
            });

            // Update the video element's source object
            this.videoElement.srcObject = this.stream;

            await this.videoElement.play();
        } catch (error) {
            console.error('Error changing media devices:', error);
        }
    }

    drawVideo() {
        if (this.videoElement && this.videoCanvas) {
            const context = this.videoCanvas.getContext('2d');
            if (context) {
                context.drawImage(this.videoElement, 0, 0, this.videoCanvas.width, this.videoCanvas.height);
                requestAnimationFrame(this.drawVideo.bind(this));
            }
        }
    }

    stopStream() {
        this.stream?.getTracks().forEach((t) => t.stop());
        delete this.stream;
    }

    async stopRecording() {
        const { recorder } = this;
        if (!recorder || recorder.state != 'recording') return;
        return new Promise<Blob>((resolve) => {
            recorder.onstop = () => {
                resolve(new Blob(this.records, { type: this.type }));
                this.records = [];
                this.stream?.getTracks().forEach((t) => t.stop());
                delete this.stream;
                delete this.recorder;
            };
            recorder.stop();
        });
    }
}

export default new RecorderService();
