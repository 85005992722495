import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { ContentWrapper } from '../instruction-dialog.styles';

export const WebflowArticle = () => (
    <>
        <DialogTitle textAlign="left" align="center" sx={{ p: 0, mt: 3, fontSize: '18px' }}>
            How to add widget to Webflow website
        </DialogTitle>
        <ContentWrapper>
            <Typography>Log in to your Webflow account and choose the website where you'd like to add the widget.</Typography>
            <img src="/instructions/webflow-1.png" alt="" />
            <Typography>
                Click on <strong>Plus</strong> icon, then drag and drop the <strong>Embed</strong> element from the left-hand menu
                to the place where you'd like the widget to be displayed.
            </Typography>
            <img src="/instructions/webflow-2.png" alt="" />
            <img src="/instructions/webflow-3.png" alt="" />
            <Typography>
                Paste your installation code into HTML Embed Code Editor window that appears and click{' '}
                <strong>Save & Close</strong>.
            </Typography>
            <img src="/instructions/webflow-4.png" alt="" />
            <Typography>
                Adjust needed settings in the right-hand menu, then click <strong>Publish</strong> and press{' '}
                <strong>Publish to Selected Domains</strong>.
            </Typography>
            <img src="/instructions/webflow-5.png" alt="" />
            <Typography>You have successfully added the widget to a page of your Webflow website!</Typography>
        </ContentWrapper>
    </>
);
