export function isTextOverflowing(element: HTMLElement) {
    if (!element) {
        console.warn('Element not found');
        return false;
    }

    const isOverflowingHorizontally = element.scrollWidth > element.clientWidth;
    const isOverflowingVertically = element.scrollHeight > element.clientHeight;

    return isOverflowingHorizontally || isOverflowingVertically;
}
