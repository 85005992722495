import styled from 'styled-components';

export const LayoutSwitcherRoot = styled.div`
    display: flex;
    gap: 4px;
    padding: 4px;
    border-radius: 999px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.bg};
    path {
        transition: fill 0.1s linear;
    }
    height: fit-content;

    button {
        width: 32px;
        height: 32px;
    }
    svg {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
    }
`;
