import React, { useMemo } from 'react';
import { Root } from './reviews-filter.styles';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import { ReactComponent as GLogo } from '../../assets/google.svg';
import { ReactComponent as YLogo } from '../../assets/yelp.svg';
import { ReactComponent as FLogo } from '../../assets/facebook.svg';
import { ReactComponent as TLogo } from '../../assets/trustpilot.svg';
import { ReviewSource } from '../../types/review-source.enum';
import { StarsRating } from '../../components/stars-rating/stars-rating.component';
import { SubsectionTitle, SubsectionWrapper } from '../../components/common.styles';
import { FormPlatformCheckboxComponent } from '../collect-section/form-platform-checkbox.component';
import { getAvailableUserPlatforms } from '../../utils/getAvailableUserPlatforms';
import { UserPopulatedType } from '../../types/user-data.type';

export type ReviewsFilterType = {
    sources: ReviewSource[];
    rating: number[];
    showHidden: boolean;
};

interface Props {
    value: ReviewsFilterType;
    onChange: (value: ReviewsFilterType) => void;
    userPopulatedData: UserPopulatedType;
}

export const ReviewsFilter = ({ value, onChange, userPopulatedData }: Props) => {
    const availableBoostPlatforms = useMemo(() => getAvailableUserPlatforms(userPopulatedData), [userPopulatedData.user]);
    return (
        <Root>
            <SubsectionWrapper>
                <SubsectionTitle $margin>Source Platforms</SubsectionTitle>
                <FormGroup
                    onChange={(e) => {
                        const source = (e.target as HTMLInputElement).name as ReviewSource;
                        if (value.sources.includes(source)) {
                            onChange({ ...value, sources: value.sources.filter((s) => s !== source) });
                        } else {
                            onChange({ ...value, sources: [...value.sources, source] });
                        }
                    }}
                >
                    <Stack gap={1}>
                        <FormPlatformCheckboxComponent
                            checked={value.sources.includes(ReviewSource.google)}
                            disabled={!availableBoostPlatforms.includes(ReviewSource.google)}
                            logo={<GLogo />}
                            name={ReviewSource.google}
                            label={'Google'}
                        />
                        <FormPlatformCheckboxComponent
                            checked={value.sources.includes(ReviewSource.yelp)}
                            disabled={!availableBoostPlatforms.includes(ReviewSource.yelp)}
                            logo={<YLogo />}
                            name={ReviewSource.yelp}
                            label={'Yelp'}
                        />
                        <FormPlatformCheckboxComponent
                            checked={value.sources.includes(ReviewSource.facebook)}
                            disabled={!availableBoostPlatforms.includes(ReviewSource.facebook)}
                            logo={<FLogo />}
                            name={ReviewSource.facebook}
                            label={'Facebook'}
                        />
                        <FormPlatformCheckboxComponent
                            checked={value.sources.includes(ReviewSource.trustpilot)}
                            disabled={!availableBoostPlatforms.includes(ReviewSource.trustpilot)}
                            logo={<TLogo />}
                            name={ReviewSource.trustpilot}
                            label={'Trustpilot'}
                        />
                    </Stack>
                </FormGroup>
            </SubsectionWrapper>
            <SubsectionWrapper>
                <SubsectionTitle $margin>Rating</SubsectionTitle>
                <FormGroup
                    onChange={(e) => {
                        const checkbox = e.target as HTMLInputElement;
                        const name = checkbox.name;
                        const checked = checkbox.checked;
                        if (checked) {
                            onChange({ ...value, rating: [...value.rating, parseInt(name, 10)] });
                        } else {
                            onChange({ ...value, rating: value.rating.filter((r) => r !== parseInt(name, 10)) });
                        }
                    }}
                >
                    <Stack gap={1}>
                        <FormPlatformCheckboxComponent
                            checked={value.rating.includes(5)}
                            label={<StarsRating value={5} />}
                            name={'5'}
                        />
                        <FormPlatformCheckboxComponent
                            checked={value.rating.includes(4)}
                            label={<StarsRating value={4} />}
                            name={'4'}
                        />
                        <FormPlatformCheckboxComponent
                            checked={value.rating.includes(3)}
                            label={<StarsRating value={3} />}
                            name={'3'}
                        />
                        <FormPlatformCheckboxComponent
                            checked={value.rating.includes(2)}
                            label={<StarsRating value={2} />}
                            name={'2'}
                        />
                        <FormPlatformCheckboxComponent
                            checked={value.rating.includes(1)}
                            label={<StarsRating value={1} />}
                            name={'1'}
                        />
                    </Stack>
                </FormGroup>
            </SubsectionWrapper>
        </Root>
    );
};
