import React from 'react';
import styled from 'styled-components';
import { LockIcon } from '../lock-icon.component';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export const PaidFeaturesLock = ({ onUpgrade }: { onUpgrade?: () => void }) => {
    return (
        <Root>
            <ContentWrapper>
                <LockIcon size={48} />
                <Stack alignItems="center" gap={1}>
                    <Typography textAlign="center" sx={{ fontSize: '24px', fontWeight: '500' }}>
                        This is a paid feature
                    </Typography>
                    <Typography textAlign="center" sx={{ fontSize: '16px', fontWeight: '500', color: 'grey.main' }}>
                        Upgrade to a Pro plan to access these features.
                    </Typography>
                </Stack>
                <Button variant="contained" onClick={onUpgrade}>
                    Upgrade
                </Button>
            </ContentWrapper>
        </Root>
    );
};

const Root = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1.5px);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.4);
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
`;
