import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack/Stack';
import Button from '@mui/material/Button';
import { LabelWrapper, MenuRoot } from './video-form.styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import RecorderService from '../components/video-recording/recorder.service';

export const DevicesSelector = ({ permissionsGranted }: { permissionsGranted: boolean }) => {
    const [microphoneSelectorOpen, setMicrophoneSelectorOpen] = useState(false);
    const [videoSelectorOpen, setVideoSelectorOpen] = useState(false);
    const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
    const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
    const [activeAudio, setActiveAudio] = useState<MediaDeviceInfo>();
    const [activeVideo, setActiveVideo] = useState<MediaDeviceInfo>();

    useEffect(() => {
        if (permissionsGranted) {
            RecorderService.getDevices().then((devices) => {
                setAudioDevices(devices.audioDevices);
                setVideoDevices(devices.videoDevices);
                if (devices.audioDevices.length) {
                    setActiveAudio(devices.audioDevices[0]);
                }
                if (devices.videoDevices.length) {
                    setActiveVideo(devices.videoDevices[0]);
                }
            });
        }
    }, [permissionsGranted]);

    return (
        <Stack direction="row" gap={2}>
            <Stack position="relative" width="calc(50% - 8px)" maxWidth="207px">
                <Button
                    color="info"
                    variant={permissionsGranted ? 'outlined' : 'contained'}
                    startIcon={permissionsGranted ? Mic : MicOff}
                    fullWidth
                    disabled={!permissionsGranted || !audioDevices.length}
                    onClick={() => setMicrophoneSelectorOpen(true)}
                    sx={{ whiteSpace: 'nowrap' }}
                >
                    {activeAudio ? <LabelWrapper>{activeAudio.label}</LabelWrapper> : <>Permission required</>}
                </Button>
                {microphoneSelectorOpen && (
                    <ClickAwayListener onClickAway={() => setMicrophoneSelectorOpen(false)}>
                        <MenuRoot>
                            {audioDevices.map((column) => (
                                <MenuItem
                                    onClick={() => {
                                        setMicrophoneSelectorOpen(false);
                                        RecorderService.changeMediaDevices(column.deviceId, activeVideo.deviceId);
                                        setActiveAudio(audioDevices.find((device) => device.deviceId === column.deviceId));
                                    }}
                                    sx={MenuItemStyles}
                                    key={column.label}
                                >
                                    {column.label}
                                </MenuItem>
                            ))}
                        </MenuRoot>
                    </ClickAwayListener>
                )}
            </Stack>

            <Stack position="relative" width="calc(50% - 8px)" maxWidth="207px">
                <Button
                    color="info"
                    variant={permissionsGranted ? 'outlined' : 'contained'}
                    startIcon={permissionsGranted ? Cam : CamOff}
                    fullWidth
                    disabled={!permissionsGranted || !videoDevices.length}
                    onClick={() => setVideoSelectorOpen(true)}
                    sx={{ whiteSpace: 'nowrap' }}
                >
                    {activeVideo ? <LabelWrapper>{activeVideo.label}</LabelWrapper> : <>Permission required</>}
                </Button>
                {videoSelectorOpen && (
                    <ClickAwayListener onClickAway={() => setVideoSelectorOpen(false)}>
                        <MenuRoot>
                            {videoDevices.map((column) => (
                                <MenuItem
                                    onClick={() => {
                                        setVideoSelectorOpen(false);
                                        RecorderService.changeMediaDevices(activeAudio.deviceId, column.deviceId);
                                        setActiveVideo(videoDevices.find((device) => device.deviceId === column.deviceId));
                                    }}
                                    sx={MenuItemStyles}
                                    key={column.label}
                                >
                                    {column.label}
                                </MenuItem>
                            ))}
                        </MenuRoot>
                    </ClickAwayListener>
                )}
            </Stack>
        </Stack>
    );
};

const MicOff = (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path
            d="M3.43278 3.18278C3.54997 3.06574 3.70882 3 3.87445 3C4.04008 3 4.19893 3.06574 4.31612 3.18278L16.8161 15.6828C16.8775 15.74 16.9268 15.809 16.9609 15.8857C16.9951 15.9623 17.0135 16.0451 17.0149 16.129C17.0164 16.2129 17.001 16.2963 16.9696 16.3741C16.9381 16.4519 16.8913 16.5226 16.832 16.582C16.7726 16.6413 16.7019 16.6881 16.6241 16.7196C16.5463 16.751 16.4629 16.7664 16.379 16.7649C16.2951 16.7635 16.2123 16.7451 16.1357 16.7109C16.059 16.6768 15.99 16.6275 15.9328 16.5661L3.43278 4.06612C3.31574 3.94893 3.25 3.79008 3.25 3.62445C3.25 3.45882 3.31574 3.29997 3.43278 3.18278Z"
            fill="#CDCED6"
        />
        <path
            d="M14.625 9.375V10.625C14.625 11.0577 14.5609 11.4837 14.438 11.8904L15.4106 12.8631C15.7145 12.1624 15.875 11.4013 15.875 10.625V9.375C15.875 9.20924 15.8092 9.05027 15.6919 8.93306C15.5747 8.81585 15.4158 8.75 15.25 8.75C15.0842 8.75 14.9253 8.81585 14.8081 8.93306C14.6908 9.05027 14.625 9.20924 14.625 9.375Z"
            fill="#CDCED6"
        />
        <path
            d="M5.69194 8.93306C5.57473 8.81585 5.41576 8.75 5.25 8.75C5.08424 8.75 4.92527 8.81585 4.80806 8.93306C4.69085 9.05027 4.625 9.20924 4.625 9.375V10.625C4.62502 12.0087 5.13497 13.3438 6.05736 14.3752C6.97976 15.4066 8.2499 16.0619 9.625 16.2158V18.125H7.125C6.95924 18.125 6.80027 18.1908 6.68306 18.3081C6.56585 18.4253 6.5 18.5842 6.5 18.75C6.5 18.9158 6.56585 19.0747 6.68306 19.1919C6.80027 19.3092 6.95924 19.375 7.125 19.375H13.375C13.5408 19.375 13.6997 19.3092 13.8169 19.1919C13.9342 19.0747 14 18.9158 14 18.75C14 18.5842 13.9342 18.4253 13.8169 18.3081C13.6997 18.1908 13.5408 18.125 13.375 18.125H10.875V16.2158C11.747 16.1182 12.5768 15.819 13.3025 15.3501L12.3921 14.4397C11.7436 14.8038 11.0069 15 10.25 15C9.08968 15 7.97688 14.5391 7.15641 13.7186C6.33594 12.8981 5.875 11.7853 5.875 10.625V9.375C5.875 9.20924 5.80915 9.05027 5.69194 8.93306Z"
            fill="#CDCED6"
        />
        <path
            d="M8.04029 12.8347C8.62634 13.4208 9.4212 13.75 10.25 13.75C10.6693 13.75 11.0798 13.6657 11.4591 13.5066L7.125 9.17255V10.625C7.125 11.4538 7.45424 12.2487 8.04029 12.8347Z"
            fill="#CDCED6"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.04029 1.54029C7.45424 2.12634 7.125 2.9212 7.125 3.75V4.57745L13.3688 10.8213C13.3729 10.7561 13.375 10.6907 13.375 10.625V3.75C13.375 2.9212 13.0458 2.12634 12.4597 1.54029C11.8737 0.95424 11.0788 0.625 10.25 0.625C9.4212 0.625 8.62634 0.95424 8.04029 1.54029Z"
            fill="#CDCED6"
        />
    </svg>
);

const CamOff = (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path
            d="M1.55827 3.30778C1.67546 3.19074 1.83431 3.125 1.99994 3.125C2.16556 3.125 2.32442 3.19074 2.4416 3.30778L14.9416 15.8078C15.003 15.865 15.0523 15.934 15.0864 16.0107C15.1206 16.0873 15.139 16.1701 15.1404 16.254C15.1419 16.3379 15.1265 16.4213 15.095 16.4991C15.0636 16.5769 15.0168 16.6476 14.9575 16.707C14.8981 16.7663 14.8274 16.8131 14.7496 16.8446C14.6718 16.876 14.5884 16.8914 14.5045 16.8899C14.4206 16.8885 14.3378 16.8701 14.2612 16.8359C14.1845 16.8018 14.1155 16.7525 14.0583 16.6911L1.55827 4.19112C1.44123 4.07393 1.37549 3.91508 1.37549 3.74945C1.37549 3.58382 1.44123 3.42497 1.55827 3.30778ZM15.1249 13.3828L17.3666 15.6244C18.1533 16.412 19.4999 15.8545 19.4999 14.7411V5.25778C19.4999 4.14445 18.1533 3.58695 17.3666 4.37445L15.1249 6.61612V13.3828ZM13.8749 6.24945V12.9728L4.65161 3.74945H11.3749C12.038 3.74945 12.6739 4.01284 13.1427 4.48168C13.6115 4.95052 13.8749 5.58641 13.8749 6.24945ZM1.99994 13.7494V6.40112L11.8108 16.2119C11.6691 16.2369 11.5233 16.2494 11.3749 16.2494H4.49994C3.8369 16.2494 3.20101 15.9861 2.73217 15.5172C2.26333 15.0484 1.99994 14.4125 1.99994 13.7494Z"
            fill="#CDCED6"
        />
    </svg>
);

const Mic = (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path
            d="M7.625 3.75C7.625 2.9212 7.95424 2.12634 8.54029 1.54029C9.12634 0.95424 9.9212 0.625 10.75 0.625C11.5788 0.625 12.3737 0.95424 12.9597 1.54029C13.5458 2.12634 13.875 2.9212 13.875 3.75V10.625C13.875 11.4538 13.5458 12.2487 12.9597 12.8347C12.3737 13.4208 11.5788 13.75 10.75 13.75C9.9212 13.75 9.12634 13.4208 8.54029 12.8347C7.95424 12.2487 7.625 11.4538 7.625 10.625V3.75Z"
            fill="#06070D"
        />
        <path
            d="M5.75 8.75C5.91576 8.75 6.07473 8.81585 6.19194 8.93306C6.30915 9.05027 6.375 9.20924 6.375 9.375V10.625C6.375 11.7853 6.83594 12.8981 7.65641 13.7186C8.47688 14.5391 9.58968 15 10.75 15C11.9103 15 13.0231 14.5391 13.8436 13.7186C14.6641 12.8981 15.125 11.7853 15.125 10.625V9.375C15.125 9.20924 15.1908 9.05027 15.3081 8.93306C15.4253 8.81585 15.5842 8.75 15.75 8.75C15.9158 8.75 16.0747 8.81585 16.1919 8.93306C16.3092 9.05027 16.375 9.20924 16.375 9.375V10.625C16.375 12.0087 15.865 13.3438 14.9426 14.3752C14.0202 15.4066 12.7501 16.0619 11.375 16.2158V18.125H13.875C14.0408 18.125 14.1997 18.1908 14.3169 18.3081C14.4342 18.4253 14.5 18.5842 14.5 18.75C14.5 18.9158 14.4342 19.0747 14.3169 19.1919C14.1997 19.3092 14.0408 19.375 13.875 19.375H7.625C7.45924 19.375 7.30027 19.3092 7.18306 19.1919C7.06585 19.0747 7 18.9158 7 18.75C7 18.5842 7.06585 18.4253 7.18306 18.3081C7.30027 18.1908 7.45924 18.125 7.625 18.125H10.125V16.2158C8.7499 16.0619 7.47976 15.4066 6.55736 14.3752C5.63497 13.3438 5.12502 12.0087 5.125 10.625V9.375C5.125 9.20924 5.19085 9.05027 5.30806 8.93306C5.42527 8.81585 5.58424 8.75 5.75 8.75Z"
            fill="black"
        />
    </svg>
);

export const Cam = (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path
            d="M4.5 3.75C3.83696 3.75 3.20107 4.01339 2.73223 4.48223C2.26339 4.95107 2 5.58696 2 6.25V13.75C2 14.413 2.26339 15.0489 2.73223 15.5178C3.20107 15.9866 3.83696 16.25 4.5 16.25H11.375C12.038 16.25 12.6739 15.9866 13.1428 15.5178C13.6116 15.0489 13.875 14.413 13.875 13.75V6.25C13.875 5.58696 13.6116 4.95107 13.1428 4.48223C12.6739 4.01339 12.038 3.75 11.375 3.75H4.5ZM17.3667 15.625L15.125 13.3833V6.61667L17.3667 4.375C18.1533 3.5875 19.5 4.145 19.5 5.25833V14.7417C19.5 15.855 18.1533 16.4125 17.3667 15.625Z"
            fill="#06070D"
        />
    </svg>
);

const MenuItemStyles = {
    fontSize: '14px',
    borderRadius: '12px',
    padding: '10px 16px',
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
};
