import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ContactsTableHeader, TableContactsContainer } from './collect-section.styles';
import {
    ErrorContainer,
    LoadingContainer,
    SuccessContainer,
} from '../../components/company-search-status/company-search-status.styles';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { ReactComponent as WarningIcon } from '../../assets/warning.svg';
import { ReactComponent as SuccessIcon } from '../../assets/success.svg';
import { ReactComponent as VisibleIcon } from '../../assets/visible.svg';
import { ReactComponent as EmailIcon } from '../../assets/email.svg';
import { ReactComponent as MessageIcon } from '../../assets/message.svg';
import moment from 'moment-mini';
import Stack from '@mui/material/Stack';
import { ContactType } from '../../types/contact.type';
import { TableActions } from './table-actions.component';
import Typography from '@mui/material/Typography';

export const EmailContacts = ({ contacts, onDelete }: { contacts: ContactType[]; onDelete: (contact: ContactType) => void }) => {
    if (!contacts.length) {
        return (
            <Stack sx={{ py: 2, height: '100%' }} gap={1} alignItems="center" justifyContent="center">
                <Typography fontSize="28px">🫢</Typography>
                <Typography fontSize="18px" fontWeight="500">
                    Oops! No contacts yet.
                </Typography>
            </Stack>
        );
    }
    return (
        <TableContainer component={TableContactsContainer}>
            <Table sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: '0px 4px' }} stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <ContactsTableHeader>Name</ContactsTableHeader>
                        </TableCell>
                        <TableCell>
                            <ContactsTableHeader>Email</ContactsTableHeader>
                        </TableCell>
                        <TableCell>
                            <ContactsTableHeader>Phone</ContactsTableHeader>
                        </TableCell>
                        <TableCell>
                            <ContactsTableHeader>Status</ContactsTableHeader>
                        </TableCell>
                        <TableCell sx={{ pr: 0 }}>
                            <ContactsTableHeader>Last activity</ContactsTableHeader>
                        </TableCell>
                        <TableCell sx={{ pl: 0 }} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contacts.map((row, index) => {
                        let status = '';
                        if (row.emailStep > row.smsStep) {
                            status = `email-step-${row.emailStep}`;
                        } else {
                            status = `sms-step-${row.smsStep}`;
                        }
                        if (row.surveyOpened) {
                            status = 'viewed';
                        }
                        if (row.reviewRedirected) {
                            status = 'positive';
                        }
                        if (row.feedbackSent) {
                            status = 'negative';
                        }

                        return (
                            <TableRow
                                key={row._id ?? index}
                                sx={{
                                    borderRadius: '16px',
                                    border: '1px solid transparent',
                                    '&:hover': { outline: '1px solid #E8E9F2 !important' },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.firstName} {row.lastName}
                                </TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.phone}</TableCell>
                                <TableCell>{getStatusElement(status)}</TableCell>
                                <TableCell sx={{ pr: 0 }}>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        {row.lastActivityType === 'email' ? <EmailIcon /> : <MessageIcon />}
                                        {moment(row.lastActivity).fromNow()}
                                    </Stack>
                                </TableCell>
                                <TableCell sx={{ pl: 0 }}>
                                    <TableActions onDelete={() => onDelete(row)} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const getStatusElement = (status: string) => {
    if (status === 'email-step-1') {
        return (
            <LoadingContainer $size="sm" $textDark>
                <CircularProgress size={16} color="info" /> Email 1
            </LoadingContainer>
        );
    }
    if (status === 'email-step-2') {
        return (
            <LoadingContainer $size="sm" $textDark>
                <CircularProgress size={16} color="info" /> Email 2
            </LoadingContainer>
        );
    }
    if (status === 'sms-step-1') {
        return (
            <LoadingContainer $size="sm" $textDark>
                <CircularProgress size={16} color="info" /> SMS 1
            </LoadingContainer>
        );
    }
    if (status === 'sms-step-2') {
        return (
            <LoadingContainer $size="sm" $textDark>
                <CircularProgress size={16} color="info" /> SMS 2
            </LoadingContainer>
        );
    }
    if (status === 'negative') {
        return (
            <ErrorContainer $size="sm">
                <WarningIcon /> Negative review submitted
            </ErrorContainer>
        );
    }
    if (status === 'positive') {
        return (
            <SuccessContainer $size="sm">
                <SuccessIcon />
                Positive review submitted
            </SuccessContainer>
        );
    }

    if (status === 'viewed') {
        return (
            <LoadingContainer $size="sm" $textDark>
                <VisibleIcon /> Review form opened
            </LoadingContainer>
        );
    }
    return <></>;
};
