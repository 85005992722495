import React from 'react';
import { SortSelector } from '../../components/sort-selector/sort-selector.component';
import { SortOption } from '../../types/sort.enum';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { LayoutSwitcherRoot } from './reviews-head.styles';
import { ReviewsFilterType } from './reviews-filter.component';
import { ReactComponent as ListIcon } from '../../assets/list.svg';
import { ReactComponent as CardsIcon } from '../../assets/cards.svg';
import { ShowHiddenInput } from '../../components/show-hidden-input/show-hidden-input.component';

export const ReviewsHeader = ({
    sortValue,
    onSortChange,
    filter,
    onFilterChange,
    layout,
    onLayoutChange,
}: {
    sortValue: SortOption;
    onSortChange: (value: SortOption) => void;
    filter?: ReviewsFilterType;
    onFilterChange?: (value: ReviewsFilterType) => void;
    layout: string;
    onLayoutChange: (value: string) => void;
}) => {
    return (
        <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap={2}>
                <SortSelector value={sortValue} onChange={onSortChange} />
                <ShowHiddenInput
                    value={filter.showHidden}
                    onChange={(value) => {
                        onFilterChange({
                            ...filter,
                            showHidden: value,
                        });
                    }}
                />
            </Stack>
            <LayoutSwitcherRoot>
                <IconButton
                    sx={{ '& path': { fill: layout === 'list' && '#06070D' } }}
                    onClick={() => {
                        onLayoutChange('list');
                    }}
                >
                    <ListIcon />
                </IconButton>
                <IconButton
                    sx={{ '& path': { fill: layout === 'cards' && '#06070D' } }}
                    onClick={() => {
                        onLayoutChange('cards');
                    }}
                >
                    <CardsIcon />
                </IconButton>
            </LayoutSwitcherRoot>
        </Stack>
    );
};
