import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import './common.css';
import { PageRoot } from './components/common.styles';
import { SettingsType } from './types/settings.type';
import { themeMui, themeStyledComponents } from './theme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { WidgetSetupPage } from './pages/widget-setup-page/widget-setup-page';
import { SignupPage } from './pages/signup-page/signup-page.component';
import { ProfilePage } from './pages/profile-page/profile-page.component';
import { UserPopulatedType } from './types/user-data.type';
import mockData from './mock-data.json';
import { ReviewsPreviewType } from './types/review-data.type';
import Confetti from 'react-confetti';
import { useWindowSize } from './utils/useWindowSize';
import { IntegrationGuideModal } from './components/integration-guide-modal/integration-guide.modal';
import { LayoutType } from './types/layout-type.enum';
import { DEBUG_TOKEN, DEFAULT_WIDGET_TITLE } from './constants';

const searchParams = new URLSearchParams(document.location.search);
const integrationId = searchParams.get('integration_id');

// localStorage.setItem('access_token', DEBUG_TOKEN);

function App() {
    const [googleCompanyId, setGoogleCompanyId] = useState('');
    const [yelpAlias, setYelpAlias] = useState('');
    const [facebookAlias, setFacebookAlias] = useState('');
    const [trustpilotAlias, setTrustpilotAlias] = useState('');
    const [settings, setSettings] = useState<SettingsType>({
        minRating: 4,
        showSummary: true,
        autoplay: true,
        isDark: false,
        showTitle: true,
        title: DEFAULT_WIDGET_TITLE,
        layout: LayoutType.carousel,
        aiHighlight: false,
    });
    const [showSignUpPage, setShowSignUpPage] = useState(true);
    const [showIntegrationPage, setShowIntegrationPage] = useState(false);
    const [userPopulatedData, setUserPopulatedData] = useState<UserPopulatedType>();
    const [previewData, setPreviewData] = useState<ReviewsPreviewType>(mockData);
    const [runConfetti, setRunConfetti] = useState(false);
    const { width, height } = useWindowSize();
    const [isIntegrationDialogOpen, setIsIntegrationDialogOpen] = useState(!!integrationId);

    const onPreviewDataChanged = (data: ReviewsPreviewType) => {
        if (data) {
            setRunConfetti(true);
            setPreviewData(data);
        }
    };

    const onLoginClicked = useCallback(() => {
        setShowSignUpPage(true);
    }, []);

    const onLogoutClicked = useCallback(() => {
        localStorage.removeItem('access_token');
        setShowIntegrationPage(false);
        setShowSignUpPage(false);
    }, []);

    useEffect(() => {
        document.addEventListener('login', onLoginClicked);
        document.addEventListener('logout', onLogoutClicked);
        return () => {
            window.removeEventListener('login', onLoginClicked);
            window.removeEventListener('logout', onLogoutClicked);
        };
    }, []);

    const showWidgetSetupPage = !showSignUpPage && !showIntegrationPage;

    return (
        <StyledThemeProvider theme={themeStyledComponents}>
            <ThemeProvider theme={themeMui}>
                <Confetti
                    style={{ zIndex: '20' }}
                    run={runConfetti}
                    recycle={false}
                    numberOfPieces={400}
                    width={width}
                    height={height}
                    onConfettiComplete={(confetti) => {
                        setRunConfetti(false);
                        confetti.reset();
                    }}
                />
                <PageRoot $limitWidth={showWidgetSetupPage}>
                    {showWidgetSetupPage && (
                        <WidgetSetupPage
                            googleCompanyId={googleCompanyId}
                            onGoogleCompanyIdChange={setGoogleCompanyId}
                            yelpAlias={yelpAlias}
                            onYelpAliasChange={setYelpAlias}
                            facebookAlias={facebookAlias}
                            onFacebookAliasChange={setFacebookAlias}
                            trustpilotAlias={trustpilotAlias}
                            onTrustpilotAliasChange={setTrustpilotAlias}
                            settings={settings}
                            onSettingsChange={setSettings}
                            onSubmit={() => {
                                setShowSignUpPage(true);
                            }}
                            onLoginClicked={onLoginClicked}
                            previewData={previewData}
                            onPreviewDataChange={onPreviewDataChanged}
                        />
                    )}
                    {showSignUpPage && (
                        <SignupPage
                            googleCompanyId={googleCompanyId}
                            yelpAlias={yelpAlias}
                            settings={settings}
                            facebookAlias={facebookAlias}
                            trustpilotAlias={trustpilotAlias}
                            onSuccess={(data) => {
                                setUserPopulatedData(data);
                                setSettings({ ...data.user.settings, title: data.user.settings.title ?? DEFAULT_WIDGET_TITLE });
                                setShowIntegrationPage(true);
                                setShowSignUpPage(false);
                                document.dispatchEvent(new Event('login-success'));
                            }}
                        />
                    )}
                    {showIntegrationPage && (
                        <ProfilePage
                            userPopulatedData={userPopulatedData}
                            onUserPopulatedDataChange={(data) => {
                                setUserPopulatedData(data);
                            }}
                            settings={settings}
                            onSettingsChange={setSettings}
                            onLogout={() => {
                                localStorage.removeItem('access_token');
                                setShowIntegrationPage(false);
                                setShowSignUpPage(false);
                            }}
                            onTriggerConfetti={() => {
                                setRunConfetti(true);
                            }}
                        />
                    )}
                    <IntegrationGuideModal
                        open={isIntegrationDialogOpen}
                        onClose={() => {
                            setIsIntegrationDialogOpen(false);
                        }}
                        userId={integrationId}
                    />
                    {/*<VideoRecordingComponent />*/}
                </PageRoot>
            </ThemeProvider>
        </StyledThemeProvider>
    );
}

export default App;
