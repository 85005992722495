import styled from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

export const StyledFormGroup = styled(FormGroup)`
    display: flex;
    flex-direction: column;
`;

export const PaperWrapper = styled.div<{
    $row?: boolean;
}>`
    background-color: ${({ theme }) => theme.colors.settingsSectionSubPaper};
    padding: 12px 16px;
    border-radius: 16px;
    ${({ $row }) => ($row ? 'display: flex; align-items: center; justify-content: space-between;' : '')};
    position: relative;
`;

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    //flex-grow: 1;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    flex-grow: 1;

    @media screen and (max-width: 900px) {
        flex-direction: column;
        flex-wrap: wrap;

        > div {
            flex: 1 1 47%;
        }
    }
`;

export const Label = styled(FormControlLabel)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin: 0;
    white-space: nowrap;
`;

export const ResponsiveSettingsWrapper = styled.div<{ $expanded?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    overflow: hidden;
    flex-grow: 1;
    margin-top: 32px;
    transition: all 0.25s linear;

    @media screen and (max-width: 900px) {
        > div {
            flex: 1 1 47%;
        }
    }

    @media screen and (max-width: 900px) {
        //display: none;
        ${({ $expanded }) => ($expanded ? 'max-height: 700px; margin-top: 32px;' : 'max-height: 0px; margin-top: 0px;')};
    }
`;

export const MoreOptionsButton = styled.button<{ $expanded: boolean }>`
    background: transparent;
    padding: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    font: inherit;
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    font-weight: 500;
    margin-top: 32px;
    cursor: pointer;

    @media screen and (min-width: 900px) {
        display: none;
    }

    svg {
        transition: transform 0.25s linear;
        transform: rotate(${({ $expanded }) => ($expanded ? '180deg' : '0deg')});
    }
`;

export const ResponsiveRowOptions = styled.div`
    display: flex;
    gap: 4px;
    > div {
        flex: 1 1 50%;
        min-width: calc(50% - 2px);
    }
`;
