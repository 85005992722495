import styled from 'styled-components';
import { ReviewCardRoot } from '../review-card/review-card.styles';

export const Root = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 24px;
    overflow: hidden;
`;

export const Column = styled.div<{ $text?: boolean }>`
    border-radius: 24px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    flex: 1 1 50%;
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: scroll;
    max-height: 90vh;

    ${({ $text }) => $text && 'max-width: 500px'};

    ${ReviewCardRoot} {
        width: 100%;
    }
`;

export const Title = styled.div`
    font-family: 'CabinetGrotesk', 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
`;
