import React, { useMemo } from 'react';
import {
    FacebookSummaryLogo,
    GoogleSummaryLogo,
    MultipleRoot,
    NumbersWrapper,
    RatingText,
    RatingWrapper,
    SummaryRoot,
    TotalText,
    TrustpilotSummaryLogo,
    YelpSummaryLogo,
} from './reviews-summary.styles';
import { ReviewSource } from '../../types/review-source.enum';
import { ReactComponent as StarIcon } from '../../assets/star-full.svg';

interface PropsPreview {
    rating: number;
    ratingsTotal: number;
    googleCompanyId?: string;
    yelpAlias?: string;
    trustpilotAlias?: string;
    facebookAlias?: string;
}

export const ReviewsSummaryPreview = (props: PropsPreview) => {
    if (props.yelpAlias) {
        return (
            <MultipleRoot>
                <ReviewsSummaryPlatformWidget source={ReviewSource.yelp} {...props} />
            </MultipleRoot>
        );
    }
    if (props.trustpilotAlias) {
        return (
            <MultipleRoot>
                <ReviewsSummaryPlatformWidget source={ReviewSource.trustpilot} {...props} />
            </MultipleRoot>
        );
    }
    if (props.facebookAlias) {
        return (
            <MultipleRoot>
                <ReviewsSummaryPlatformWidget source={ReviewSource.facebook} {...props} />
            </MultipleRoot>
        );
    }
    if (props.googleCompanyId) {
        return (
            <MultipleRoot>
                <ReviewsSummaryPlatformWidget source={ReviewSource.google} {...props} />
            </MultipleRoot>
        );
    }
};

export interface SummaryStats {
    googleRating: number;
    googleRatingsTotal: number;
    googleCompanyId?: string;

    yelpRating?: number;
    yelpRatingsTotal?: number;
    yelpAlias?: string;

    facebookRating?: number;
    facebookRatingsTotal?: number;
    facebookAlias?: string;

    trustpilotRating?: number;
    trustpilotRatingsTotal?: number;
    trustpilotAlias?: string;

    marginSmall?: boolean;
}

export const ReviewsSummaryUser = (props: SummaryStats) => {
    return <MultiplePlatformsSummary {...props} />;
};

const ReviewsSummaryPlatformWidget = ({
    ratingsTotal,
    rating,
    googleCompanyId,
    yelpAlias,
    source,
    trustpilotAlias,
    facebookAlias,
}: PropsPreview & {
    source: ReviewSource;
}) => {
    const openGoogleUrl = () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${googleCompanyId}`);
    };
    const openYelpUrl = () => {
        window.open(`https://www.yelp.com/biz/${yelpAlias}`);
    };

    const openTrustpilotUrl = () => {
        window.open(`https://www.trustpilot.com/review/${trustpilotAlias}`);
    };

    const openFacebookUrl = () => {
        window.open(`https://www.facebook.com/${facebookAlias}`);
    };

    const metadata = useMemo(() => {
        if (source === ReviewSource.yelp) {
            return { callback: openYelpUrl, logo: <YelpSummaryLogo onClick={openYelpUrl} /> };
        } else if (source === ReviewSource.google) {
            return { callback: openGoogleUrl, logo: <GoogleSummaryLogo onClick={openGoogleUrl} /> };
        } else if (source === ReviewSource.trustpilot) {
            return { callback: openTrustpilotUrl, logo: <TrustpilotSummaryLogo onClick={openTrustpilotUrl} /> };
        } else if (source === ReviewSource.facebook) {
            return { callback: openFacebookUrl, logo: <FacebookSummaryLogo onClick={openFacebookUrl} /> };
        }
    }, [source]);

    return (
        <SummaryRoot>
            {metadata.logo}
            <NumbersWrapper>
                <RatingWrapper onClick={metadata.callback}>
                    <StarIcon /> <RatingText>{rating}</RatingText>
                </RatingWrapper>
                <TotalText>{ratingsTotal}</TotalText>
            </NumbersWrapper>
        </SummaryRoot>
    );
};

const MultiplePlatformsSummary = (props: SummaryStats) => {
    return (
        <MultipleRoot $marginSmall={props.marginSmall}>
            {props.googleCompanyId && (
                <>
                    <ReviewsSummaryPlatformWidget
                        rating={props.googleRating}
                        ratingsTotal={props.googleRatingsTotal}
                        source={ReviewSource.google}
                        googleCompanyId={props.googleCompanyId}
                    />
                </>
            )}
            {props.yelpAlias && (
                <>
                    <ReviewsSummaryPlatformWidget
                        rating={props.yelpRating}
                        ratingsTotal={props.yelpRatingsTotal}
                        source={ReviewSource.yelp}
                        yelpAlias={props.yelpAlias}
                    />
                </>
            )}
            {props.facebookAlias && (
                <>
                    <ReviewsSummaryPlatformWidget
                        rating={props.facebookRating}
                        ratingsTotal={props.facebookRatingsTotal}
                        source={ReviewSource.facebook}
                        facebookAlias={props.facebookAlias}
                    />
                </>
            )}
            {props.trustpilotAlias && (
                <>
                    <ReviewsSummaryPlatformWidget
                        rating={props.trustpilotRating}
                        ratingsTotal={props.trustpilotRatingsTotal}
                        source={ReviewSource.trustpilot}
                        trustpilotAlias={props.trustpilotAlias}
                    />
                </>
            )}
        </MultipleRoot>
    );
};

export default ReviewsSummaryUser;
