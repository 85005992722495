import React from 'react';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { SERVER_URL } from '../../constants';
import { InputIconWrapper, StyledFileInput } from '../../components/common.styles';
import { ReactComponent as PlusIcon } from '../../assets/share.svg';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';
import { LogoInputPreviewWrapper } from './collect-section.styles';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

export const LogoUploadComponent = ({ onLogoUrlChange, value }: { onLogoUrlChange: (url) => void; value: string }) => {
    const [file, setFile] = React.useState<File | null>(null);
    const [logoUrl, setLogoUrl] = React.useState<string | null>(value);
    const [isLoading, setIsLoading] = React.useState(false);
    const fileInput = React.useRef<HTMLInputElement>(null);

    const logoChange = async (logoFile: File) => {
        setFile(logoFile);
        setLogoUrl(URL.createObjectURL(logoFile));
        onLogoUrlChange(URL.createObjectURL(logoFile));
        await onSave(logoFile);
    };

    const onSave = async (file?: File) => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('access_token');
            if (token) {
                const formData = new FormData();
                formData.append('file', file);
                await axios.post(`${SERVER_URL}/user/logo`, formData, {
                    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
                });
            }
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };

    const hasLogo = logoUrl;

    return (
        <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
                <StyledFileInput
                    inputRef={fileInput}
                    disabled={isLoading}
                    size="medium"
                    placeholder="Upload logo"
                    value={file}
                    onChange={logoChange}
                    InputProps={{
                        inputProps: {
                            accept: 'image/*',
                        },
                        endAdornment: (
                            <LogoInputPreviewWrapper>
                                {hasLogo && <img src={logoUrl} alt="logo" />}
                                <InputIconWrapper
                                    onClick={() => {
                                        if (!isLoading) {
                                            if (hasLogo) {
                                                setFile(null);
                                                setLogoUrl(null);
                                                onLogoUrlChange('');
                                                onSave();
                                            } else {
                                                fileInput.current?.click();
                                            }
                                        }
                                    }}
                                >
                                    {isLoading ? <CircularProgress size={24} /> : hasLogo ? <TrashIcon /> : <PlusIcon />}
                                </InputIconWrapper>
                            </LogoInputPreviewWrapper>
                        ),
                    }}
                />
            </Stack>
        </Stack>
    );
};
