import styled from 'styled-components';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { MuiFileInput } from 'mui-file-input';
import LoadingButton from '@mui/lab/LoadingButton';

export const PageRoot = styled.div<{ $profile?: boolean; $limitWidth?: boolean }>`
    background: transparent;
    width: 100%;
    max-width: ${({ $limitWidth }) => ($limitWidth ? '1750px' : 'unset')};
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: ${({ $profile }) => ($profile ? '0px' : '48px')};
    position: relative;
    z-index: 10;
    @media (max-width: 768px) {
        gap: 40px;
    }
`;

export const LoginButton = styled(Button).attrs({ children: 'Login', size: 'medium', variant: 'outlined' })`
    position: absolute;
    top: 4px;
    right: 4px;
    /*    @media screen and (max-width: 550px) {
      top: 4px;
      right: 4px;
    }*/
`;

export const LogoutButton = styled(Button).attrs({ children: 'Logout' })`
    position: absolute;
    top: 8px;
    right: 8px;
`;

export const PaperRoot = styled(Paper)`
    box-shadow: 0 0.75em 1.5em -0.25em #919eab1f, 0 0 0.125em 0 #919eab33;
    width: 440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    padding-top: 16px;
    gap: 1em;
    margin-top: 16px;
    border-radius: 8px;

    @media screen and (max-width: 550px) {
        width: 380px;
    }

    @media screen and (max-width: 400px) {
        width: 360px;
    }
`;

export const WidgetWrapper = styled.div<{ $mt?: boolean; $sidebar?: boolean; $centerCorrection?: boolean }>`
    background: ${({ theme }) => theme.colors.bg};
    box-shadow: 0 0.75em 1.5em -0.25em #919eab1f, 0 0 0.125em 0 #919eab33;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 30px;
    padding: 40px 0;
    width: ${({ $sidebar }) => ($sidebar ? 'calc(100% - 350px - 100px)' : `calc(100% - 358px)`)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${({ $mt }) => $mt && 'margin-top: 16px;'};
    ${({ $centerCorrection }) => $centerCorrection && 'padding-bottom: 70px;'};

    @media screen and (max-width: 900px) {
        width: 100%;
        padding-bottom: 40px;
    }
`;

export const SideLayout = styled.div`
    display: flex;
    width: 100%;
    padding: 8px;
    gap: 8px;
    border-radius: 38px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 100%);

    background-blend-mode: overlay;
    @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
        width: 100%;
        padding: 0;
        background: none;
        gap: 16px;
    }
`;

export const SideBarRoot = styled.div<{ $rounded?: boolean }>`
    height: auto;
    width: 350px;
    margin: 0 auto;
    flex-shrink: 0;
    padding: 16px;

    display: flex;
    flex-direction: column;

    background-color: ${({ theme }) => theme.colors.settingsSectionPaper};
    ${({ $rounded }) => $rounded && 'border-radius: 30px;'};
    box-shadow: 0 0.75em 1.5em -0.25em #919eab1f, 0 0 0.125em 0 #919eab33;
    border: 1px solid ${({ theme }) => theme.colors.border};

    @media screen and (max-width: 900px) {
        width: 100%;
    }
`;

export const SectionDashRoot = styled.div`
    width: calc(100% - 260px - 24px);
    padding: 24px 24px 24px 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 900px) {
        width: 100%;
    }
`;

export const SectionContentWrapper = styled.div<{ $fullHeight?: boolean }>`
    width: 100%;
    overflow: hidden;
    height: ${({ $fullHeight }) => ($fullHeight ? '100%' : 'auto')};
    position: relative;
`;

export const SectionTitle = styled.div`
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.96px;
`;

export const SectionDescription = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.64px;
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-top: 16px;
`;

export const StyledFileInput = styled(MuiFileInput)`
    input {
        padding: 12px 14px;
        padding-right: 0;
    }

    .MuiFileInput-placeholder {
        color: ${({ theme }) => theme.colors.text} !important;
        font-weight: 500;
    }

    fieldset {
        transition: all 0.25s linear;
        border-color: ${({ theme }) => theme.colors.border};
    }

    * {
        cursor: pointer;
    }

    .MuiInputBase-root {
        padding-left: 16px;
        padding-right: 8px;
    }

    .MuiInputAdornment-root {
        display: none;
    }

    path {
        transition: all 0.25s linear;
    }

    &:hover {
        fieldset {
            border-color: ${({ theme }) => theme.colors.activeBorderColor} !important;
        }
    }
    label > span {
        max-width: 250px;
    }
`;

export const SubsectionWrapper = styled.div`
    padding: 16px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.bg};
`;

export const SubsectionTitle = styled.div<{ $margin?: boolean }>`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.64px;
    margin-bottom: ${({ $margin }) => ($margin ? '24px' : '0')};
`;

export const InputIconWrapper = styled(Button)`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.25s linear;
    border-radius: 100%;
    cursor: pointer;
    padding: 0 !important;
    min-width: unset;

    path {
        transition: fill 0.1s linear;
    }

    &:hover {
        background: rgba(74, 20, 255, 0.05);
        path {
            fill: ${({ theme }) => theme.colors.activeBorderColor} !important;
        }
    }
`;

export const ModalTitle = styled.p`
    color: ${({ theme }) => theme.colors.text};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.72px;
    margin: 0;
`;

export const BodyText = styled(Typography).attrs({ variant: 'body2', color: 'textPrimary', letterSpacing: '-0.28px' })``;

export const PinButton = styled(LoadingButton)<{ $active?: boolean }>`
    path {
        fill: ${({ $active, theme }) => ($active ? '#fff' : theme.colors.text)};
    }
`;
