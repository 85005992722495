import React, { useState } from 'react';
import { CountDown, RecordingButton, RecordingCanvas, VideoContainer } from './video-form.styles';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Cam, DevicesSelector } from './devices-selector.component';
import RecorderService from '../components/video-recording/recorder.service';

interface Props {
    accentColor: string;
    onRecorded: (video: Blob) => void;
}

export const VideoRecording = ({ accentColor, onRecorded }: Props) => {
    const [permissionsGranted, setPermissionsGranted] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

    const startRecording = () => {
        startCountdown();
        RecorderService.startRecording();
    };

    const stopRecording = async () => {
        setCountdown(0);
        stopCountdown();
        const blob = await RecorderService.stopRecording();
        onRecorded(blob);
    };

    const requestPermissions = async () => {
        await RecorderService.requestPermissions();
        setPermissionsGranted(true);
    };

    const startCountdown = () => {
        const id = setInterval(() => {
            setCountdown((prev) => prev + 1);
        }, 1000);
        setIntervalId(id);
    };

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes < 10 ? `0${minutes}` : minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
    };

    const stopCountdown = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    };
    return (
        <>
            <VideoContainer>
                <RecordingCanvas />
                {!permissionsGranted ? (
                    <Stack gap={3} alignItems="center">
                        <Typography sx={{ fontSize: '16px', color: '#fff' }}>Your camera and microphone is off</Typography>
                        <Stack direction="row" alignItems="center" gap={2}>
                            {BlockedMic}
                            {BlockedCam}
                        </Stack>
                        <Button
                            variant="contained"
                            fullWidth
                            size="medium"
                            onClick={requestPermissions}
                            sx={{
                                color: '#fff',
                                backgroundColor: `${accentColor}9F`,
                                transition: 'all 0.1s',
                                '&:hover': { backgroundColor: `${accentColor}9F`, opacity: 0.7 },
                            }}
                        >
                            Turn on microphone and camera
                        </Button>
                    </Stack>
                ) : (
                    <Stack
                        height="100%"
                        direction="column"
                        alignItems="center"
                        justifyContent="space-between"
                        zIndex={1}
                        sx={{ p: 2 }}
                    >
                        {isRecording ? <CountDown id="video-countdown">{formatTime(countdown)}</CountDown> : <div />}
                        <RecordingButton
                            color="error"
                            variant="contained"
                            onClick={() => {
                                const newValue = !isRecording;
                                setIsRecording(newValue);
                                if (newValue) {
                                    startRecording();
                                } else {
                                    stopRecording();
                                }
                            }}
                            $recording={isRecording}
                        >
                            {Cam}
                        </RecordingButton>
                    </Stack>
                )}
            </VideoContainer>
            <DevicesSelector permissionsGranted={permissionsGranted} />
        </>
    );
};

const BlockedMic = (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="48" height="48" rx="24" fill="#CC5029" fillOpacity="0.12" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34 8C34 4.41 36.91 1.5 40.5 1.5C44.09 1.5 47 4.41 47 8C47 11.59 44.09 14.5 40.5 14.5C36.91 14.5 34 11.59 34 8ZM40.5 5.5C40.6326 5.5 40.7598 5.55268 40.8536 5.64645C40.9473 5.74022 41 5.86739 41 6V8.5C41 8.63261 40.9473 8.75979 40.8536 8.85355C40.7598 8.94732 40.6326 9 40.5 9C40.3674 9 40.2402 8.94732 40.1464 8.85355C40.0527 8.75979 40 8.63261 40 8.5V6C40 5.86739 40.0527 5.74022 40.1464 5.64645C40.2402 5.55268 40.3674 5.5 40.5 5.5ZM40.5 11C40.6326 11 40.7598 10.9473 40.8536 10.8536C40.9473 10.7598 41 10.6326 41 10.5C41 10.3674 40.9473 10.2402 40.8536 10.1464C40.7598 10.0527 40.6326 10 40.5 10C40.3674 10 40.2402 10.0527 40.1464 10.1464C40.0527 10.2402 40 10.3674 40 10.5C40 10.6326 40.0527 10.7598 40.1464 10.8536C40.2402 10.9473 40.3674 11 40.5 11Z"
            fill="#CC5029"
        />
        <path
            d="M17.6828 17.1828C17.8 17.0657 17.9588 17 18.1245 17C18.2901 17 18.4489 17.0657 18.5661 17.1828L31.0661 29.6828C31.1275 29.74 31.1768 29.809 31.2109 29.8857C31.2451 29.9623 31.2635 30.0451 31.2649 30.129C31.2664 30.2129 31.251 30.2963 31.2196 30.3741C31.1881 30.4519 31.1413 30.5226 31.082 30.582C31.0226 30.6413 30.9519 30.6881 30.8741 30.7196C30.7963 30.751 30.7129 30.7664 30.629 30.7649C30.5451 30.7635 30.4623 30.7451 30.3857 30.7109C30.309 30.6768 30.24 30.6275 30.1828 30.5661L17.6828 18.0661C17.5657 17.9489 17.5 17.7901 17.5 17.6244C17.5 17.4588 17.5657 17.3 17.6828 17.1828Z"
            fill="#CC5029"
        />
        <path
            d="M28.875 23.375V24.625C28.875 25.0577 28.8109 25.4837 28.688 25.8904L29.6606 26.8631C29.9645 26.1624 30.125 25.4013 30.125 24.625V23.375C30.125 23.2092 30.0592 23.0503 29.9419 22.9331C29.8247 22.8158 29.6658 22.75 29.5 22.75C29.3342 22.75 29.1753 22.8158 29.0581 22.9331C28.9408 23.0503 28.875 23.2092 28.875 23.375Z"
            fill="#CC5029"
        />
        <path
            d="M19.9419 22.9331C19.8247 22.8158 19.6658 22.75 19.5 22.75C19.3342 22.75 19.1753 22.8158 19.0581 22.9331C18.9408 23.0503 18.875 23.2092 18.875 23.375V24.625C18.875 26.0087 19.385 27.3438 20.3074 28.3752C21.2298 29.4066 22.4999 30.0619 23.875 30.2158V32.125H21.375C21.2092 32.125 21.0503 32.1908 20.9331 32.3081C20.8158 32.4253 20.75 32.5842 20.75 32.75C20.75 32.9158 20.8158 33.0747 20.9331 33.1919C21.0503 33.3092 21.2092 33.375 21.375 33.375H27.625C27.7908 33.375 27.9497 33.3092 28.0669 33.1919C28.1842 33.0747 28.25 32.9158 28.25 32.75C28.25 32.5842 28.1842 32.4253 28.0669 32.3081C27.9497 32.1908 27.7908 32.125 27.625 32.125H25.125V30.2158C25.997 30.1182 26.8268 29.819 27.5525 29.3501L26.6421 28.4397C25.9936 28.8038 25.2569 29 24.5 29C23.3397 29 22.2269 28.5391 21.4064 27.7186C20.5859 26.8981 20.125 25.7853 20.125 24.625V23.375C20.125 23.2092 20.0592 23.0503 19.9419 22.9331Z"
            fill="#CC5029"
        />
        <path
            d="M22.2903 26.8347C22.8763 27.4208 23.6712 27.75 24.5 27.75C24.9193 27.75 25.3298 27.6657 25.7091 27.5066L21.375 23.1725V24.625C21.375 25.4538 21.7042 26.2487 22.2903 26.8347Z"
            fill="#CC5029"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.2903 15.5403C21.7042 16.1263 21.375 16.9212 21.375 17.75V18.5775L27.6188 24.8213C27.6229 24.7561 27.625 24.6907 27.625 24.625V17.75C27.625 16.9212 27.2958 16.1263 26.7097 15.5403C26.1237 14.9542 25.3288 14.625 24.5 14.625C23.6712 14.625 22.8763 14.9542 22.2903 15.5403Z"
            fill="#CC5029"
        />
    </svg>
);

const BlockedCam = (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="48" height="48" rx="24" fill="#CC5029" fillOpacity="0.12" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34 8C34 4.41 36.91 1.5 40.5 1.5C44.09 1.5 47 4.41 47 8C47 11.59 44.09 14.5 40.5 14.5C36.91 14.5 34 11.59 34 8ZM40.5 5.5C40.6326 5.5 40.7598 5.55268 40.8536 5.64645C40.9473 5.74022 41 5.86739 41 6V8.5C41 8.63261 40.9473 8.75979 40.8536 8.85355C40.7598 8.94732 40.6326 9 40.5 9C40.3674 9 40.2402 8.94732 40.1464 8.85355C40.0527 8.75979 40 8.63261 40 8.5V6C40 5.86739 40.0527 5.74022 40.1464 5.64645C40.2402 5.55268 40.3674 5.5 40.5 5.5ZM40.5 11C40.6326 11 40.7598 10.9473 40.8536 10.8536C40.9473 10.7598 41 10.6326 41 10.5C41 10.3674 40.9473 10.2402 40.8536 10.1464C40.7598 10.0527 40.6326 10 40.5 10C40.3674 10 40.2402 10.0527 40.1464 10.1464C40.0527 10.2402 40 10.3674 40 10.5C40 10.6326 40.0527 10.7598 40.1464 10.8536C40.2402 10.9473 40.3674 11 40.5 11Z"
            fill="#CC5029"
        />
        <path
            d="M15.8083 17.3078C15.9255 17.1907 16.0843 17.125 16.2499 17.125C16.4156 17.125 16.5744 17.1907 16.6916 17.3078L29.1916 29.8078C29.253 29.865 29.3023 29.934 29.3364 30.0107C29.3706 30.0873 29.389 30.1701 29.3904 30.254C29.3919 30.3379 29.3765 30.4213 29.345 30.4991C29.3136 30.5769 29.2668 30.6476 29.2075 30.707C29.1481 30.7663 29.0774 30.8131 28.9996 30.8446C28.9218 30.876 28.8384 30.8914 28.7545 30.8899C28.6706 30.8885 28.5878 30.8701 28.5112 30.8359C28.4345 30.8018 28.3655 30.7525 28.3083 30.6911L15.8083 18.1911C15.6912 18.0739 15.6255 17.9151 15.6255 17.7495C15.6255 17.5838 15.6912 17.425 15.8083 17.3078ZM29.3749 27.3828L31.6166 29.6244C32.4033 30.412 33.7499 29.8545 33.7499 28.7411V19.2578C33.7499 18.1444 32.4033 17.587 31.6166 18.3744L29.3749 20.6161V27.3828ZM28.1249 20.2494V26.9728L18.9016 17.7495H25.6249C26.288 17.7495 26.9239 18.0128 27.3927 18.4817C27.8615 18.9505 28.1249 19.5864 28.1249 20.2494ZM16.2499 27.7494V20.4011L26.0608 30.2119C25.9191 30.2369 25.7733 30.2494 25.6249 30.2494H18.7499C18.0869 30.2494 17.451 29.9861 16.9822 29.5172C16.5133 29.0484 16.2499 28.4125 16.2499 27.7494Z"
            fill="#CC5029"
        />
    </svg>
);
