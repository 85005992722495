import Stack from '@mui/material/Stack/Stack';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

export const PlanWrapper = styled.div`
    flex: 1 1 25%;
    max-width: 40%;
    min-width: 360px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const LightPlanContainer = styled.div<{ $logoGrey?: boolean }>`
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.bg};
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    flex-shrink: 0;

    overflow: hidden;
    height: fit-content;
    ${({ $logoGrey }) => $logoGrey && `path {fill: #CDCED6};`}
`;

const Bullet = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.primary};
`;

export const BulletRow = ({ color = '#000', label = '' }: { color?: string; label?: string }) => (
    <Stack direction="row" alignItems="center" gap={1}>
        <Bullet />
        <Typography color={color} fontSize="14px" fontWeight={500}>
            {label}
        </Typography>
    </Stack>
);

export const FeaturesContainer = styled.div`
    background: ${({ theme }) => theme.colors.bg};
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.border};
`;

export const BillingLabel = styled.div`
    color: #e5e5ff;
    font-family: 'CabinetGrotesk', 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

export const PricingText = styled.div<{ $dark?: boolean }>`
    font-family: 'CabinetGrotesk', 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -1.6px;
    color: ${({ theme, $dark }) => ($dark ? theme.colors.text : theme.colors.bg)};
`;

export const PricingTextCrossed = styled(PricingText)`
    color: #6756a0;
    text-decoration: line-through;
    font-weight: 400;
`;

export const PerText = styled.div`
    font-family: 'CabinetGrotesk', 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.64px;
    color: ${({ theme }) => theme.colors.inactiveGrey};
    line-height: 25px;
`;
