import styled from 'styled-components';
import { ReactComponent as GLogo } from '../../assets/google.svg';
import { ReactComponent as YLogo } from '../../assets/yelp.svg';
import { ReactComponent as FLogo } from '../../assets/facebook.svg';
import { ReactComponent as TLogo } from '../../assets/trustpilot.svg';
import { defaultAccentColor } from '../../constants';

export const ReviewCardRoot = styled.div`
    margin: auto;
    padding: 8px;
    font-family: 'CabinetGrotesk', 'Inter', sans-serif;
`;

export const Content = styled.div<{ $hideBorder?: boolean; $noPadding?: boolean }>`
    width: 100%;
    background: ${({ theme }) => theme.colors.cardBackground};
    border-radius: 16px;
    padding: ${({ $noPadding }) => ($noPadding ? '0' : '16px')};
    border: ${({ theme, $hideBorder }) => !$hideBorder && `1px solid ${theme.colors.border}`};
    box-shadow: 0px 45px 12px 0px rgba(0, 0, 0, 0), 0px 29px 11px 0px rgba(0, 0, 0, 0), 0px 16px 10px 0px rgba(0, 0, 0, 0.01),
        0px 7px 7px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 1em;
    line-height: 1.5em;
    flex: 1;
    font-weight: normal;
    letter-spacing: normal;
    text-align: left;
    min-height: 280px;
    height: fit-content;
    position: relative;
`;

export const AvatarImage = styled.img<{ $round?: boolean }>`
    display: block;
    width: 2.5em;
    height: 2.5em;
    object-fit: cover;
    margin-right: 12px;
    cursor: pointer;
    ${({ $round }) => $round && 'border-radius: 50%;'}
`;

export const AvatarNameLetter = styled.div<{ $bgColor?: string; $textColor?: string }>`
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    object-fit: cover;
    background: ${({ $bgColor }) => $bgColor};
    color: ${({ $textColor }) => $textColor};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 12px;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    line-height: 17px;
`;

export const ReviewUserName = styled.div`
    font-size: 15px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.text};
    &:hover {
        text-decoration: underline;
    }
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const NameWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`;

export const NameTextWrapper = styled.span<{ $light?: boolean; $bold?: boolean }>`
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 20px;
    color: ${({ theme, $light }) => ($light ? theme.colors.bg : theme.colors.text)};
    font-weight: ${({ $bold }) => ($bold ? '700' : '500')};
`;

export const ReviewCountLabel = styled.div`
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 20px;
    letter-spacing: -0.28px;
    font-weight: 450;
`;

export const HeaderTextWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
`;

export const RatingContainer = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const DateText = styled.span<{ $light?: boolean }>`
    color: ${({ theme, $light }) => ($light ? theme.colors.bg : theme.colors.textSecondary)};
    margin-left: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: -0.3px;
    font-size: 15px;
    font-weight: 450;
`;

export const MainText = styled.span`
    line-height: 20px;
    color: ${({ theme }) => theme.colors.text};
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    letter-spacing: -0.28px;
    max-height: 100px;
    transition: max-height 0.25s ease-in-out;
    font-weight: 460;
    font-size: 16px;
`;

export const Link = styled.span<{ $accentColor?: string }>`
    font-weight: 500;
    padding: 8px 15px;
    font-size: 14px;
    color: ${({ $accentColor }) => $accentColor || defaultAccentColor};
    border-radius: 999px;
    cursor: pointer;
    letter-spacing: -0.28px;
    background: ${({ $accentColor }) => ($accentColor ? `${$accentColor}0F` : `${defaultAccentColor}0F`)};
    transition: opacity 0.25s;
    height: fit-content;

    &:hover {
        opacity: 0.8;
    }
`;

export const GoogleLogo = styled(GLogo)`
    margin-left: auto;
    width: 1.6em;
    height: 1.6em;
    cursor: pointer;
    transition: 0.2s opacity ease-in-out;
    &:hover {
        opacity: 0.7;
    }
`;

export const YelpLogo = styled(YLogo)`
    margin-left: auto;
    width: 1.6em;
    height: 1.6em;
    cursor: pointer;
    transition: 0.2s opacity ease-in-out;
    &:hover {
        opacity: 0.7;
    }
`;

export const FacebookLogo = styled(FLogo)`
    margin-left: auto;
    width: 1.6em;
    height: 1.6em;
    cursor: pointer;
    transition: 0.2s opacity ease-in-out;
    &:hover {
        opacity: 0.7;
    }
`;

export const TrustPilotLogo = styled(TLogo)`
    margin-left: auto;
    width: 1.6em;
    height: 1.6em;
    cursor: pointer;
    transition: 0.2s opacity ease-in-out;
    &:hover {
        opacity: 0.7;
    }
`;

export const CardFooterWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
`;

export const ReviewImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 6px;
    transition: all 0.15s ease-in-out;
    pointer-events: none;
    z-index: 1000;
    object-fit: cover;
`;

export const ReviewImageWrapper = styled.div<{ $index: number; $length: number }>`
    width: 40px;
    height: 40px;
    flex-shrink: 0;

    cursor: pointer;

    &:hover {
        ${ReviewImage} {
            border-radius: 3px;
            transform: scale(5) translate(${({ $index, $length }) => `${($length - $index - 1) * 6 - 14}px`}, -25px);
        }
    }
`;

export const ReviewImageLockedWrapper = styled.div<{ $index: number; $length: number }>`
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    position: relative;
    margin-left: -32px;
    ${ReviewImage} {
        border-radius: 6px;
    }
    &:hover {
        ${ReviewImage} {
            border-radius: 3px;
            transform: scale(5) translate(${({ $index, $length }) => `${($length - $index - 1) * 6 - 14}px`}, -25px);
        }
    }
`;

export const VideoPlayContainer = styled.div`
    cursor: pointer;
    padding: 3px 0px 2px 4px;

    &:hover {
        svg {
            opacity: 0.8;
        }
    }
    path {
        fill: ${({ theme }) => theme.colors.bg};
    }
`;

export const VideoReviewContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
    display: flex;

    &:hover {
        ${VideoPlayContainer} {
            svg {
                opacity: 0.8;
            }
        }
    }
    &:before {
        content: '';
        position: absolute;
        pointer-events: none;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 16px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 80.2%, rgba(0, 0, 0, 0.3) 100%),
            linear-gradient(270deg, rgba(0, 0, 0, 0) 80.2%, rgba(0, 0, 0, 0.3) 100%),
            linear-gradient(90deg, rgba(0, 0, 0, 0) 79.6%, rgba(0, 0, 0, 0.3) 100%),
            linear-gradient(180deg, rgba(0, 0, 0, 0) 70.35%, rgba(0, 0, 0, 0.5) 90.45%);
    }
`;
export const StyledVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 50vh;
    min-height: 300px;
`;

export const VideoControlsContainer = styled.div`
    position: absolute;
    width: calc(100% - 32px);
    left: 16px;
    bottom: 16px;
`;

export const LockContainer = styled.div<{ $radius: number; $border?: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(6, 7, 13, 0.5);
    backdrop-filter: blur(2px);
    ${({ $border, theme }) => $border && `border: 1px solid ${theme.colors.border}`};
    border-radius: ${({ $radius }) => `${$radius}px`};
    overflow: hidden;
    path {
        fill: #fff;
    }
`;
