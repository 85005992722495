import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { ContentWrapper } from '../instruction-dialog.styles';

export const GodaddyArticle = () => (
    <>
        <DialogTitle textAlign="left" align="center" sx={{ p: 0, mt: 3, fontSize: '18px' }}>
            How to add widget to GoDaddy website
        </DialogTitle>
        <ContentWrapper>
            <Typography>
                Log in to your GoDaddy Account, select <strong>My Products</strong>, and choose the website you'd like to edit:
            </Typography>
            <img src="/instructions/godaddy-1.png" alt="" />
            <Typography>Select Edit Website:</Typography>
            <img src="/instructions/godaddy-2.png" alt="" />
            <Typography>
                Select the page and spot where you'd like to add the widget and click on <strong>Add Section</strong>:
            </Typography>
            <img src="/instructions/godaddy-3.gif" alt="" />
            <Typography>
                In the list on the right select <strong>Files & Web → HTML → Embed custom code on the page</strong>:
            </Typography>
            <img src="/instructions/godaddy-4.png" alt="" />
            <Typography>
                Paste your widget installation code to the <strong>Custom Code</strong> field:
            </Typography>
            <img src="/instructions/godaddy-5.png" alt="" />
            <Typography>Change its height or center align if needed:</Typography>
            <img src="/instructions/godaddy-6.png" alt="" />
            <Typography>
                <strong>Click Done:</strong>
            </Typography>
            <img src="/instructions/godaddy-7.png" alt="" />
            <Typography>You have successfully installed the widget on your GoDaddy website!</Typography>
        </ContentWrapper>
    </>
);
