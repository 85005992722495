import styled from 'styled-components';

export const DotsWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.45s cubic-bezier(0.6, 0.6, 0, 1);

    @keyframes button-stars {
        0% {
            transform: translateZ(0) translateY(0);
        }
        100% {
            transform: translateZ(0) translateY(-200px);
        }
    }

    @keyframes button-stars-small {
        0% {
            transform: translateZ(0) translateY(10px);
        }
        100% {
            transform: translateZ(0) translateY(-40px);
        }
    }
`;

export const Dots1 = styled.div`
    width: 1px;
    height: 1px;
    animation: button-stars 50s linear infinite;
    box-shadow: 320px 138px #fff, 301px 83px #fff, 290px 172px #fff, 47px 59px #fff, 290px 137px #fff, 3px 285px #fff,
        184px 90px #fff, 137px 228px #fff, 91px 84px #fff, 69px 308px #fff, 236px 349px #fff, 25px 283px #fff, 313px 117px #fff,
        147px 3px #fff, 352px 5px #fff, 226px 201px #fff, 25px 33px #fff, 276px 115px #fff, 223px 294px #fff, 97px 29px #fff,
        307px 209px #fff, 329px 167px #fff, 259px 142px #fff, 225px 29px #fff, 147px 310px #fff, 259px 107px #fff, 293px 51px #fff,
        306px 171px #fff, 12px 26px #fff, 272px 63px #fff, 6px 15px #fff, 179px 281px #fff, 192px 211px #fff, 311px 175px #fff,
        109px 49px #fff, 297px 37px #fff, 100px 311px #fff, 352px 206px #fff, 152px 234px #fff, 175px 109px #fff, 322px 98px #fff,
        334px 345px #fff, 88px 336px #fff, 358px 7px #fff, 205px 69px #fff, 267px 93px #fff, 229px 273px #fff, 205px 83px #fff,
        11px 179px #fff, 237px 53px #fff, 82px 92px #fff, 5px 14px #fff, 328px 340px #fff, 88px 281px #fff, 163px 94px #fff,
        45px 29px #fff, 199px 100px #fff, 80px 239px #fff, 232px 204px #fff, 128px 301px #fff, 73px 305px #fff, 228px 195px #fff,
        184px 279px #fff, 304px 53px #fff, 204px 324px #fff, 338px 229px #fff, 28px 6px #fff, 298px 295px #fff, 28px 119px #fff,
        61px 140px #fff, 184px 334px #fff, 236px 113px #fff, 283px 41px #fff, 224px 94px #fff, 346px 230px #fff, 298px 231px #fff,
        250px 238px #fff, 350px 253px #fff, 235px 71px #fff, 201px 192px #fff, 94px 281px #fff, 163px 204px #fff, 19px 298px #fff,
        257px 342px #fff, 317px 359px #fff, 1px 317px #fff, 252px 255px #fff, 258px 156px #fff, 190px 238px #fff, 154px 137px #fff,
        282px 112px #fff, 169px 147px #fff, 182px 193px #fff, 63px 259px #fff, 85px 78px #fff, 297px 14px #fff, 252px 276px #fff,
        36px 76px #fff, 320px 258px #fff, 123px 45px #fff;
`;
export const Dots1Small = styled.div`
    width: 1px;
    height: 1px;
    animation: button-stars-small 10s linear infinite;
    box-shadow: 5px 10px #fff, 12px 5px #fff, 10px 15px #fff, 8px 20px #fff, 14px 25px #fff, 10px 30px #fff, 5px 35px #fff,
        2px 40px #fff, 12px 45px #fff;
`;

export const Dots2 = styled.div`
    width: 1px;
    height: 1px;
    box-shadow: 145px 346px #fff, 248px 132px #fff, 190px 105px #fff, 263px 126px #fff, 283px 318px #fff, 329px 63px #fff,
        22px 181px #fff, 89px 220px #fff, 278px 51px #fff, 353px 208px #fff, 40px 286px #fff, 14px 282px #fff, 293px 207px #fff,
        153px 73px #fff, 118px 197px #fff, 173px 177px #fff, 262px 14px #fff, 319px 324px #fff, 174px 118px #fff, 355px 84px #fff,
        192px 244px #fff, 319px 8px #fff, 107px 33px #fff, 21px 153px #fff, 142px 133px #fff, 7px 182px #fff, 352px 180px #fff,
        21px 354px #fff, 80px 127px #fff, 254px 335px #fff, 159px 204px #fff, 169px 271px #fff, 124px 199px #fff, 208px 272px #fff,
        173px 141px #fff, 252px 70px #fff, 171px 161px #fff, 77px 151px #fff, 188px 1px #fff, 288px 21px #fff, 281px 12px #fff,
        79px 140px #fff, 188px 102px #fff, 30px 290px #fff, 290px 129px #fff, 110px 153px #fff, 158px 139px #fff, 194px 246px #fff,
        201px 160px #fff, 177px 38px #fff, 153px 339px #fff, 261px 38px #fff, 86px 185px #fff, 262px 106px #fff, 270px 60px #fff,
        275px 290px #fff, 92px 283px #fff, 44px 299px #fff, 67px 337px #fff, 191px 321px #fff, 95px 233px #fff, 319px 121px #fff,
        165px 7px #fff, 114px 264px #fff, 261px 356px #fff, 309px 78px #fff, 181px 263px #fff, 316px 271px #fff, 37px 190px #fff,
        61px 143px #fff, 183px 113px #fff, 252px 206px #fff, 248px 174px #fff, 57px 168px #fff, 293px 161px #fff, 88px 83px #fff,
        168px 280px #fff, 163px 170px #fff, 14px 141px #fff, 159px 28px #fff, 136px 186px #fff, 316px 228px #fff, 358px 23px #fff,
        36px 245px #fff, 237px 110px #fff, 288px 111px #fff, 49px 218px #fff, 135px 129px #fff, 78px 52px #fff, 81px 194px #fff,
        24px 232px #fff, 39px 311px #fff, 38px 131px #fff, 160px 318px #fff, 318px 186px #fff, 38px 179px #fff, 293px 48px #fff,
        29px 155px #fff, 179px 156px #fff, 325px 31px #fff;
    animation: button-stars 30s linear infinite;
    filter: blur(1px);
`;

export const Dots2Small = styled.div`
    width: 1px;
    height: 1px;
    animation: button-stars-small 7s linear infinite;
    box-shadow: 5px 10px #fff, 12px 5px #fff, 10px 15px #fff, 8px 20px #fff, 14px 25px #fff, 10px 30px #fff, 5px 35px #fff,
        2px 40px #fff, 12px 45px #fff;
    filter: blur(1px);
`;
