import React, { useState } from 'react';
import { ProfilePlatformsComponent } from '../profile-platforms/profile-platforms.component';
import { UserPopulatedType } from '../../types/user-data.type';
import { ReviewsPreviewType } from '../../types/review-data.type';
import { SidebarStarsFilter } from '../sidebar-stars-filter/sidebar-stars-filter.component';
import { SettingsType } from '../../types/settings.type';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { FilterWrapperContainer, SettingWrapper } from './profile-widget-settings.styles';
import axios from 'axios';
import { SERVER_URL } from '../../constants';
import { ProfileLayoutSettings } from '../profile-layout-settings/profile-layout-settings.component';
import { AccentColorSelector } from '../accent-color-selector/accent-color-selector.component';
import { Switch } from '../switch/switch.component';
import { Label, PaperWrapper } from '../widget-settings/widget-settings.styles';
import { BodyText } from '../common.styles';
import { LayoutType } from '../../types/layout-type.enum';
import { ReactComponent as CodeIcon } from '../../assets/code.svg';
import { LockIcon } from '../lock-icon.component';
import { PaidFeatureModal } from '../paid-feature-modal/paid-feature.modal';

interface Props {
    userPopulatedData: UserPopulatedType;
    onPreviewDataChange: (previewData?: ReviewsPreviewType) => void;
    onUserPopulatedDataChange: (data: UserPopulatedType) => void;
    platformPreviewData: ReviewsPreviewType;
    settings: SettingsType;
    onSettingsChange: (settings: SettingsType) => void;
    onIntegrationOpen: () => void;
    isProPlan: boolean;
    onUpgrade: () => void;
}

export const ProfileWidgetSettings = ({
    userPopulatedData,
    onPreviewDataChange,
    onUserPopulatedDataChange,
    platformPreviewData,
    settings,
    onSettingsChange,
    onIntegrationOpen,
    isProPlan,
    onUpgrade,
}: Props) => {
    const [showPaidFeatureModal, setShowPaidFeatureModal] = useState(false);
    const onSettingsSave = async (value: SettingsType) => {
        if (userPopulatedData.user.email) {
            try {
                onSettingsChange(value);
                const token = localStorage.getItem('access_token');
                await axios.patch(
                    `${SERVER_URL}/user`,
                    {
                        email: userPopulatedData.user.email,
                        settings: value,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
            } catch (e) {
                console.error(e);
            }
        }
    };

    return (
        <Stack gap={3}>
            <Button
                size="medium"
                variant="contained"
                startIcon={<CodeIcon />}
                sx={{ borderRadius: '9999px' }}
                onClick={onIntegrationOpen}
            >
                Integration guide
            </Button>
            <ProfilePlatformsComponent
                platformPreviewData={platformPreviewData}
                onPreviewDataChange={onPreviewDataChange}
                userPopulatedData={userPopulatedData}
                onUserPopulatedDataChange={onUserPopulatedDataChange}
                isProPlan={isProPlan}
                onPaidFeatureOpen={() => setShowPaidFeatureModal(true)}
            />
            <FilterWrapperContainer>
                <SidebarStarsFilter
                    value={settings.minRating}
                    onChange={(minRating) => {
                        onSettingsSave({
                            ...settings,
                            minRating,
                        });
                    }}
                />
            </FilterWrapperContainer>
            <ProfileLayoutSettings
                value={settings.layout}
                onChange={(layout) => {
                    onSettingsSave({
                        ...settings,
                        layout,
                    });
                }}
            />
            <TextField
                id="title-input"
                size="medium"
                variant="outlined"
                placeholder="What our customers say"
                label={'Title'}
                fullWidth
                // sx={{ '& .MuiInputBase-root': { background: '#fff' } }}
                value={settings.title}
                onChange={(e) => {
                    onSettingsSave({
                        ...settings,
                        title: e.target.value,
                    });
                }}
            />
            <Stack gap={1}>
                <Stack direction="row" gap={1}>
                    <SettingWrapper>
                        <AccentColorSelector
                            value={settings.accentColor}
                            onChange={(color) => {
                                onSettingsSave({
                                    ...settings,
                                    accentColor: color,
                                });
                            }}
                        />
                    </SettingWrapper>
                    <SettingWrapper>
                        <PaperWrapper $row>
                            <Label
                                label={<BodyText sx={{ marginRight: '9px' }}>Dark Mode</BodyText>}
                                control={
                                    <Switch
                                        checked={settings.isDark || false}
                                        onChange={(e) => {
                                            onSettingsSave({
                                                ...settings,
                                                isDark: e.target.checked,
                                            });
                                        }}
                                    />
                                }
                            />
                        </PaperWrapper>
                    </SettingWrapper>
                </Stack>
                <SettingWrapper>
                    <PaperWrapper $row>
                        <Label
                            label={<BodyText sx={{ marginRight: '9px' }}>Show Overall Reviews</BodyText>}
                            control={
                                <Switch
                                    stars
                                    checked={settings.showSummary}
                                    onChange={(e) => {
                                        onSettingsSave({
                                            ...settings,
                                            showSummary: e.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </PaperWrapper>
                </SettingWrapper>
                <SettingWrapper>
                    <PaperWrapper $row>
                        <Label
                            label={<BodyText sx={{ marginRight: '9px' }}>Show Widget Title</BodyText>}
                            control={
                                <Switch
                                    stars
                                    checked={settings.showTitle}
                                    onChange={(e) => {
                                        onSettingsSave({
                                            ...settings,
                                            showTitle: e.target.checked,
                                        });
                                    }}
                                />
                            }
                        />
                    </PaperWrapper>
                </SettingWrapper>
                {settings.layout === LayoutType.carousel && (
                    <SettingWrapper>
                        <PaperWrapper $row>
                            <Label
                                label={<BodyText sx={{ marginRight: '9px' }}>Auto-Slide</BodyText>}
                                control={
                                    <Switch
                                        stars
                                        checked={settings.autoplay}
                                        onChange={(e) => {
                                            onSettingsSave({
                                                ...settings,
                                                autoplay: e.target.checked,
                                            });
                                        }}
                                    />
                                }
                            />
                        </PaperWrapper>
                    </SettingWrapper>
                )}
                <SettingWrapper>
                    <Tooltip
                        placement="top-end"
                        PopperProps={{
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [10, -15],
                                    },
                                },
                            ],
                        }}
                        disableHoverListener={isProPlan}
                        title={
                            <Typography fontSize="14px" fontWeight="500">
                                PRO Feature
                            </Typography>
                        }
                    >
                        <PaperWrapper $row>
                            <Label
                                label={<BodyText sx={{ marginRight: '9px' }}>Ai Highlight</BodyText>}
                                onClick={() => {
                                    !isProPlan && setShowPaidFeatureModal(true);
                                }}
                                control={
                                    <Stack direction="row" gap={1}>
                                        <Switch
                                            disabled={!isProPlan}
                                            stars
                                            checked={settings.aiHighlight}
                                            onChange={(e) => {
                                                onSettingsSave({
                                                    ...settings,
                                                    aiHighlight: e.target.checked,
                                                });
                                            }}
                                        />
                                        {!isProPlan && <LockIcon size={23} />}
                                    </Stack>
                                }
                            />
                        </PaperWrapper>
                    </Tooltip>
                </SettingWrapper>
            </Stack>
            <PaidFeatureModal open={showPaidFeatureModal} onClose={() => setShowPaidFeatureModal(false)} onUpgrade={onUpgrade} />
        </Stack>
    );
};
