import React, { useMemo, useState } from 'react';
import { ReviewsContainer, ReviewsDisplayWrapper, ReviewsSectionHeaderWrapper } from './reviews-section.styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReviewType } from '../../types/review.type';
import { ReviewsSectionCard } from './reviews-section-card.component';
import { ReviewsFilter, ReviewsFilterType } from './reviews-filter.component';
import { ReviewSource } from '../../types/review-source.enum';
import { ReviewsSummaryUser, SummaryStats } from '../../components/reviews-summary/reviews-summary.component';
import { SettingsType } from '../../types/settings.type';
import axios from 'axios';
import { SERVER_URL } from '../../constants';
import { UserPopulatedType } from '../../types/user-data.type';
import { pinSortNegative, pinSortNewest, pinSortOldest, pinSortPositive } from '../../utils/pinSort';
import { SectionContentWrapper, SectionDashRoot, SectionDescription, SectionTitle } from '../../components/common.styles';
import { getAvailableUserPlatforms } from '../../utils/getAvailableUserPlatforms';
import { ReviewsHeader } from './reviews-header.component';
import { SortOption } from '../../types/sort.enum';

interface Props {
    summaryInfo: SummaryStats;
    settings: SettingsType;
    onUserPopulatedDataChange: (data: UserPopulatedType) => void;
    userPopulatedData: UserPopulatedType;
}

export const ReviewsSection = ({ summaryInfo, settings, onUserPopulatedDataChange, userPopulatedData }: Props) => {
    const [pinnedItemId, setPinnedItemId] = useState<string | null>();
    const [filter, setFilter] = useState<ReviewsFilterType>({
        sources: getAvailableUserPlatforms(userPopulatedData),
        rating: [5, 4, 3, 2, 1],
        showHidden: true,
    });
    const [sort, setSort] = useState<SortOption>(SortOption.newest);
    const [layout, setLayout] = useState('cards');

    const sortFunction = useMemo(() => {
        switch (sort) {
            case SortOption.newest:
                return pinSortNewest;
            case SortOption.oldest:
                return pinSortOldest;
            case SortOption.positive:
                return pinSortPositive;
            case SortOption.negative:
                return pinSortNegative;
        }
    }, [sort]);

    const visibleFilter = (el: ReviewType) => (el.rating >= settings.minRating && el.forceState !== -1) || el.forceState === 1;
    const hiddenFilter = (el: ReviewType) => (el.rating < settings.minRating || el.forceState === -1) && el.forceState !== 1;

    const currentVisibilityFilter = filter.showHidden ? () => true : visibleFilter;

    const filteredItems = useMemo(
        () =>
            userPopulatedData.reviews
                .filter((el) => filter.sources.includes(el.source as ReviewSource) && filter.rating.includes(el.rating))
                .filter(currentVisibilityFilter)
                .sort(sortFunction),
        [userPopulatedData.reviews, filter, pinnedItemId, sortFunction]
    );

    const togglePin = async (review: ReviewType) => {
        const token = localStorage.getItem('access_token');
        const newValue = !review.pin;
        await axios.patch(
            `${SERVER_URL}/reviews`,
            { id: review._id, pin: newValue },
            { headers: { Authorization: `Bearer ${token}` } }
        );
        review.pin = newValue;
        setPinnedItemId(newValue ? review._id : null);
    };

    const isCardsLayout = layout === 'cards';

    return (
        <SectionDashRoot>
            <SectionContentWrapper>
                <ReviewsSectionHeaderWrapper>
                    <div>
                        <SectionTitle>My reviews</SectionTitle>
                        <SectionDescription>Hide, pin, explore your reviews</SectionDescription>
                    </div>
                    <ReviewsSummaryUser {...summaryInfo} />
                </ReviewsSectionHeaderWrapper>
                <ReviewsDisplayWrapper>
                    <ReviewsFilter value={filter} onChange={setFilter} userPopulatedData={userPopulatedData} />
                    <Stack gap={3} flexGrow={1}>
                        <ReviewsHeader
                            sortValue={sort}
                            onSortChange={setSort}
                            filter={filter}
                            onFilterChange={setFilter}
                            layout={layout}
                            onLayoutChange={setLayout}
                        />
                        <ReviewsContainer $cards={isCardsLayout}>
                            {filteredItems.map((el) => (
                                <ReviewsSectionCard
                                    key={el._id}
                                    item={el}
                                    minRating={settings.minRating}
                                    onPin={() => togglePin(el)}
                                    isCardsLayout={isCardsLayout}
                                />
                            ))}
                            {!filteredItems.length && (
                                <Typography
                                    sx={{
                                        my: 2,
                                        mx: 'auto',
                                        width: 'fit-content',
                                    }}
                                    variant="h5"
                                >
                                    No reviews found
                                </Typography>
                            )}
                        </ReviewsContainer>
                    </Stack>
                </ReviewsDisplayWrapper>
            </SectionContentWrapper>
        </SectionDashRoot>
    );
};
