import React from 'react';
import { ReactComponent as WidgetLogo } from '../../assets/widget.svg';
import { ReactComponent as StarIcon } from '../../assets/star-full.svg';
import { ReactComponent as InboxIcon } from '../../assets/inbox.svg';
import { ReactComponent as VideoIcon } from '../../assets/video.svg';
import { ReactComponent as PersonIcon } from '../../assets/person.svg';
import { ReactComponent as CardIcon } from '../../assets/card.svg';
import { SideButton, SideRoot } from './side-bar.styles';
import { VerifiedIcon } from '../../assets/verified';
import { Logo } from '../logo/logo.component';

export const SideBarComponent = ({ value, onChange }: { value: string; onChange: (value: string) => void }) => {
    const changeTab = (value: string) => {
        history.pushState({}, '', `/${value}`);
        onChange(value);
    };

    return (
        <SideRoot>
            <Logo />
            <SideButton $active={value === 'widget'} onClick={() => changeTab('widget')}>
                <WidgetLogo />
                <span>Widget</span>
            </SideButton>
            <SideButton $active={value === 'reviews'} onClick={() => changeTab('reviews')}>
                <StarIcon />
                <span>Reviews</span>
            </SideButton>
            <SideButton $active={value === 'builder'} onClick={() => changeTab('builder')}>
                <VerifiedIcon />
                <span>Reputation builder</span>
            </SideButton>
            <SideButton $active={value === 'requests'} onClick={() => changeTab('requests')}>
                <InboxIcon />
                <span>Review requests</span>
            </SideButton>
            <SideButton $active={value === 'video'} onClick={() => changeTab('video')}>
                <VideoIcon />
                <span>Video reviews</span>
            </SideButton>
            <SideButton $active={value === 'account'} onClick={() => changeTab('account')}>
                <CardIcon />
                <span>Billing</span>
            </SideButton>
        </SideRoot>
    );
};
