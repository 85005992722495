import React from 'react';
import { PaperWrapper } from '../widget-settings/widget-settings.styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactComponent as StarIcon } from '../../assets/star-full-small.svg';
import { Root } from './sidebar-stars-filter.styles';

interface Props {
    value?: number;
    onChange?: (value: number) => void;
}
export const SidebarStarsFilter = ({ value, onChange }: Props) => {
    return (
        <PaperWrapper $row>
            <Typography sx={{ marginRight: '9px' }} variant={'body2'} color="textPrimary">
                Filter Reviews
            </Typography>
            <Root>
                <Stack direction="row-reverse" spacing={'4px'}>
                    <StarIcon className={value >= 5 ? 'active' : ''} onClick={() => onChange(5)} />
                    <StarIcon className={value >= 4 ? 'active' : ''} onClick={() => onChange(4)} />
                    <StarIcon className={value >= 3 ? 'active' : ''} onClick={() => onChange(3)} />
                    <StarIcon className={value >= 2 ? 'active' : ''} onClick={() => onChange(2)} />
                    <StarIcon className={value >= 1 ? 'active' : ''} onClick={() => onChange(1)} />
                </Stack>
            </Root>
        </PaperWrapper>
    );
};
