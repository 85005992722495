import React, { useState } from 'react';
import { PlayButton, RatingContainer, Root, StyledVideo, VideoContainer } from './video-card.styles';
import { ReactComponent as PlayIcon } from '../../assets/play.svg';
import Stack from '@mui/material/Stack/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment from 'moment-mini';
import { ReactComponent as StarFull } from '../../assets/star-full.svg';
import { ReactComponent as StarEmpty } from '../../assets/star-empty.svg';
import { PinButton } from '../../components/common.styles';
import { ReactComponent as PinIcon } from '../../assets/pin.svg';
import { ReactComponent as LinkIcon } from '../../assets/link.svg';
import { ReactComponent as DownloadIcon } from '../../assets/download.svg';
import { ReactComponent as DeleteIcon } from '../../assets/trash.svg';
import { VisibleButton } from '../../components/visible-button/visible-button.component';
import { ReviewType } from '../../types/review.type';
import axios from 'axios';
import { SERVER_URL } from '../../constants';

interface Props {
    item: ReviewType;
    minRating: number;
    onPlay: () => void;
    onPin: () => Promise<void>;
    onDelete: () => void;
}

export const VideoCard = ({ item, minRating, onPlay, onPin, onDelete }: Props) => {
    const [isVisible, setIsVisible] = useState(item.forceState === 1 || (item.rating >= minRating && item.forceState !== -1));
    const [isPinLoading, setIsPinLoading] = useState(false);

    const toggleVisibility = async () => {
        const token = localStorage.getItem('access_token');
        const newValue = isVisible ? -1 : 1;
        await axios.patch(
            `${SERVER_URL}/reviews`,
            { forceState: newValue, id: item._id },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        item.forceState = newValue;
        setIsVisible(!isVisible);
    };

    return (
        <Root>
            <VideoContainer onClick={onPlay}>
                <StyledVideo src={item.videoUrl} preload="metadata" />
                <PlayButton color="info" onClick={onPlay}>
                    <PlayIcon />
                </PlayButton>
            </VideoContainer>
            <Stack gap={2} sx={{ p: 1 }}>
                <Stack direction="row" alignItems="center" gap={1}>
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: '500' }}>
                        {item.userName}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: '14px', color: '#8A8A99' }}>
                        {moment(item.timestamp / 1000).fromNow()}
                    </Typography>
                </Stack>
                <RatingContainer>
                    {Array(item.rating)
                        .fill(0)
                        .map((_, index) => (
                            <StarFull key={index} />
                        ))}
                    {Array(5 - item.rating)
                        .fill(0)
                        .map((_, index) => (
                            <StarEmpty key={index} />
                        ))}
                </RatingContainer>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack direction="row" gap={2} alignItems="center">
                        <Tooltip
                            placement="top-start"
                            title={
                                <Typography color="white" typography="subtitle1" sx={{ fontSize: '11px' }} textAlign="center">
                                    {item.pin ? 'Remove from showing first' : 'Always keep first'}
                                </Typography>
                            }
                        >
                            <span>
                                <PinButton
                                    $active={item.pin}
                                    loading={isPinLoading}
                                    sx={{
                                        ...buttonStyles,
                                    }}
                                    variant={item.pin ? 'contained' : 'outlined'}
                                    onClick={async () => {
                                        setIsPinLoading(true);
                                        await onPin();
                                        setIsPinLoading(false);
                                    }}
                                >
                                    <PinIcon />
                                </PinButton>
                            </span>
                        </Tooltip>
                        <VisibleButton value={isVisible} onClick={toggleVisibility} styles={buttonStyles} />
                    </Stack>
                    <Stack direction="row" gap={1} alignItems="center">
                        <IconButton
                            sx={{ p: '10px' }}
                            onClick={() => {
                                navigator.clipboard.writeText(item.videoUrl);
                            }}
                        >
                            <LinkIcon />
                        </IconButton>
                        <IconButton
                            sx={{ p: '10px' }}
                            onClick={() => {
                                const link = document.createElement('a');
                                link.href = item.videoUrl;
                                link.download = item.videoUrl.split('/').pop();
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }}
                        >
                            <DownloadIcon />
                        </IconButton>
                        <IconButton sx={{ p: '10px', '& path': { fill: '#000' } }} onClick={onDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                </Stack>
            </Stack>
        </Root>
    );
};

const buttonStyles = {
    width: '40px !important',
    height: '40px !important',
    minWidth: '40px',
    padding: '9px 0 !important',
    '& svg': { margin: '0 !important' },
};
