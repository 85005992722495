import React, { useMemo, useState } from 'react';
import { PreviewVideo, VideoContainer } from './video-form.styles';
import Stack from '@mui/material/Stack/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

interface Props {
    accentColor: string;
    recordedFile: Blob;
    onDelete: () => void;
    onUpload: (name: string) => void;
}

export const VideoPreview = ({ accentColor, recordedFile, onDelete, onUpload }: Props) => {
    const [error, setError] = useState(false);
    const [name, setName] = useState('');

    const src = useMemo(() => URL.createObjectURL(recordedFile), [recordedFile]);
    return (
        <>
            <VideoContainer>
                <PreviewVideo src={src} controls playsInline preload="metadata" />
            </VideoContainer>
            <Stack gap={2}>
                <TextField
                    error={error}
                    onChange={(e) => setName(e.target.value)}
                    label="Full Name"
                    size="small"
                    color="info"
                    variant="outlined"
                    sx={{
                        '& .Mui-focused::before': {
                            border: `2px solid ${accentColor}1F`,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: accentColor, // Set your desired hover color here
                        },
                    }}
                />
                <Stack direction="row" gap={2}>
                    <Button variant="outlined" color="info" startIcon={Trash} sx={{ flex: '1 1 auto' }} onClick={onDelete}>
                        Delete
                    </Button>
                    <Button
                        variant="contained"
                        color="info"
                        startIcon={Upload}
                        onClick={() => {
                            if (!name) {
                                setError(true);
                            } else {
                                setError(false);
                                onUpload(name);
                            }
                        }}
                        sx={{
                            flex: '1 1 auto',
                            color: accentColor,
                            backgroundColor: `${accentColor}1F`,
                            transition: 'all 0.1s',
                            '&:hover': { backgroundColor: `${accentColor}1F`, opacity: 0.7 },
                            '& path': { fill: accentColor },
                        }}
                    >
                        Upload
                    </Button>
                </Stack>
            </Stack>
        </>
    );
};

const Trash = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Trash">
            <g id="Vector">
                <path
                    d="M15.6566 7.79817L14.246 16.1257C14.1271 16.8276 13.5397 17.3394 12.8532 17.3394H7.14679C6.46026 17.3394 5.8729 16.8276 5.75401 16.1257L4.34343 7.79817"
                    fill="#000"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.02778 3.3211C8.59822 3.3211 8.25 3.68256 8.25 4.12844V4.93578H11.75V4.12844C11.75 3.68255 11.4018 3.3211 10.9722 3.3211H9.02778ZM13.0227 4.93578V4.12844C13.0227 2.95294 12.1047 2 10.9722 2H9.02778C7.89531 2 6.97727 2.95293 6.97727 4.12844V4.93578H3.63636C3.28491 4.93578 3 5.23152 3 5.59633C3 5.96114 3.28491 6.25688 3.63636 6.25688H16.3636C16.7151 6.25688 17 5.96114 17 5.59633C17 5.23152 16.7151 4.93578 16.3636 4.93578H13.0227ZM3.58542 7.13761H16.4146L14.8727 16.2401C14.7004 17.2578 13.8487 18 12.8532 18H7.14679C6.1513 18 5.29965 17.2578 5.12726 16.2401L3.58542 7.13761ZM5.10145 8.45872L6.38076 16.0113C6.44615 16.3974 6.76921 16.6789 7.14679 16.6789H12.8532C13.2308 16.6789 13.5538 16.3974 13.6192 16.0114L14.8985 8.45872H5.10145Z"
                    fill="#000"
                />
            </g>
        </g>
    </svg>
);

const Upload = (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.80833 2.05778C9.92552 1.94074 10.0844 1.875 10.25 1.875C10.4156 1.875 10.5745 1.94074 10.6917 2.05778L14.4417 5.80778C14.5521 5.92626 14.6122 6.08297 14.6093 6.24489C14.6065 6.4068 14.5409 6.56129 14.4264 6.6758C14.3118 6.79031 14.1574 6.85591 13.9954 6.85876C13.8335 6.86162 13.6768 6.80152 13.5583 6.69112L10.875 4.00778V13.7495C10.875 13.9152 10.8092 14.0742 10.6919 14.1914C10.5747 14.3086 10.4158 14.3745 10.25 14.3745C10.0842 14.3745 9.92527 14.3086 9.80806 14.1914C9.69085 14.0742 9.625 13.9152 9.625 13.7495V4.00778L6.94167 6.69112C6.82319 6.80152 6.66648 6.86162 6.50456 6.85876C6.34265 6.85591 6.18816 6.79031 6.07365 6.6758C5.95914 6.56129 5.89354 6.4068 5.89069 6.24489C5.88783 6.08297 5.94793 5.92626 6.05833 5.80778L9.80833 2.05778ZM2.75 13.1245C2.91576 13.1245 3.07473 13.1903 3.19194 13.3075C3.30915 13.4247 3.375 13.5837 3.375 13.7495V15.6245C3.375 15.956 3.5067 16.2739 3.74112 16.5083C3.97554 16.7428 4.29348 16.8745 4.625 16.8745H15.875C16.2065 16.8745 16.5245 16.7428 16.7589 16.5083C16.9933 16.2739 17.125 15.956 17.125 15.6245V13.7495C17.125 13.5837 17.1908 13.4247 17.3081 13.3075C17.4253 13.1903 17.5842 13.1245 17.75 13.1245C17.9158 13.1245 18.0747 13.1903 18.1919 13.3075C18.3092 13.4247 18.375 13.5837 18.375 13.7495V15.6245C18.375 16.2875 18.1116 16.9234 17.6428 17.3922C17.1739 17.8611 16.538 18.1245 15.875 18.1245H4.625C3.96196 18.1245 3.32607 17.8611 2.85723 17.3922C2.38839 16.9234 2.125 16.2875 2.125 15.6245V13.7495C2.125 13.5837 2.19085 13.4247 2.30806 13.3075C2.42527 13.1903 2.58424 13.1245 2.75 13.1245Z"
            fill="white"
        />
    </svg>
);
