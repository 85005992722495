import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    BorderAnimContainer,
    ButtonText,
    CreditCardLabel,
    GlowingBoxBorders,
    InputWrapper,
    PlatformImage,
    Root,
    StyledInput,
} from './widget-setup.styles';
import { SERVER_URL } from '../../constants';
import { loadScript } from '../../utils/loadScript';
import { ReviewsPreviewType } from '../../types/review-data.type';
import withToast from '../withToast.hoc';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReviewSource } from '../../types/review-source.enum';
import { CompanySearchStatus } from '../company-search-status/company-search-status.component';
import axios from 'axios';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Typography from '@mui/material/Typography';
import { themeMui, themeStyledComponents } from '../../theme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import GLogo from '../../assets/google.svg';
import YLogo from '../../assets/yelp.svg';
import FLogo from '../../assets/facebook.svg';
import TLogo from '../../assets/trustpilot.svg';
import { SpaceButton } from '../space-button/space-button.component';
import Card from '../../assets/card.svg';

interface Props {
    onChange: (newValue: {
        googleCompanyId?: string;
        yelpAlias?: string;
        facebookAlias?: string;
        trustpilotAlias?: string;
        previewData: ReviewsPreviewType;
    }) => void;
    onSubmit: () => void;
    showToast: (message: string) => void;
    source: ReviewSource;
}

export const WidgetSetupComponent = withToast<Props>(({ onChange, onSubmit, showToast, source }: Props) => {
    const [googleInput, setGoogleInput] = useState(null);
    const [yelpInput, setYelpInput] = useState(null);
    const [facebookInput, setFacebookInput] = useState(null);
    const [trustpilotInput, setTrustpilotInput] = useState(null);
    const init = useRef(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [yelpUrl, setYelpUrl] = useState('');
    const [facebookUrl, setFacebookUrl] = useState('');
    const [trustpilotUrl, setTrustpilotUrl] = useState('');
    const [foundReviewsCount, setFoundReviewsCount] = useState<number>();
    const [isInputFocused, setIsInputFocused] = useState(false);

    const onPlaceIdSubmit = async (place_id: string) => {
        onChange({ googleCompanyId: place_id, previewData: null });
        setIsLoading(true);
        const previewData = await fetch(`${SERVER_URL}/reviews/preview-google?companyId=${place_id}`).then((res) => res.json());
        setIsSubmitDisabled(false);
        setIsLoading(false);
        setFoundReviewsCount(previewData.ratingsTotal);
        onChange({ googleCompanyId: place_id, previewData });
    };

    const initGoogleInput = async () => {
        if (googleInput && !init.current) {
            init.current = true;
            await loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyCri34xsCEVXkxm3hz_Uj9x6WNjmCwAm3U&libraries=places'
            );
            const options = {
                fields: ['place_id'],
                strictBounds: false,
            };
            // @ts-ignore
            const autocomplete = new window.google.maps.places.Autocomplete(googleInput, options);
            autocomplete.addListener('place_changed', async () => {
                try {
                    const place = autocomplete.getPlace();
                    if (place) {
                        const { place_id } = place;
                        await onPlaceIdSubmit(place_id);
                    }
                } catch (e) {
                    console.error(e);
                    showToast('Error while loading reviews for selected company.');
                }
            });
        }
    };

    const searchYelpCompany = async (url: string) => {
        try {
            const parsedUrl = new URL(url);
            const pathname = parsedUrl.pathname;
            const alias = pathname.split('/')[2];
            if (alias) {
                setIsLoading(true);
                const { data } = await axios.get(`${SERVER_URL}/reviews/preview-yelp?alias=${alias}`);
                setIsSubmitDisabled(false);
                setFoundReviewsCount(data.ratingsTotal);
                onChange({ yelpAlias: alias, previewData: data });
            } else {
                setFoundReviewsCount(0);
            }
        } catch (e) {
            setFoundReviewsCount(0);
            setIsSubmitDisabled(true);
        }
        setIsLoading(false);
    };

    const searchFacebookCompany = async (url: string) => {
        try {
            const parsedUrl = new URL(url);
            const pathname = parsedUrl.pathname;
            const alias = pathname.split('/')[1];
            if (alias) {
                setIsLoading(true);
                const { data } = await axios.get(`${SERVER_URL}/reviews/preview-facebook?alias=${alias}`);
                setIsSubmitDisabled(false);
                setFoundReviewsCount(data.ratingsTotal);
                onChange({ facebookAlias: alias, previewData: data });
            } else {
                setFoundReviewsCount(0);
            }
        } catch (e) {
            setFoundReviewsCount(0);
            setIsSubmitDisabled(true);
        }
        setIsLoading(false);
    };

    const searchTrustpilotCompany = async (url: string) => {
        try {
            const parsedUrl = new URL(url);
            const pathname = parsedUrl.pathname;
            const alias = pathname.split('/')[2];
            console.log(alias);
            if (alias) {
                setIsLoading(true);
                const { data } = await axios.get(`${SERVER_URL}/reviews/preview-trustpilot?alias=${alias}`);
                setIsSubmitDisabled(false);
                setFoundReviewsCount(data.ratingsTotal);
                onChange({ trustpilotAlias: alias, previewData: data });
            } else {
                setFoundReviewsCount(0);
            }
        } catch (e) {
            setFoundReviewsCount(0);
            setIsSubmitDisabled(true);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        initGoogleInput();
    }, [googleInput]);

    useEffect(() => {
        setFoundReviewsCount(undefined);
        if (source === ReviewSource.google) {
            googleInput?.focus();
        } else if (source === ReviewSource.yelp) {
            yelpInput?.focus();
        } else if (source === ReviewSource.facebook) {
            facebookInput?.focus();
        } else if (source === ReviewSource.trustpilot) {
            trustpilotInput?.focus();
        }
    }, [source]);

    const iconSrc = useMemo(() => {
        if (source === ReviewSource.google) {
            return GLogo;
        } else if (source === ReviewSource.yelp) {
            return YLogo;
        } else if (source === ReviewSource.facebook) {
            return FLogo;
        } else if (source === ReviewSource.trustpilot) {
            return TLogo;
        }
    }, [source]);

    const createButton = (id: string) => (
        <SpaceButton
            id={id}
            // disabled={isSubmitDisabled}
            loading={isLoading}
            onClick={() => {
                if (!isSubmitDisabled && !isLoading) {
                    onSubmit();
                    // @ts-ignore
                    if (window.gtag) {
                        // @ts-ignore
                        window.gtag('event', 'widget_create');
                    }
                }
            }}
        >
            <ButtonText>Start Now</ButtonText>
        </SpaceButton>
    );

    return (
        <StyledThemeProvider theme={themeStyledComponents}>
            <ThemeProvider theme={themeMui}>
                <Root>
                    <InputWrapper $focus={isInputFocused}>
                        <BorderAnimContainer>
                            <GlowingBoxBorders></GlowingBoxBorders>
                        </BorderAnimContainer>
                        <PlatformImage src={iconSrc} />
                        <StyledInput
                            $visible={source === ReviewSource.google}
                            placeholder={'Search your business name'}
                            disabled={isLoading}
                            ref={(ref) => {
                                // @ts-ignore
                                setGoogleInput(ref);
                            }}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={() => setIsInputFocused(false)}
                        />
                        <StyledInput
                            $visible={source === ReviewSource.yelp}
                            placeholder={'Paste your Yelp URL...'}
                            value={yelpUrl}
                            disabled={isLoading}
                            onChange={(e) => {
                                const value = e.target.value;
                                setYelpUrl(value);
                                searchYelpCompany(value);
                            }}
                            ref={(ref) => {
                                // @ts-ignore
                                setYelpInput(ref);
                            }}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={() => setIsInputFocused(false)}
                        />
                        <StyledInput
                            $visible={source === ReviewSource.facebook}
                            placeholder={'Paste your Facebook URL...'}
                            value={facebookUrl}
                            disabled={isLoading}
                            onChange={(e) => {
                                const value = e.target.value;
                                setFacebookUrl(value);
                                searchFacebookCompany(value);
                            }}
                            ref={(ref) => {
                                // @ts-ignore
                                setFacebookInput(ref);
                            }}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={() => setIsInputFocused(false)}
                        />
                        <StyledInput
                            $visible={source === ReviewSource.trustpilot}
                            placeholder={'Paste your Trustpilot URL...'}
                            value={trustpilotUrl}
                            disabled={isLoading}
                            onChange={(e) => {
                                const value = e.target.value;
                                setTrustpilotUrl(value);
                                searchTrustpilotCompany(value);
                            }}
                            ref={(ref) => {
                                // @ts-ignore
                                setTrustpilotInput(ref);
                            }}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={() => setIsInputFocused(false)}
                        />
                        {createButton('create-widget-desktop')}
                    </InputWrapper>
                    {createButton('create-widget-mobile')}
                    <CompanySearchStatus count={foundReviewsCount} isLoading={isLoading} />
                    {!!foundReviewsCount && (
                        <Typography color="white" align="center">
                            More reviews will be collected after creating the widget...
                        </Typography>
                    )}
                    <CreditCardLabel>
                        <img src={Card} /> No credit card required
                    </CreditCardLabel>
                </Root>
            </ThemeProvider>
        </StyledThemeProvider>
    );
});
