import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { PreviewRoot } from './mapping-select.component';

export const PlatformsFormWrapper = styled.div`
    display: flex;
    margin-bottom: 8px;
    gap: 8px;
    flex-wrap: wrap;
`;

export const FormBuilderLayout = styled.div`
    display: flex;
    gap: 40px;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;
`;

export const FormSettingsWrapper = styled.div`
    max-width: 412px;
`;
export const FormPreviewWrapper = styled.div`
    flex: 1 1 auto;
`;

export const LogoInputPreviewWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    gap: 4px;

    img {
        width: 32px;
        height: 32px;
        object-fit: contain;
        border-radius: 6px;
    }
`;

export const BoostFormControlLabel = styled(FormControlLabel)`
    flex: 1 1 45%;
    border-radius: 12px;
    margin: 0;
    border: 1px solid ${({ theme }) => theme.colors.border};
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    transition: border 0.25s linear;
    padding: 4px 4px 4px 16px;

    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.activeBorderColor};
    }
`;

export const DropAreaContainer = styled.div<{ $isDragging?: boolean }>`
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    border: 1px dashed rgba(104, 51, 255, 0.2);
    width: 430px;
    cursor: pointer;
    transition: background-color 0.1s;

    * {
        pointer-events: none;
    }

    ${({ $isDragging }) => $isDragging && 'background: rgba(104, 51, 255, 0.05)'};

    &:hover {
        background: rgba(104, 51, 255, 0.05);
    }
`;

export const DropTitle = styled.div`
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.56px;

    span {
        color: ${({ theme }) => theme.colors.primary};
    }
`;

export const DropDescription = styled.div`
    color: ${({ theme }) => theme.colors.inactiveGrey};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 100% */
    letter-spacing: -0.24px;
`;

export const DropInput = styled.input`
    display: none;
`;

export const CsvInputLabel = styled(InputLabel)`
    top: -7px !important;
    &.MuiInputLabel-shrink {
        top: 0 !important;
    }
`;

export const MappingFormControl = styled(FormControl)`
    legend span {
        font-size: 12px;
    }

    .MuiSelect-select[aria-expanded='false'] {
        ${PreviewRoot} {
            background: red;
        }
    }
`;

export const ContactsTableHeader = styled.span`
    color: ${({ theme }) => theme.colors.inactiveGrey};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 100% */
    letter-spacing: -0.24px;
    text-transform: uppercase;
`;

export const QrWrapper = styled.div`
    width: 120px;
    height: 120px;
    position: relative;
    overflow: visible;

    canvas {
        width: 100% !important;
        height: 100% !important;
    }
`;

export const TableContactsContainer = styled.div`
    border-radius: 16px;
    border: 1px solid var(--Colors-UI-Stroke, #e8e9f2);
    background: #fff;
    padding-left: 16px;
    padding-right: 16px;
`;
