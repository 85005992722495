import styled from 'styled-components';

export const SectionContainer = styled.div<{ $minWidth?: string }>`
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.cardBackground};
    border: 1px solid ${({ theme }) => theme.colors.border};
    height: fit-content;
    flex: 1 1 calc(30%);
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth};`}
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 133%;
    letter-spacing: -0.72px;
    margin-bottom: 24px;
`;

export const BottomGradient = styled.div`
    width: 100%;
    height: 98px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 72.14%);
    position: absolute;
    left: 0;
    bottom: 0;
`;

export const NotificationContainer = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
`;

export const InboxText = styled.span<{ $weight: number }>`
    color: rgba(36, 17, 89, 0.5);
    font-size: 16px;
    font-style: normal;
    font-weight: ${({ $weight }) => $weight};
    line-height: 16px; /* 100% */
    letter-spacing: -0.64px;
`;

export const AvatarLetter = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.light};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.64px;
    padding: 11px;
`;

export const EmailSender = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.56px;
`;

export const LogoImage = styled.img`
    max-width: 400px;
    width: fit-content;
    height: 48px;
    border-radius: 8px;
`;

export const EmailSubtitle = styled.div`
    color: ${({ theme }) => theme.colors.inactiveGrey};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.56px;
`;

export const EmailFooterText = styled.div<{ $weight: number }>`
    color: ${({ theme }) => theme.colors.inactiveGrey};
    font-size: 12px;
    font-style: normal;
    font-weight: ${({ $weight }) => $weight};
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.48px;
    text-align: center;
`;

export const NextMessageText = styled.div<{ $dark?: boolean; $weight: number; $margin?: string }>`
    color: ${({ theme, $dark }) => ($dark ? 'rgba(36, 17, 89, 0.50)' : theme.colors.bg)};
    font-size: 16px;
    font-style: normal;
    font-weight: ${({ $weight }) => $weight};
    line-height: normal;
    letter-spacing: -0.64px;
    ${({ $margin }) => $margin && `margin: ${$margin}`}
`;
