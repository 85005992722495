import React, { useEffect, useRef, useState } from 'react';
import { ReviewSource } from '../../types/review-source.enum';
import { UserPopulatedType } from '../../types/user-data.type';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { SERVER_URL } from '../../constants';
import withToast from '../withToast.hoc';
import { PlatformsManage } from './platforms-manage.styles';
import { CompanySearchStatus } from '../company-search-status/company-search-status.component';
import axios from 'axios';
import { ReviewsPreviewType } from '../../types/review-data.type';
import { loadScript } from '../../utils/loadScript';

interface Props {
    userPopulatedData: UserPopulatedType;
    showToast: (message: string) => void;
    source: ReviewSource;
    onAdded: (source: ReviewSource, previewData?: ReviewsPreviewType) => void;
}

export const PlatformsManageComponent = withToast<Props>(({ userPopulatedData, showToast, source, onAdded }: Props) => {
    const [googleInput, setGoogleInput] = useState(null);
    const [yelpInput, setYelpInput] = useState(null);
    const [facebookInput, setFacebookInput] = useState(null);
    const [trustpilotInput, setTrustpilotInput] = useState(null);
    const init = useRef(false);
    const [yelpUrl, setYelpUrl] = useState('');
    const [facebookUrl, setFacebookUrl] = useState('');
    const [trustpilotUrl, setTrustpilotUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [foundReviewsCount, setFoundReviewsCount] = useState<number>();
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [newGoogleCompanyId, setNewCompanyId] = useState('');
    const [newYelpAlias, setNewYelpAlias] = useState('');
    const [newFacebookAlias, setNewFacebookAlias] = useState('');
    const [newTrustpilotAlias, setNewTrustpilotAlias] = useState('');
    const [lastFetchedData, setLastFetchedData] = useState<ReviewsPreviewType>();

    const initGoogleInput = async () => {
        if (googleInput && !init.current) {
            init.current = true;
            await loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyCri34xsCEVXkxm3hz_Uj9x6WNjmCwAm3U&libraries=places'
            );
            const options = {
                fields: ['place_id'],
                strictBounds: false,
            };
            // @ts-ignore
            if (window.google.maps) {
                // @ts-ignore
                const autocomplete = new window.google.maps.places.Autocomplete(googleInput, options);
                autocomplete.addListener('place_changed', async () => {
                    try {
                        const place = autocomplete.getPlace();
                        if (place) {
                            const { place_id } = place;
                            setNewCompanyId(place_id);
                            setIsLoading(true);
                            const previewData = await fetch(`${SERVER_URL}/reviews/preview-google?companyId=${place_id}`).then(
                                (res) => res.json()
                            );
                            setLastFetchedData(previewData);
                            setIsLoading(false);
                            setFoundReviewsCount(previewData.ratingsTotal);
                            setIsSubmitDisabled(false);
                        }
                    } catch (e) {
                        console.error(e);
                        showToast('Error while loading reviews for selected company.');
                        setIsSubmitDisabled(true);
                    }
                });
            }
        }
    };

    const searchYelpCompany = async (url: string) => {
        try {
            const parsedUrl = new URL(url);
            const pathname = parsedUrl.pathname;
            const alias = pathname.split('/')[2];
            if (alias) {
                setIsLoading(true);
                const { data } = await axios.get(`${SERVER_URL}/reviews/preview-yelp?alias=${alias}`);
                setIsSubmitDisabled(false);
                setFoundReviewsCount(data.ratingsTotal);
                setLastFetchedData(data);
                setNewYelpAlias(alias);
            } else {
                setFoundReviewsCount(0);
                setIsSubmitDisabled(true);
            }
        } catch (e) {
            setFoundReviewsCount(0);
            setIsSubmitDisabled(true);
        }
        setIsLoading(false);
    };

    const searchFacebookCompany = async (url: string) => {
        try {
            const parsedUrl = new URL(url);
            const pathname = parsedUrl.pathname;
            const alias = pathname.split('/')[1];
            if (alias) {
                setIsLoading(true);
                const { data } = await axios.get(`${SERVER_URL}/reviews/preview-facebook?alias=${alias}`);
                setIsSubmitDisabled(false);
                setFoundReviewsCount(data.ratingsTotal);
                setLastFetchedData(data);
                setNewFacebookAlias(alias);
            } else {
                setFoundReviewsCount(0);
                setIsSubmitDisabled(true);
            }
        } catch (e) {
            setFoundReviewsCount(0);
            setIsSubmitDisabled(true);
        }
        setIsLoading(false);
    };

    const searchTrustpilotCompany = async (url: string) => {
        try {
            const parsedUrl = new URL(url);
            const pathname = parsedUrl.pathname;
            const alias = pathname.split('/')[2];
            if (alias) {
                setIsLoading(true);
                const { data } = await axios.get(`${SERVER_URL}/reviews/preview-trustpilot?alias=${alias}`);
                setIsSubmitDisabled(false);
                setFoundReviewsCount(data.ratingsTotal);
                setLastFetchedData(data);
                setNewTrustpilotAlias(alias);
            } else {
                setFoundReviewsCount(0);
                setIsSubmitDisabled(true);
            }
        } catch (e) {
            setFoundReviewsCount(0);
            setIsSubmitDisabled(true);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        initGoogleInput();
    }, [googleInput]);

    const onAddSource = async (source: ReviewSource) => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('access_token');
            if (token) {
                const payload: {
                    googleCompanyId?: string;
                    yelpAlias?: string;
                    facebookAlias?: string;
                    trustpilotAlias?: string;
                } = {};
                if (source === ReviewSource.google) {
                    payload.googleCompanyId = newGoogleCompanyId;
                } else if (source === ReviewSource.yelp) {
                    payload.yelpAlias = newYelpAlias;
                } else if (source === ReviewSource.facebook) {
                    payload.facebookAlias = newFacebookAlias;
                } else if (source === ReviewSource.trustpilot) {
                    payload.trustpilotAlias = newTrustpilotAlias;
                }
                const res = await axios.post(`${SERVER_URL}/user/add-source`, payload, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const newReviews = res.data;

                if (source === ReviewSource.google) {
                    userPopulatedData.user.googleCompanyId = newGoogleCompanyId;
                } else if (source === ReviewSource.yelp) {
                    userPopulatedData.user.yelpAlias = newYelpAlias;
                } else if (source === ReviewSource.facebook) {
                    userPopulatedData.user.facebookAlias = newFacebookAlias;
                } else if (source === ReviewSource.trustpilot) {
                    userPopulatedData.user.trustpilotAlias = newTrustpilotAlias;
                }

                onAdded(source, { ...lastFetchedData, reviews: newReviews });
            }
        } catch (e) {
            console.error(e);
        }
        setIsLoading(false);
    };

    const showGoogleInput = source === ReviewSource.google && !userPopulatedData.user.googleCompanyId;
    const showYelpInput = source === ReviewSource.yelp && !userPopulatedData.user.yelpAlias;
    const showFacebookInput = source === ReviewSource.facebook && !userPopulatedData.user.facebookAlias;
    const showTrustpilotInput = source === ReviewSource.trustpilot && !userPopulatedData.user.trustpilotAlias;

    useEffect(() => {
        if (googleInput) {
            setLastFetchedData(null);
            setNewCompanyId('');
            googleInput.value = '';
            setYelpUrl('');
            setFacebookUrl('');
            setTrustpilotUrl('');
            setFoundReviewsCount(undefined);
            setIsSubmitDisabled(true);

            if (showGoogleInput) {
                googleInput?.focus();
            }
            if (showYelpInput) {
                yelpInput?.focus();
            }
            if (showFacebookInput) {
                facebookInput?.focus();
            }
            if (showTrustpilotInput) {
                trustpilotInput?.focus();
            }
        }
    }, [source, googleInput]);

    return (
        <PlatformsManage>
            <TextField
                fullWidth
                sx={{ display: showGoogleInput ? 'block' : 'none' }}
                placeholder={'Search your business name...'}
                disabled={isLoading}
                inputRef={(ref) => {
                    // @ts-ignore
                    setGoogleInput(ref);
                }}
            />
            <TextField
                fullWidth
                sx={{ display: showYelpInput ? 'block' : 'none' }}
                placeholder={'Paste your Yelp link'}
                value={yelpUrl}
                disabled={isLoading}
                onChange={(e) => {
                    const value = e.target.value;
                    setYelpUrl(value);
                    searchYelpCompany(value);
                }}
                inputRef={(ref) => {
                    // @ts-ignore
                    setYelpInput(ref);
                }}
            />
            <TextField
                fullWidth
                size="medium"
                sx={{ display: showFacebookInput ? 'block' : 'none' }}
                placeholder={'Paste your Facebook link'}
                value={facebookUrl}
                disabled={isLoading}
                onChange={(e) => {
                    const value = e.target.value;
                    setFacebookUrl(value);
                    searchFacebookCompany(value);
                }}
                inputRef={(ref) => {
                    // @ts-ignore
                    setFacebookInput(ref);
                }}
            />
            <TextField
                fullWidth
                sx={{ display: showTrustpilotInput ? 'block' : 'none' }}
                placeholder={'Paste your Trustpilot link'}
                value={trustpilotUrl}
                disabled={isLoading}
                onChange={(e) => {
                    const value = e.target.value;
                    setTrustpilotUrl(value);
                    searchTrustpilotCompany(value);
                }}
                inputRef={(ref) => {
                    // @ts-ignore
                    setTrustpilotInput(ref);
                }}
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <CompanySearchStatus count={foundReviewsCount} isLoading={isLoading} />
                <Button
                    variant="contained"
                    disabled={isSubmitDisabled || isLoading}
                    sx={{ width: 'fit-content', ml: 'auto' }}
                    onClick={() => {
                        if (!isSubmitDisabled && !isLoading) {
                            if (showGoogleInput) {
                                onAddSource(ReviewSource.google);
                            } else if (showYelpInput) {
                                onAddSource(ReviewSource.yelp);
                            } else if (showFacebookInput) {
                                onAddSource(ReviewSource.facebook);
                            } else if (showTrustpilotInput) {
                                onAddSource(ReviewSource.trustpilot);
                            }
                        }
                    }}
                >
                    Add to widget
                </Button>
            </Stack>
        </PlatformsManage>
    );
});
