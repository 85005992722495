import React from 'react';
import { CompanyName, GradientBorder, Root, TextContent, TimeText } from './email-card.styles';
import { ReactComponent as EmailCardBg } from '../../assets/email-card.svg';
import Stack from '@mui/material/Stack';

export const EmailCard = ({ active, companyName }: { active?: boolean; companyName: string }) => (
    <Root $active={active}>
        <Stack width="100%" justifyContent="space-between" direction="row">
            <CompanyName>{companyName}</CompanyName>
            <TimeText>{active ? 'Now' : '7 days ago'}</TimeText>
        </Stack>
        <TextContent>{`Hi Alex, Please review your experience with us. Your feedback helps us become better. Thank you`}</TextContent>
        {!active && (
            <GradientBorder>
                <EmailCardBg />
            </GradientBorder>
        )}
    </Root>
);
