import styled from 'styled-components';

export const Root = styled.div<{ $active?: boolean }>`
    width: 100%;
    padding: 8px;
    border-radius: 10px;
    border: ${({ theme, $active }) => $active && `1px solid ${theme.colors.primary}`};
    position: relative;
`;

export const TimeText = styled.div<{ $size?: string; $secondary?: boolean }>`
    color: ${({ theme, $secondary }) => ($secondary ? 'rgba(36, 17, 89, 0.50)' : theme.colors.primary)};
    font-size: ${({ $size }) => ($size === 'medium' ? '12px' : '10px')};
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 127.273% */
    letter-spacing: -0.44px;
    margin-bottom: 2px;
`;

export const SubjectText = styled.div`
    overflow: hidden;
    color: ${({ theme }) => theme.colors.text};
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.48px;
`;

export const CompanyName = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.48px;
`;

export const TextContent = styled.div`
    color: rgba(36, 17, 89, 0.5);
    white-space: break-spaces;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 127.273% */
    letter-spacing: -0.44px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 6px;
`;

export const GradientBorder = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;

export const RecipientText = styled.div`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.48px;

    span {
        color: ${({ theme }) => theme.colors.primary};
    }
`;
